import React from 'react';
import { number, object } from 'prop-types';
import styles from './PercentageIndicator.module.css';

const PercentageIndicator = (props) => {
  const { percent, percentStyles } = props;
  const getClass = (val) => {
    if (val >= 80 && val <= 100 && val <= percent) {
      return styles.belowAnd100;
    }
    if (val >= 40 && val <= 70 && val <= percent) {
      return styles.belowAnd70;
    }
    if (val >= 0 && val <= 30 && val <= percent) {
      return styles.belowAnd30;
    }
    return styles.common;
  };
  const arr = Array(10).fill(null);

  return (
    <div className="flex items-center">
      {arr.map((v, i) => (
        <div
          style={{
            ...(percentStyles || {}),
          }}
          className={`${styles.indicators}
           ${getClass((i + 1) * 10)}`}
        >
        &nbsp;
        </div>
      ))}
    </div>
  );
};

PercentageIndicator.defaultProps = {
  percentStyles: {},
};

PercentageIndicator.propTypes = {
  percent: number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  percentStyles: object,
};

export default PercentageIndicator;
