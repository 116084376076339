import React from 'react';
import {
  bool, func, object, string,
} from 'prop-types';
import styles from './TextInput.module.css';
import { assetPrefix } from '../../utilities/general';

const TextInput = (props) => {
  const {
    value, title, errored, errorBgColor, errorBorderColor, onChange, customStyles, placeholder,
    errorIcon, normalIcon, type, errorToolTip, showErrorIcon, showIcon, required, extraButton,
    extraButtonOnClick, extraButtonToolTip, onKeyPress, name,
  } = props;

  return (
    <div className="flex flex-col">
      {!!title && (title.length > 0) && (
      <div className="flex">
        <div
          className={styles.title}
        >
          {title}
        </div>
        {required && <div className={`ml-1 text-xl ${styles.star}`}>*</div>}
      </div>
      )}
      <div
        className="margin-0 relative flex items-center"
        style={extraButton === '' ? {} : { width: customStyles.width }}
      >
        <input
          placeholder={placeholder}
          onChange={onChange}
          onKeyPress={onKeyPress}
          style={{
            ...(errored ? {
              backgroundColor: errorBgColor,
              borderColor: errorBorderColor,
            } : {}),
            ...customStyles,
          }}
          className={showIcon ? styles.input : styles.settingsInput}
          value={value}
          type={type && type}
          name={name}
        />
        {!!normalIcon && !errored && showIcon && (
          <img
            alt={placeholder}
            src={normalIcon}
            className={`${styles.inputIcon} absolute left-4`}
          />
        )}
        {!!extraButton
        && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <img
          alt="Click to view"
          src={extraButton}
          data-tip={extraButtonToolTip}
          data-background-color="#3E3E3E"
          className={`${styles.hoverErrorIcon} absolute right-6 top-6 cursor-pointer`}
          onClick={extraButtonOnClick}
        />
        )}
        {errored && (
        <>
          {!!errorIcon && (
          <img
            alt="Error"
            src={errorIcon}
            className={`${styles.inputIcon} absolute left-4`}
          />
          )}
          {!!showErrorIcon
          && (
          <img
            alt="Error"
            data-tip={errorToolTip}
            data-background-color="#FF6964"
            src={`${assetPrefix}/images/error-hover-icon.svg`}
            className={`${styles.hoverErrorIcon} absolute right-2 top-2`}
          />
          )}
        </>
        )}
      </div>
    </div>
  );
};

TextInput.defaultProps = {
  errorBgColor: '#FFF5F5',
  errorBorderColor: '#ED2D27',
  // errorToolTipShow: false,
  errorToolTip: '',
  customStyles: {},
  title: '',
  errored: false,
  showIcon: true,
  placeholder: '',
  errorIcon: '',
  normalIcon: '',
  type: '',
  showErrorIcon: true,
  required: false,
  extraButton: '',
  extraButtonToolTip: '',
  name: '',
  extraButtonOnClick: () => {},
  onKeyPress: () => {},
};

TextInput.propTypes = {
  value: string.isRequired,
  title: string,
  errored: bool,
  showIcon: bool,
  errorBgColor: string,
  errorBorderColor: string,
  onChange: func.isRequired,
  onKeyPress: func,
  // eslint-disable-next-line react/forbid-prop-types
  customStyles: object,
  placeholder: string,
  errorIcon: string,
  normalIcon: string,
  type: string,
  errorToolTip: string,
  // errorToolTipShow: bool,
  showErrorIcon: bool,
  required: bool,
  extraButton: string,
  name: string,
  extraButtonToolTip: string,
  extraButtonOnClick: func,
};

export default TextInput;
