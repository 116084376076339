// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseApp = {
  app: null,
};

const firebaseConfig = {
  apiKey: 'AIzaSyCfXKHHnQaixGedTf9_Km5r5_y01iHU0cQ',
  authDomain: 'oddup-2.firebaseapp.com',
  databaseURL: 'https://oddup-2-default-rtdb.firebaseio.com',
  projectId: 'oddup-2',
  // storageBucket: "oddup-2.appspot.com",
  // messagingSenderId: "638131075980",
  appId: '1:638131075980:web:1f0efb83dc8ae8a199b4a9',
  // measurementId: "G-EL5W86X5RE"
};

// Initialize Firebase
export const firebaseInit = () => {
  const app = initializeApp(firebaseConfig);
  firebaseApp.app = app;
};
