import React, { useEffect } from 'react';
import anime from 'animejs';
import styles from './CommonStyle.module.css';
import { assetPrefix } from '../../utilities/general';
import { devices, getDevice } from '../../utilities/animations';

const WorldGrowth = () => {
  useEffect(() => {
    const animate = () => {
      const eles = [
        document.querySelector('#right'),
        document.querySelector('#left'),
        document.querySelector('#middle'),
      ];
      anime({
        keyframes: [
          //     {
          //   translateY: -50,
          // },
          {
            // delay: 100,
            opacity: 1,
            translateY: 0,
          }],
        targets: eles,
        duration: 300,
        easing: 'easeInQuad',
        delay: anime.stagger(200, { from: 'last' }),
      });
    };

    // Add an event listener
    document.addEventListener('entry-animation-complete', (e) => {
      if (e.detail.from === 'section-2') {
        animate();
      }
    });
  }, []);
  const animatedStyles = (preTransform) => {
    if (getDevice() !== devices.DESKTOP) {
      return ({});
    }
    return ({
      opacity: 0,
      transform: `translateY(-50px)${preTransform || ''}`,
    });
  };
  return (
    <div
      className={`flex mx-auto ${styles.worldGrowth}`}
      style={{
        width: '545px', paddingBottom: '10px',
      }}
    >
      <div
        className={`flex justify-between mt-10 ${styles.secondImageShadowStyle}`}
        id="left"
        style={{
          ...animatedStyles(),
        }}
      >
        <div className="font-semibold text-base lg:text-xl">
          <div className="flex items-center mt-4">
            <img
              className={styles.iconStyle1}
              src={`${assetPrefix}/images/landingPage/hotdocImage.svg`}
              alt="icon"
            />
            <div className="ml-2 text-xs md:text-base lg:text-xl">HotDoc</div>
          </div>
          <div className="text-lg lg:text-2xl mt-3 font-semibold" style={{ color: 'darkblue' }}>
            <div className="mr-1">$1.3B+</div>
          </div>
          <div className="flex mt-1 text-sm lg:text-xl font-semibold" style={{ color: '#47CC79' }}>
            <div className="mr-1">+3.26%</div>
          </div>
          <img src={`${assetPrefix}/images/landingPage/companyB.png`} className={`mx-auto ${styles.graphStyle}`} alt="graph" />
        </div>
      </div>
      <div
        className={`flex scale-125 lg:scale-150 justify-between mt-9 lg:mt-4 ${styles.midCard} ${styles.secondImageShadowStyle}`}
        id="middle"
        style={{
          ...animatedStyles(' scaleX(1.5) scaleY(1.5)'),
          zIndex: 9,
        }}
      >
        <div className="font-semibold text-base lg:text-xl">
          <div className="flex items-center mt-2 md:mt-4">
            <img
              className={styles.iconStyle1}
              src={`${assetPrefix}/images/landingPage/moneySQImage.svg`}
              alt="icon"
            />
            <div className="ml-2">Money SQ</div>
          </div>
          <div className="text-lg lg:text-2xl mt-3 font-semibold" style={{ color: 'darkblue' }}>
            <div className="mr-1">$400M+</div>
          </div>
          <div className="flex mt-1 text-sm lg:text-xl font-semibold" style={{ color: '#47CC79' }}>
            <div className="mr-1">+7.42%</div>
          </div>
          <img src={`${assetPrefix}/images/landingPage/moneySQGraph.svg`} className={`mx-auto ${styles.graphStyle}`} alt="graph" />
        </div>
      </div>
      <div
        className={`flex justify-between mt-10 -ml-3 lg:ml-0 ${styles.secondImageShadowStyle}`}
        id="right"
        style={{
          ...animatedStyles(),
        }}
      >
        <div className="font-semibold text-base lg:text-xl">
          <div className="flex items-center mt-4">
            <img
              className={styles.iconStyle1}
              src={`${assetPrefix}/images/productPage/buxx.png`}
              alt="icon"
            />
            <div className="-ml-10 md:ml-2 text-xs md:text-base lg:text-xl">RocketSpace</div>
          </div>
          <div className="text-lg lg:text-2xl mt-3 font-semibold" style={{ color: 'darkblue' }}>
            <div className="mr-1">$2,505.00</div>
          </div>
          <div className="flex mt-1 text-sm lg:text-xl font-semibold" style={{ color: '#47CC79' }}>
            <div className="mr-1">+3.42%</div>
          </div>
          <img src={`${assetPrefix}/images/landingPage/companyC.png`} className={`mx-auto ${styles.graphStyle}`} alt="graph" />
        </div>
      </div>
    </div>
  );
};

export default WorldGrowth;
