import React, { useEffect } from 'react';
import anime from 'animejs/lib/anime';
import Skeleton from 'react-loading-skeleton';
import styles from './CommonStyle.module.css';
import { assetPrefix } from '../../utilities/general';
import RoundIcon from '../RoundIcon/RoundIcon';

const FirstImage = () => {
  useEffect(() => {
  //   anime({
  //     keyframes: [{
  //       translateX: -50,
  //     }, {
  //       delay: 400,
  //       translateX: 0,
  //     }],
  //     targets: `.${styles.innershadowStyle}`,
  //     opacity: 1,
  //     easing: 'easeInQuad',
  //     delay: anime.stagger(300, { from: 'last' }),
  //   });
  // }, []);

    const initialDelay = 300;
    const duration = 800;

    anime({
      targets: '#cardanoContainer',
      delay: initialDelay,
      keyframes: [{
        translateX: [0, -50],
        duration,
      }, {
        translateY: [0, 83],
        duration,
      }, {
        translateX: [-50, 0],
        duration,
      }],
      // opacity: [0, 1],
      easing: 'easeInQuad',
    });
    anime({
      targets: '#telegramContainer',
      delay: initialDelay,
      keyframes: [{
        translateX: [0, 50],
        duration,
      }, {
        translateY: [0, -83],
        duration,
      }, {
        translateX: [50, 0],
        duration,
      }],
      // opacity: [0, 1],
      easing: 'easeInQuad',
    });

    anime({
      targets: '#uberContainer',
      delay: (initialDelay * 2 + duration * 3),
      keyframes: [{
        translateX: [0, -50],
        duration,
      }, {
        translateY: [0, 83],
        duration,
      }, {
        translateX: [-50, 0],
        duration,
      }],
      // opacity: [0, 1],
      easing: 'easeInQuad',
    });
    anime({
      targets: '#zomatoContainer',
      delay: (initialDelay * 2 + duration * 3),
      keyframes: [{
        translateX: [0, 50],
        duration,
      }, {
        translateY: [0, -83],
        duration,
      }, {
        translateX: [50, 0],
        duration,
      }],
      // opacity: [0, 1],
      easing: 'easeInQuad',
    });
    // anime({
    //   targets: '#cardanoContainer',
    //   delay: 3000,
    //   translateX: [0, -50],
    //   opacity: [0, 1],
    //   duration: 2000,
    //   easing: 'easeInQuad',
    // });
    // anime({
    //   targets: '#cardanoContainer',
    //   delay: 3000,
    //   translateX: [0, -50],
    //   opacity: [0, 1],
    //   duration: 2000,
    //   easing: 'easeInQuad',
    // });
  }, []);

  // const handleIntersectionChanges = (inView) => {
  //   if (inView) {
  //     anime.timeline({
  //     }).add({
  //       targets: '#containerAnimatable',
  //       translateX: ['20vw', 0],
  //       opacity: 1,
  //       duration: 500,
  //       easing: 'linear',
  //       delay: 300,
  //     }).add({
  //       keyframes: [{
  //         translateX: -50,
  //
  //       }, {
  //         delay: 400,
  //         translateX: 0,
  //       }],
  //       targets: `.${styles.innershadowStyle}`,
  //       opacity: 1,
  //       easing: 'easeInQuad',
  //       delay: anime.stagger(300),
  //     });
  //   } else {
  //     anime.timeline({
  //     }).add({
  //       targets: '#containerAnimatable',
  //       translateX: [0, '20vw'],
  //       opacity: 0.3,
  //       duration: 500,
  //       easing: 'linear',
  //       delay: 300,
  //     });
  //   }
  // };
  return (
  // <InView as="div" onChange={handleIntersectionChanges} threshold={0.8} initialInView={false}>
    <div
      id="containerAnimatable"
      className={styles.mainBoxShadowStyle}
    >
      <img
        src={`${assetPrefix}/images/landingPage/landingPageOddup.png`}
        alt="Oddup"
        className={styles.firstImage}
      />
      <div className={`flex items-center justify-between mt-5 md:mt-10 ${styles.innershadowStyle}`} id="cardanoContainer">
        <div className={`font-semibold text-sm md:text-lg ${styles.mainCard}`}>
          <div className="flex items-center">
            <RoundIcon
              eleIdForScore="cardanoScore"
              src={`${assetPrefix}/images/productPage/ada.png`}
              oddupScore={82}
            />
            <div className="ml-5">
              Cardano
              <div className="flex items-center md:text-sm text-xs font-medium" style={{ color: '#A9A9A9' }}>
                <div className="mr-1">Switzerland</div>
                <div className={styles.dotStyle} />
                <div className="ml-1">Blockchain</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
      </div>
      <div className={`flex items-center justify-between mt-5 ${styles.innershadowStyle}`} id="telegramContainer">
        <div className={`font-semibold text-sm md:text-lg ${styles.mainCard}`}>
          <div className="flex items-center">
            <RoundIcon
              eleIdForScore="telegramScore"
              src={`${assetPrefix}/images/landingPage/telegram.svg`}
              oddupScore={89}
            />
            <div className="ml-5">
              Telegram
              <div className="flex items-center md:text-sm text-xs font-medium" style={{ color: '#A9A9A9' }}>
                <div className="mr-1">Russia</div>
                <div className={styles.dotStyle} />
                <div className="ml-1">Communications</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
      </div>
      <div className={`flex items-center justify-between mt-5 ${styles.innershadowStyle}`} id="uberContainer">
        <div className={`font-semibold text-sm md:text-lg ${styles.mainCard}`}>
          <div className="flex items-center">
            <RoundIcon
              src={`${assetPrefix}/images/landingPage/uber.svg`}
              eleIdForScore="uberScore"
              oddupScore={68}
            />
            <div className="ml-5">
              Uber
              <div className="flex items-center md:text-sm text-xs font-medium" style={{ color: '#A9A9A9' }}>
                <div className="mr-1">USA</div>
                <div className={styles.dotStyle} />
                <div className="ml-1">Transport</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
      </div>
      <div className={`flex items-center justify-between mt-5 -mb-12 ${styles.innershadowStyle}`} id="zomatoContainer">
        <div className={`font-semibold text-sm md:text-lg ${styles.mainCard}`}>
          <div className="flex items-center">
            <RoundIcon
              eleIdForScore="zomatoScore"
              src={`${assetPrefix}/images/landingPage/zomato.svg`}
              oddupScore={75}
            />
            <div className="ml-5">
              Zomato
              <div className="flex items-center md:text-sm text-xs font-medium" style={{ color: '#A9A9A9' }}>
                <div className="mr-1">India</div>
                <div className={styles.dotStyle} />
                <div className="ml-1">Food</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
        <div className={styles.subCard}>
          <Skeleton className={styles.placeholder} />
        </div>
      </div>
    </div>
  );
  // </InView>
};
export default FirstImage;
