/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,react/jsx-no-bind */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  MenuItem, MenuList, ClickAwayListener, Grow, Paper, Popper,
} from '@material-ui/core';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { zonedTimeToUtc } from 'date-fns-tz';
import { selectAllNotifications, selectUserSettings } from '../../redux/selectors';
import styles from '../PageHead/PageHead.module.css';
import { transformFirebaseNotifs } from '../../utilities/general';
import { markSeen } from '../../utilities/apis/firebase';

const Notification = () => {
  const allNotification = useSelector(selectAllNotifications);
  const [openNotification, setOpenNotification] = useState(false);
  const anchorRefNotification = useRef(null);
  const allNotifications = useSelector(selectAllNotifications);
  const userSettingsWithLoader = useSelector(selectUserSettings);

  useEffect(() => {
    if (openNotification && allNotifications) {
      const notifArr = transformFirebaseNotifs(allNotifications);
      const notSeen = notifArr.filter((noti) => !noti.seen || noti.seen === 'false');
      notSeen.map((notif) => markSeen({
        userId: userSettingsWithLoader.data.userId,
        notificationKey: notif.key,
      }));
    }
  }, [openNotification]);

  const history = useHistory();

  const handleToggleNotification = () => {
    setOpenNotification((prevOpen) => !prevOpen);
  };

  const handleCloseNotification = (event) => {
    if (anchorRefNotification.current && anchorRefNotification.current.contains(event.target)) {
      return;
    }

    setOpenNotification(false);
  };

  function handleListKeyDownNotification(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenNotification(false);
    }
  }
  const OpenNotification = useRef(openNotification);
  useEffect(() => {
    if (OpenNotification.current === true && openNotification === false) {
      anchorRefNotification.current.focus();
    }

    OpenNotification.current = openNotification;
  }, [openNotification]);

  const sortedNotification = allNotification && Object.values(allNotification).sort((a, b) => b.timestamp?.localeCompare(a.timestamp));
  const emptimestamp = [];
  const emptimestampArray = sortedNotification?.filter((item) => item.timestamp === '' || item.timestamp === 'None');
  emptimestamp.push(emptimestampArray);

  const fillemptimestamp = [];
  const fillemptimestampArray = sortedNotification?.filter((item) => item.timestamp !== '');
  fillemptimestamp.push(fillemptimestampArray);
  if (fillemptimestampArray && emptimestampArray) {
    fillemptimestampArray.push(...emptimestampArray);
  }
  const fillarray = fillemptimestampArray?.filter((item) => item.timestamp !== 'None');
  const nonearray = fillemptimestampArray?.filter((item) => item.timestamp === 'None');
  if (fillarray && nonearray) {
    fillarray.push(nonearray);
  }
  return (
    <div>
      {
        fillarray && transformFirebaseNotifs(allNotifications).some((notify) => (!notify.seen || notify.seen === 'false'))
          ? (
            <img
              src="/oddup-app/assets/images/Notification-Bell.png"
              alt="Search"
              className={styles.bellIcon}
              ref={anchorRefNotification}
              aria-controls={openNotification ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggleNotification}
            />
          ) : (
            <img
              src="/oddup-app/assets/images/bell-icon.png"
              alt="Search"
              ref={anchorRefNotification}
              className={styles.notificationIcon}
              aria-controls={openNotification ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggleNotification}
            />
            // <img
            //   src="/oddup-app/assets/images/blueNotification.png"
            //   alt="Search"
            //   className={`${styles.notificationIcon}`}
            //   ref={anchorRefNotification}
            //   aria-controls={openNotification ? 'menu-list-grow' : undefined}
            //   aria-haspopup="true"
            //   onClick={handleToggleNotification}
            // />
          )
      }
      <Popper
        open={openNotification}
        anchorEl={anchorRefNotification.current}
        role={undefined}
        transition
        disablePortal
        className={styles.mainbox}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper className={styles.boxshadowdown}>
              <ClickAwayListener onClickAway={handleCloseNotification}>
                <MenuList
                  autoFocusItem={openNotification}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDownNotification}
                >
                  {fillarray && Object.values(fillarray).map((data) => (
                    <MenuItem
                      key={data.key}
                      onClick={() => {
                        if (data.relativeLink) {
                          history.push(data.relativeLink);
                        }
                      }}
                    >
                      <div className={styles.belliconmainBox}>
                        {data && (
                          <div className={`flex flex-col ${styles.middlebox}`}>
                            <p>{data.notificationText}</p>
                          </div>
                        )}
                        <div className={`flex mb-auto ${styles.left}`}>
                          {data?.timestamp && true && data.timestamp !== 'None'
                            ? formatDistanceToNowStrict(
                              zonedTimeToUtc(parseISO(data.timestamp), 'UTC'),
                            ) : ''}
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
export default Notification;
