const actions = {
  SET_INITIAL_VALUES: 'SET_INITIAL_VALUES',
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_USER_SETTINGS: 'SET_USER_SETTINGS',
  SET_LOGGED_IN_STATUS: 'SET_LOGGED_IN_STATUS',
  SET_WARNING_RIBBON_SHOW: 'SET_WARNING_RIBBON_SHOW',
  SET_LIMIT_EXCEED_ERROR: 'SET_LIMIT_EXCEED_ERROR',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SET_ALL_NOTIFICATION: 'SET_ALL_NOTIFICATION',
};

export default actions;
