/* eslint-disable no-nested-ternary */
/**
 * React
 * Author: Patanjali kumar (patanjali@oddup.com)
 * Copyright © 2020-present Oddup. All rights reserved.
 *
 *
 */

import React from 'react';
import {
  any, string, bool, number,
} from 'prop-types';
import styles from './RoundIcon.module.css';
import { displayableValue, getColorForScore } from '../../utilities/general';

const RoundIcon = (props) => {
  const {
    src, title, oddupScore, imageStyles, scoreContainerStyles, showBorder, data, eleIdForScore,
  } = props;

  const cryptoCurrencyColor = (performance) => {
    if (performance > 0) {
      return '#8CE217';
    } if (performance < 0) {
      return '#ed2d27';
    }
    return '#ed2d27';
  };
  return (
    <div
      className={`relative ${styles.roundIconBorder} ${oddupScore >= 70 ? styles.bordergt70 : oddupScore > 35 ? styles.bordergt35 : styles.borderlt35}`}
    >
      <img
        src={(src !== null && src.length !== 0) ? src : '/oddup-app/assets/logo/companyLogo.svg'}
        alt={title}
        className={styles.roundIcon}
        style={showBorder ? {
          borderColor: getColorForScore(oddupScore),
          ...imageStyles,
        } : {
          borderColor: cryptoCurrencyColor(data),
          ...imageStyles,
        }}
      />
      {oddupScore && (
      <span
        data-place="right"
        data-tip="Oddup Score"
        className={styles.scoreNumber}
        style={{
          backgroundColor: getColorForScore(oddupScore),
          ...scoreContainerStyles,
        }}
        {...(eleIdForScore.length ? { id: eleIdForScore } : {})}
      >
        {displayableValue(oddupScore)}
      </span>
      )}
    </div>
  );
};

RoundIcon.defaultProps = {
  oddupScore: null,
  src: '/oddup-app/assets/logo/companyLogo.svg',
  title: 'Sample',
  imageStyles: {},
  scoreContainerStyles: {},
  showBorder: true,
  data: '0',
  eleIdForScore: '',
};
RoundIcon.propTypes = {
  src: string,
  title: string,
  oddupScore: string,
  // eslint-disable-next-line react/forbid-prop-types
  imageStyles: any,
  // eslint-disable-next-line react/forbid-prop-types
  scoreContainerStyles: any,
  showBorder: bool,
  data: number,
  eleIdForScore: string,
};

export default RoundIcon;
