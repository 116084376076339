/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/**
 * React
 * Author: Patanjali kumar (patanjali@oddup.com)
 * Copyright © 2020-present Oddup. All rights reserved.
 *
 *
 */

import React, {
  useState, useRef, useEffect, lazy, Suspense,
} from 'react';
import { string, bool, func } from 'prop-types';
import {
  MenuItem, MenuList, Button, ClickAwayListener, Grow, Paper, Popper,
} from '@material-ui/core';
import { CloseOutlined, ExpandMore } from '@mui/icons-material';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './PageHead.module.css';
import {
  selectUserProfile,
  selectLoggedInStatus,
  selectWarningRibbon,
  selectNotification, selectUserSettings,
} from '../../redux/selectors';
import { deleteCookie } from '../../utilities/cookiesSession';
import actions from '../../redux/actions';
import { globalSearch } from '../../utilities/apis/filters';
import { apiWrapWithoutData } from '../../utilities/apis/apiHelpers';
// import { loadingStates } from '../../utilities/general';
import MobileViewWarning from '../MobileViewWarning/MobileViewWarning';
import LazyLoading from '../LazyLoading/LazyLoading';
import buttonize, { loadingStates } from '../../utilities/general';
// import MobileViewWarning from '../MobileViewWarning/MobileViewWarning';
import ImageLoader from '../ImageLoader/ImageLoader';
import countries from '../../utilities/countries';
import { globalSearchCTAEvent } from '../../utilities/event';
import NotificationBell from '../NotificationBell/NotificationBell';
import { markSeen } from '../../utilities/apis/firebase';

// const UpgradePlan = lazy(() => import('../../pages/PaymentFlow/UpgradePlan'));
const UpgradeSubs = lazy(() => import('../../pages/PaymentFlow/UpgradeSubs'));

const PageHead = (props) => {
  // const [searchText, setSearchText] = useState('');
  const {
    showBorderColor, searchText, setSearchText, textPreviouslySearched, borderBottomColor,
  } = props;
  const [previousText, setPreviousText] = useState(textPreviouslySearched);
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(loadingStates.NO_ACTIVE_REQUEST);
  const initialInputs = ((useSelector(selectUserProfile)).data || {});
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [timer, setTimer] = useState(0);
  const dispatch = useDispatch();
  const loggedInStatus = useSelector(selectLoggedInStatus).loggedIn;
  const history = useHistory();
  const [loadUpgradeBox, setLoadUpgradeBox] = useState(false);

  const userSettingsWithLoader = useSelector(selectUserSettings);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (previousText !== '') {
      setSearchText(previousText);
    }
  }, []);

  const handleGlobalSearch = (text) => {
    if (!(text && (text.length > 3))) {
      setFilteredData([]);
      return null;
    }
    (async () => {
      const resp = await apiWrapWithoutData(globalSearch(text));
      if (resp && resp.data && resp.data.success) {
        const data = [resp.data.data];
        const result = data.reduce((arr, value) => {
          const item = {};
          const search = (key) => {
            if (value[key].length !== 0) {
              item[key] = value[key].filter((x, index) => index < 3);
            }
          };
          search('companies');
          search('sectors');
          search('locations');
          search('investors');
          search('cryptocurrencies');
          arr.push(item);
          return arr;
        }, []);
        setFilteredData(result);
        setDataLoading(loadingStates.FINISHED);
      } else {
        setDataLoading(loadingStates.FAILED);
      }
      return null;
    })();
    // const data = globalSearch(text);
    return null;
  };

  const handleChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length > 3) {
      setDataLoading(loadingStates.LOADING);
    }
    setTimer(setTimeout(() => {
      handleGlobalSearch(e.target.value);
    }, 1800));
  };

  const handleKeyDown = () => {
    setPreviousText('');
    clearTimeout(timer);
  };

  const eventFireGlobalSearch = ({
    targetDestination,
  }) => {
    const searchedText = searchText;
    globalSearchCTAEvent({
      details: {
        searchedText,
        targetDestination,
      },
    });
  };

  const searchResult = (result, tab, Name) => {
    let countryCode = [];
    if (tab === 'location' && result.country !== '') {
      countryCode = countries.filter((country) => country.label === result.country);
    }
    return (
      <div
        className="flex cursor-default mb-1 cursor-pointer"
        onClick={() => {
          const resultIncoming = result;
          if (!resultIncoming.slug && resultIncoming.symbol) {
            resultIncoming.slug = result.symbol;
          }
          history.push({ pathname: `/${tab}/details/${resultIncoming.slug}`, state: { data: resultIncoming.slug, Name } });
          eventFireGlobalSearch({
            targetDestination: `/${tab}/details/${resultIncoming.slug}`,
          });
          setPreviousText(searchText);
        }}
      >
        <div className={`${styles.dropdownResultImage} flex items-center`}>
          {(tab === 'location')
            && (
              // <img src={(result.icon !== null && result.icon.length !== 0) ? result.icon : '/oddup-app/assets/logo/companyLogo.svg'} alt="logo" className="w-full h-full" />
              <ImageLoader
                alt="logo"
                title={result.name}
                className={styles.assetIcon}
                src={`https://odp-logo-flags.s3.amazonaws.com/${countryCode[0].value.toLowerCase()}.svg`}
                fallback="/oddup-app/assets/logo/companyLogo.svg"
              />
            )}
          {(tab === 'companies')
            && (
              <ImageLoader
                alt="logo"
                title={result.name}
                className={styles.assetIcon}
                src={`https://odp-logo-companies.s3.amazonaws.com/${result.slug}.jpg`}
                fallback="/oddup-app/assets/logo/companyLogo.svg"
              />
            )}
          {(tab === 'investors')
            && (
              <ImageLoader
                alt="logo"
                title={result.name}
                src={`https://odp-logo-investors.s3.amazonaws.com/${result.slug}.jpg`}
                fallback="/oddup-app/assets/images/investor-logo.svg"
              />
            )}
          {tab === 'sectors'
            && (
              // <img src={(result.icon !== null && result.icon.length !== 0) ? result.icon : '/oddup-app/assets/logo/sectorLogo.svg'} alt="logo" className="w-full h-full" />
              <ImageLoader
                alt="logo"
                title={result.name}
                className={styles.assetIcon}
                src={`https://odp-logo-sectors.s3.amazonaws.com/${result.slug}.png`}
                fallback="/oddup-app/assets/logo/sectorLogo.svg"
              />
            )}
          {tab === 'cryptocurrencies'
            && (
              // <img src={(result.icon !== null && result.icon.length !== 0) ? result.icon : '/oddup-app/assets/logo/sectorLogo.svg'} alt="logo" className="w-full h-full" />
              <ImageLoader
                alt="logo"
                title={result.name}
                className={styles.assetIcon}
                src={result.icon}
                fallback="/oddup-app/assets/logo/sectorLogo.svg"
              />
            )}
        </div>
        {' '}
        <span className="ml-2 mt-0.5">{result.name}</span>
      </div>
    );
  };

  // ${pageTitle === 'Companies' ? styles.companyHeaderContainer
  //         : pageTitle === 'Investors'
  //           ? styles.investorHeaderContainer
  //           : pageTitle === 'Locations'
  //             ? styles.locationHeaderContainer
  //             : showBorderColor === false
  //               ? styles.sectorHeaderWithoutBorder : styles.sectorHeaderContainer
  //       }

  const handleLogout = () => {
    deleteCookie('authToken');
    dispatch({
      type: actions.SET_LOGGED_IN_STATUS,
      payload: {
        loggedIn: false,
      },
    });
    window.location.href = '/login';
  };

  const handleViewAll = (tab) => {
    history.push({
      pathname: `/${tab}/${searchText}`,
      state: { data: searchText },
    });
    eventFireGlobalSearch({
      targetDestination: `/${tab}/${searchText}`,
    });
  };

  const warningRibbon = useSelector(selectWarningRibbon);
  const notification = useSelector(selectNotification);
  const showNotification = notification && !notification.seen;

  const notificationSeenHandler = (closeClick) => () => {
    // const db = getDatabase();
    // const nodeRef = ref(db, `${constants.env}/notifications/${userSettingsWithLoader.data.userId}/${notification.key}/seen`);
    // set(nodeRef, true);
    markSeen({
      userId: userSettingsWithLoader.data.userId,
      notificationKey: notification.key,
    });
    if (!closeClick && notification.relativeLink) {
      history.push(notification.relativeLink);
    }
    dispatch({
      type: actions.SET_NOTIFICATION,
      payload: null,
    });
  };
  return (
    <>
      {loadUpgradeBox && (
        <Suspense fallback={<LazyLoading />}>
          <UpgradeSubs upgradeSubs={loadUpgradeBox} setUpgradeSubs={setLoadUpgradeBox} />
        </Suspense>
      )}
      <MobileViewWarning />
      {showNotification && (
        <div
          className={`${styles.warningRibbon} flex items-center justify-between px-8 w-full cursor-pointer`}
          style={{
            ...(notification.bgColor ? {
              backgroundColor: notification.bgColor,
            } : {}),
          }}
          {...buttonize(notificationSeenHandler(false))}
        >
          <span>{notification.notificationText}</span>
          <CloseOutlined
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              notificationSeenHandler(true)();
            }}
          />
        </div>
      )}
      {warningRibbon.show && warningRibbon.exhausted
        && (
          <div className={`${styles.warningRibbon} flex items-center px-8 w-full`}>
            Your daily limit has been exhausted. Please return tomorrow or,
            <span
              style={{
                cursor: 'pointer',
              }}
              {...buttonize(() => {
                setLoadUpgradeBox(true);
              })}
            >
              <i>
                &nbsp;
                upgrade here
              </i>
            </span>
            .
          </div>
        )}
      {warningRibbon.show && !warningRibbon.exhausted
        && (
          <div className={`${styles.warningRibbon} flex items-center px-8 w-full`}>
            Your daily limit is about to be exhausted.
          </div>
        )}
      <header
        id="page-header"
        className={`flex items-center justify-between bg-white w-screen 
        ${showBorderColor === false ? styles.sectorHeaderWithoutBorder : styles.commonHeaderContainer}
        ${warningRibbon.show || showNotification ? styles.headerContainerWithWarning : ''}`}
        style={{
          ...(borderBottomColor ? {
            borderBottomColor,
          } : {}),
        }}
      >
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between bg-white w-full">
            <div className="flex items-center" onClick={() => history.push('/companies')}>
              <img className={styles.logoHeader} src="/oddup-app/assets/logo/oddup.svg" alt="Oddup" />
              {/* <span className={styles.pageTitle}>{pageTitle}</span> */}
            </div>
            <div className="flex items-center">
              <div
                className="flex border relative items-center"
                style={{
                  borderRadius: '33px',
                }}
              >
                <img
                  src="/oddup-app/assets/images/search-icon.png"
                  alt="Search"
                  className={styles.headIcon}
                />

                <input
                  type="text"
                  placeholder="Type To Search"
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleChange(e)}
                  value={searchText}
                  className="pl-1 pr-4 py-2.5 pl-0 focus:outline-none"
                  autoComplete="off"
                  style={{
                    borderRadius: '50%',
                  }}
                />
                {/* <button type="button"> */}
                {/* </button> */}
                <div
                  className={styles.searchDropdown}
                >
                  <ul>
                    {(searchText.length > 3 && dataLoading === loadingStates.LOADING) && (
                      <>
                        <li className="text-white">
                          <div
                            className={`flex items-center justify-between ${styles.searchList}`}
                          >
                            Companies
                          </div>
                          <div>
                            <div className="p-2 bg-white">
                              <Skeleton
                                width={175}
                                height={18}
                                style={{ borderRadius: 5 }}
                              />
                            </div>
                          </div>
                        </li>
                        <li className="text-white">
                          <div
                            className={`flex items-center justify-between ${styles.searchList}`}
                          >
                            Sectors
                          </div>
                          <div>
                            <div className="p-2 bg-white">
                              <Skeleton
                                width={175}
                                height={18}
                                style={{ borderRadius: 5 }}
                              />
                            </div>
                          </div>
                        </li>
                        <li className="text-white">
                          <div
                            className={`flex items-center justify-between ${styles.searchList}`}
                          >
                            Locations
                          </div>
                          <div>
                            <div className="p-2 bg-white">
                              <Skeleton
                                width={175}
                                height={18}
                                style={{ borderRadius: 5 }}
                              />
                            </div>
                          </div>
                        </li>
                        <li className="text-white">
                          <div
                            className={`flex items-center justify-between ${styles.searchList}`}
                          >
                            Investors
                          </div>
                          <div>
                            <div className="p-2 bg-white">
                              <Skeleton
                                width={175}
                                height={18}
                                style={{ borderRadius: 5 }}
                              />
                            </div>
                          </div>
                        </li>
                        <li className="text-white">
                          <div
                            className={`flex items-center justify-between ${styles.searchList}`}
                          >
                            Cryptocurrencies
                          </div>
                          <div>
                            <div className="p-2 bg-white">
                              <Skeleton
                                width={175}
                                height={18}
                                style={{ borderRadius: 5 }}
                              />
                            </div>
                          </div>
                        </li>
                        <li className="text-white">
                          <div
                            className={`flex items-center justify-between ${styles.searchList}`}
                          >
                            LocationsMap
                          </div>
                          <div>
                            <div className="p-2 bg-white">
                              <Skeleton
                                width={175}
                                height={18}
                                style={{ borderRadius: 5 }}
                              />
                            </div>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                  <ul>
                    {previousText === '' && searchText.length > 3 && dataLoading !== loadingStates.LOADING && filteredData.length > 0 && filteredData.map((dt) => (
                      <>
                        {(dt.companies && dt.companies.length > 0)
                          && (
                            <li className="text-white">
                              <div
                                className={`flex items-center justify-between ${styles.searchList}`}
                              >
                                Companies
                                <button
                                  type="button"
                                  onClick={() => { handleViewAll('companies'); setPreviousText(searchText); }}
                                >
                                  View
                                  All
                                </button>
                              </div>
                              <div>
                                <div className="p-2 bg-white">
                                  <>
                                    {dt.companies.map((cmp) => (
                                      searchResult(cmp, 'companies')
                                    ))}
                                  </>
                                </div>
                              </div>
                            </li>
                          )}
                        {(dt.sectors && dt.sectors.length > 0)
                          && (
                            <li className="text-white">
                              <div
                                className={`flex items-center justify-between ${styles.searchList}`}
                              >
                                Sectors
                                <button
                                  type="button"
                                  onClick={() => { handleViewAll('sectors'); setPreviousText(searchText); }}
                                >
                                  View
                                  All
                                </button>
                              </div>
                              <div>
                                <div className="p-2 bg-white">
                                  <>
                                    {dt.sectors.map((sec) => (
                                      searchResult(sec, 'sectors')
                                    ))}
                                  </>
                                </div>
                              </div>
                            </li>
                          )}
                        {(dt.locations && dt.locations.length > 0)
                          && (
                            <li className="text-white">
                              <div
                                className={`flex items-center justify-between ${styles.searchList}`}
                              >
                                Locations
                                <button
                                  type="button"
                                  onClick={() => { handleViewAll('locations'); setPreviousText(searchText); }}
                                >
                                  View
                                  All
                                </button>
                              </div>
                              <div>
                                <div className="p-2 bg-white">
                                  <>
                                    {dt.locations.map((loc) => (
                                      searchResult(loc, 'location')
                                    ))}
                                  </>
                                </div>
                              </div>
                            </li>
                          )}
                        {(dt.investors && dt.investors.length > 0)
                          && (
                            <li className="text-white">
                              <div
                                className={`flex items-center justify-between ${styles.searchList}`}
                              >
                                Investors
                                <button
                                  type="button"
                                  onClick={() => { handleViewAll('investors'); setPreviousText(searchText); }}
                                >
                                  View
                                  All
                                </button>
                              </div>
                              <div>
                                <div className="p-2 bg-white">
                                  <>
                                    {dt.investors.map((inv) => (
                                      searchResult(inv, 'investors')
                                    ))}
                                  </>
                                </div>
                              </div>
                            </li>
                          )}
                        {(dt.cryptocurrencies && dt.cryptocurrencies.length > 0)
                          && (
                            <li className="text-white">
                              <div
                                className={`flex items-center justify-between ${styles.searchList}`}
                              >
                                Cryptocurrencies
                                <button
                                  type="button"
                                  onClick={() => { handleViewAll('cryptocurrencies'); setPreviousText(searchText); }}
                                >
                                  View
                                  All
                                </button>
                              </div>
                              <div>
                                <div className="p-2 bg-white">
                                  <>
                                    {dt.cryptocurrencies.map((inv) => (
                                      searchResult(inv, 'cryptocurrencies')
                                    ))}
                                  </>
                                </div>
                              </div>
                            </li>
                          )}
                        {(dt.locations && dt.locations.length > 0)
                          && (
                            <li className="text-white">
                              <div
                                className={`flex items-center justify-between ${styles.searchList}`}
                              >
                                LocationsMap
                                <button
                                  type="button"
                                  onClick={() => { handleViewAll('locationsmap'); setPreviousText(searchText); }}
                                >
                                  View
                                  All
                                </button>
                              </div>
                              <div>
                                <div className="p-2 bg-white">
                                  <>
                                    {dt.locations.map((cmp) => (
                                      searchResult(cmp, 'location', 'locationsmap')
                                    ))}
                                  </>
                                </div>
                              </div>
                            </li>
                          )}
                      </>
                    ))}
                    {(previousText === '' && searchText.length > 3 && dataLoading !== loadingStates.LOADING && filteredData && filteredData.length > 0 && Object.keys(filteredData[0]).length === 0)
                      && (
                        <li className="text-white">
                          <div className="border">
                            <div className="p-2 bg-white">
                              <div className="font-normal cursor-default">
                                No Record Found
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                  </ul>
                </div>
              </div>
              {/* <img
                src="/oddup-app/assets/images/bell-icon.png"
                alt="Search"
                className={styles.headIcon}
              /> */}
              <div>
                <NotificationBell />
              </div>
              <img
                src="/oddup-app/assets/images/user-image.png"
                alt="Search"
                className={`${styles.headIcon} ${styles.userIcon}`}
              />
              <div className="flex flex-col">
                <span className={styles.signedIn}>Signed in as</span>
                <span className={styles.userName}>{initialInputs.name}</span>
              </div>
              <div>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <ExpandMore />
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={() => history.push('/settings')}
                            >
                              Settings
                            </MenuItem>
                            <MenuItem
                              onClick={handleLogout}
                            >
                              {loggedInStatus ? 'Sign Out' : 'Login'}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          </div>
        </div>

      </header>
    </>
  );
};

PageHead.defaultProps = {
  pageTitle: '',
  textPreviouslySearched: '',
  showBorderColor: true,
  searchText: '',
  borderBottomColor: null,
  setSearchText: () => {
  },
};

PageHead.propTypes = {
  pageTitle: string,
  textPreviouslySearched: string,
  showBorderColor: bool,
  searchText: string,
  setSearchText: func,
  borderBottomColor: string,
};

export default PageHead;
