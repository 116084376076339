import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';

const ImageLoader = (props) => {
  const { src, fallback, ...others } = props;
  const [srcMain, setSrcMain] = useState(fallback);
  useEffect(() => {
    (async () => {
      const image = new Image();
      image.src = src;
      image.onload = () => {
        setSrcMain(src);
      };
    })();
  }, []);
  // eslint-disable-next-line jsx-a11y/alt-text
  return (<img src={srcMain} {...others} />);
};

ImageLoader.propTypes = {
  src: string.isRequired,
  fallback: string.isRequired,
};

export default ImageLoader;
