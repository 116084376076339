import React, { useEffect } from 'react';
import anime from 'animejs';
import styles from './CommonStyle.module.css';
import { assetPrefix } from '../../utilities/general';

const MarketStartups = () => {
  useEffect(() => {
    const animate = () => {
      anime({
        keyframes: [{
          translateX: -50,
        }, {
          delay: 400,
          translateX: 0,
        }],
        duration: 500,
        targets: '.animeStartup',
        opacity: 1,
        easing: 'easeInQuad',
        delay: anime.stagger(300, { from: 'last' }),
      });
    };

    // Add an event listener
    document.addEventListener('entry-animation-complete', (e) => {
      if (e.detail.from === 'section-6') {
        animate();
      }
    });
  }, []);
  return (
    <div
      className={styles.frameWidth}
      style={{
        paddingBottom: '10px',
      }}
    >
      <div className={`animeStartup flex justify-between mt-10 border ${styles.handlePadding} ${styles.shadowNone} ${styles.innershadowStyle1} ${styles.innershadowStyle}`}>
        <div className="w-3/5 md:w-1/2 font-semibold text-base lg:text-xl mt-0 md:mt-1.5">
          <div className="flex items-center">
            <img src={`${assetPrefix}/images/landingPage/oyo.svg`} className={styles.iconStyle1} alt="logo" />
            <div className="ml-2 leading-5 md:leading-none" style={{ color: '#4E4E4E' }}>
              OYO
              <div className={`flex items-center lg:text-base font-normal ${styles.subHeading}`} style={{ color: '#A9A9A9' }}>
                <div className="mr-1">India</div>
                <div className={styles.dotStyle} />
                <div className="ml-1">Hospitality company</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/5 md:mt-1 lg:mt-0 md:w-1/2">
          <img src={`${assetPrefix}/images/landingPage/oyo.png`} className="mx-auto" alt="graph" />
        </div>
      </div>
      <div className={`animeStartup flex justify-between mt-4 border ${styles.handlePadding} ${styles.shadowNone} ${styles.innershadowStyle1} ${styles.innershadowStyle}`}>
        <div className="w-3/5 md:w-1/2 font-semibold text-base lg:text-xl mt-0 md:mt-1.5">
          <div className="flex items-center">
            <img src={`${assetPrefix}/images/landingPage/tink.svg`} className={styles.iconStyle1} alt="logo" />
            <div className="ml-2 leading-5 md:leading-none" style={{ color: '#4E4E4E' }}>
              Tink
              <div className={`flex items-center lg:text-base font-normal ${styles.subHeading}`} style={{ color: '#A9A9A9' }}>
                <div className="mr-1">Europe</div>
                <div className={styles.dotStyle} />
                <div className="ml-1">Financial Tech</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/5 md:mt-1 lg:mt-0 md:w-1/2">
          <img src={`${assetPrefix}/images/landingPage/tink.png`} className="mx-auto" alt="graph" />
        </div>
      </div>
      <div className={`animeStartup flex justify-between mt-4 border ${styles.handlePadding} ${styles.shadowNone} ${styles.innershadowStyle1} ${styles.innershadowStyle}`}>
        <div className="w-3/5 md:w-1/2 font-semibold text-base lg:text-xl mt-0 md:mt-1.5">
          <div className="flex items-center">
            <img src={`${assetPrefix}/images/landingPage/enfore.svg`} className={styles.iconStyle1} alt="logo" />
            <div className="ml-2 leading-5 md:leading-none" style={{ color: '#4E4E4E' }}>
              Enfore
              <div className={`flex items-center lg:text-base font-normal ${styles.subHeading}`} style={{ color: '#A9A9A9' }}>
                <div className="mr-1">Berlin</div>
                <div className={styles.dotStyle} />
                <div className="ml-1">Hardware</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/5 md:mt-1 lg:mt-0 md:w-1/2">
          <img src={`${assetPrefix}/images/landingPage/enforge.png`} className="mx-auto" alt="graph" />
        </div>
      </div>
      <div className={`animeStartup flex justify-between mt-4 border ${styles.handlePadding} ${styles.shadowNone} ${styles.innershadowStyle1} ${styles.innershadowStyle}`}>
        <div className="w-3/5 md:w-1/2 font-semibold text-base lg:text-xl mt-0 md:mt-1.5">
          <div className="flex items-center">
            <img src={`${assetPrefix}/images/landingPage/kingsoft.svg`} className={styles.iconStyle1} alt="logo" />
            <div className="ml-2 leading-5 md:leading-none" style={{ color: '#4E4E4E' }}>
              Kingsoft Cloud
              <div className={`flex items-center lg:text-base font-normal ${styles.subHeading}`} style={{ color: '#A9A9A9' }}>
                <div className="mr-1">Beizing</div>
                <div className={styles.dotStyle} />
                <div className="ml-1">Enterprise Solutions</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/5 md:mt-1 lg:mt-0 md:w-1/2">
          <img src={`${assetPrefix}/images/landingPage/kingsoft.png`} className="mx-auto" alt="graph" />
        </div>
      </div>
    </div>
  );
};

export default MarketStartups;
