import axios from 'axios';
import { headersProvider } from './apiHelpers';
import constants from '../constants';

export const getInitialValues = () => axios.get(
  `${constants.apiHost}/company/get-filter`, {
    headers: headersProvider(),
  },
);

export const getAllTags = () => axios.get(
  `${constants.apiHost}/company/get-tags`, {
    headers: headersProvider(),
  },
);

export const globalSearch = (searchedValue) => axios.get(`${constants.apiHost}/globalSearch?search=${searchedValue}`, {
  noTrailingSlash: true,
  headers: headersProvider(),
});

export const globalSearchListing = (filters, url) => axios.get(`${constants.apiHost}/${url}`, {
  noTrailingSlash: true,
  params: {
    ...filters,
  },
  headers: headersProvider(),
});
