import actions from '../actions';

const loggedInStatus = (state = {}, action) => {
  switch (action.type) {
    case actions.SET_LOGGED_IN_STATUS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default loggedInStatus;
