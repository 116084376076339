/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { ArrowForward } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import Carousel from '../../components/CarouselOwn';
import styles from './ProductPage.module.css';
import buttonize, { assetPrefix } from '../../utilities/general';
import CryptoIconsContainer from './CryptoiconsContainer';

const ProductPage = () => {
  const typeform = useRef(null);
  const cryptoDiv = useRef(null);
  const researchDiv = useRef(null);
  const toCheckScroll = useRef(null);
  const featuredClients = useRef(null);
  const history = useHistory();
  const [activeNav, setActiveNav] = useState(null);
  const [showHeaderBg, setShowHeaderBg] = useState(false);
  const scrollListener = () => {
    if (window.scrollY > 900 && !showHeaderBg) {
      setShowHeaderBg(true);
    }
    if (window.scrollY < 900 && showHeaderBg) {
      setShowHeaderBg(false);
    }
    const cryptoTarget = toCheckScroll.current;
    const researchTarget = featuredClients.current;
    const passedResearchTarget = researchTarget !== null && window.scrollY > (
      researchTarget.offsetTop + researchTarget.offsetHeight
    );
    const passedCryptoTarget = cryptoTarget !== null && window.scrollY > (
      cryptoTarget.offsetTop + cryptoTarget.offsetHeight
    );
    if (passedCryptoTarget && passedResearchTarget) {
      setActiveNav('crypto');
      return;
    }
    if (passedResearchTarget) {
      setActiveNav('research');
    }

    if (!passedResearchTarget && !passedCryptoTarget) {
      setActiveNav(null);
    }
    // if (scrolled && !passed) {
    //   setScrolled(false);
    // }
    // if (!scrolled && passed) {
    //   setScrolled(true);
    // }
  };
  const carouselItems = [
    'Shielding against Theft',
    'Secure exchange address',
    'Immunity from cyber crimes',
    'Protection against transfer fraud',
    'Direct access of funds to exchanges',
    'Address whitelisting required for withdrawals',
    'Transaction level verification and Offline Signing',
    'Comprehensive reporting on Oddup Crypto platform',
  ];

  useEffect(() => {
    if (toCheckScroll && toCheckScroll.current && featuredClients && featuredClients.current) {
      window.removeEventListener('scroll', scrollListener);
      window.addEventListener('scroll', scrollListener);
    }
  }, [activeNav]);
  const goToResearchDiv = () => {
    if (researchDiv && researchDiv.current) {
      const ele = researchDiv.current;
      const y = ele.getBoundingClientRect().top - 150 + window.scrollY;
      window.scroll({
        top: y,
        behavior: 'smooth',
      });
    }
  };
  const goToCryptoDiv = () => {
    if (cryptoDiv && cryptoDiv.current) {
      const ele = cryptoDiv.current;
      const y = ele.getBoundingClientRect().top - 140 + window.scrollY;
      window.scroll({
        top: y,
        behavior: 'smooth',
      });
    }
  };
  const typeformOpen = () => {
    if (typeform) {
      typeform.current.typeform.open();
    }
  };
  return (
    <div>
      <div className={`w-full ${styles.feature1} relative`}>
        <div className="pt-14 flex items-center justify-between w-10/12" style={{ maxWidth: '97.5em', margin: '0 auto' }}>
          <div className="md:text-left text-center">
            <img className={`md:mr-auto ${styles.imageStyle}`} src={`${assetPrefix}/logo/oddupWhiteHeading.svg`} alt="bubbles" />
          </div>
          <div className="text-white flex">
            <div className="font-semibold text-base pr-9">DISCOVER ODDUP</div>
            <div className="font-semibold text-base pr-9">PRODUCTS</div>
            <div className="font-semibold text-base pr-9">SERVICES</div>
            <div className="font-semibold text-base">INSIGHTS</div>
          </div>
          <div className="flex">
            <span className={styles.loginBtn} onClick={() => history.push('/login')}>
              LOGIN
            </span>
            <span className={styles.signUpBtn} onClick={() => history.push('/signup')}>
              SIGNUP
            </span>
          </div>
        </div>
        <section className={`${styles.generalContainer} mr-0 w-10/12 mx-auto`}>
          <div className={`flex justify-between items-center relative ${styles.globeContainer}`}>
            <div className="flex flex-col z-10 w-1/2">
              <img src={`${assetPrefix}/images/landingPage/map-cities-mobile.png`} className={`${styles.mapCities} block lg:hidden`} alt="Early Stage Growth" />
              <span className={styles.featureTitleWhite}>
                An indispensable resource for
                <br />
                investors, venture capitalists,
                <br />
                innovators & management
                <br />
                consultants
              </span>
              <span className={`${styles.featureDescWhite} hidden lg:block`}>
                Join more than 50,000 users utilizing Oddup’s proprietary platform every day for information on
                startups, cities and sectors, and for updated details on cryptocurrencies across the world.
              </span>
              <span className={`${styles.featureDesc} block lg:hidden`}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Join more than 50,000 users utilizing Oddup’s proprietary platform every day for information on startups, cities and sectors, and for updated details on cryptocurrencies across the world.
              </span>
              <br />
              <div
                style={{
                  background: '#FFA82D',
                }}
                className={`mb-24 rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
                {...buttonize(typeformOpen)}
              >
                <div className="text-white">
                  Signup to Oddup
                  <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
                </div>
              </div>
              <div style={{ border: '1px solid #0060B8' }} className="mb-12" />
              <div className="flex w-full" style={{ color: '#FFFFFF' }}>
                <div className="w-1/4 w-full">
                  <div className="font-semibold text-2xl">100+ Million</div>
                  <div className="font-normal text-lg">Startups</div>
                </div>
                <div className="w-1/4 w-full">
                  <div className="font-semibold text-2xl">3+ Million</div>
                  <div className="font-normal text-lg">Investors</div>
                </div>
                <div className="w-1/4 w-full">
                  <div className="font-semibold text-2xl">4000+</div>
                  <div className="font-normal text-lg">Cryptocurrencies</div>
                </div>
                <div className="w-1/4 w-full">
                  <div className="font-semibold text-2xl">300+</div>
                  <div className="font-normal text-lg">Sectors</div>
                </div>
              </div>
            </div>
            <img src={`${assetPrefix}/images/productPage/globe.png`} className={`${styles.globe} hidden lg:block`} alt="globe" />
          </div>
        </section>
      </div>

      <section className={`${styles.generalContainer1} rounded-xl border mt-20 lg:mt-52`} style={{ backgroundImage: 'url(/oddup-app/assets/images/productPage/cta-icons.png)' }}>
        <div className="flex lg:flex-row items-center">
          <div className="flex flex-col">
            <span className={styles.featureTitle1}>
              Involve your organisation in the
              <br />
              Oddup Experience with Oddup
              <br />
              Enterprise
            </span>
            <span className={`${styles.featureDesc} hidden lg:block`}>
              Leading companies trust Oddup. Looking for a volume license?
            </span>
            <span className={`${styles.featureDesc} block lg:hidden`}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Leading companies trust Oddup. Looking for a volume license?
            </span>
            <br />
            <div
              style={{
                background: '#4AA8FF',
              }}
              className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
              {...buttonize(typeformOpen)}
            >
              <div className="text-white">
                Contact Sales
                <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      { /* Header -- start */ }
      <div className={`mt-20 ${styles.headerParentContainer} ${showHeaderBg ? styles.headerBg : ''}`}>
        <div className={`${styles.generalContainer}`}>
          <div className="flex justify-center items-center">
            <div className="flex">
              <span
                className={`${styles.headerItem} ${styles.hideOnMobile} ${activeNav === 'research' ? styles.headerItemActive : ''} mr-10`}
                {...buttonize(goToResearchDiv)}
              >
                ODDUP RESEARCH
              </span>
              <span className={`${styles.headerItem} ${styles.hideOnMobile} ${activeNav === 'crypto' ? styles.headerItemActive : ''} `} {...buttonize(goToCryptoDiv)}>ODDUP CRYPTO</span>
            </div>
          </div>
        </div>
      </div>
      { /* Header -- end */ }
      <div className="w-10/12 border rounded-2xl mx-auto p-5">
        <div ref={featuredClients} />
        <div className={`w-full ${styles.feature3Container} relative`} ref={researchDiv}>
          <section className={`${styles.generalContainer2} mr-0`}>
            <div className={`flex justify-between items-center ${styles.mapCitiesContainer}`}>
              <div className="flex flex-col w-full relative">
                <img src={`${assetPrefix}/images/productPage/map-cities.png`} className={`${styles.mapCities} hidden lg:block`} alt="Early Stage Growth" />
                <img src={`${assetPrefix}/images/landingPage/map-cities-mobile.png`} className={`${styles.mapCities} block lg:hidden`} alt="Early Stage Growth" />
                <span className={styles.featureTitle}>
                  Comprehensive Global
                  <br />
                  Coverage
                </span>
                <span className={`${styles.featureDesc} hidden lg:block`}>
                  Keep efficient tabs on the real-time startups, city, region, sector and
                  <br />
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  cryptocurrency movements and Investment activity with Oddup's un-paralleled
                  <br />
                  global coverage, and expertise across locations, markets, and industries. Oddup
                  <br />
                  provides entrepreneurial, investor, industry, and ecosystem data, insights, and
                  <br />
                  analyses across all the major startup locations across the globe.
                </span>
                <span className={`${styles.featureDesc} block lg:hidden`}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Keep efficient tabs on the real-time startups, city, region, sector and cryptocurrency movements and Investment activity with Oddup's un-paralleled global coverage, and expertise across locations, markets, and industries. Oddup provides entrepreneurial, investor, industry, and ecosystem data, insights, and analyses across all the major startup locations across the globe.
                </span>
                <br />
                <div
                  style={{
                    background: '#4AA8FF',
                  }}
                  className={`mb-24 rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
                  {...buttonize(typeformOpen)}
                >
                  <div className="text-white">
                    Signup for Oddup
                    <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className={`${styles.generalContainer} mt-14 lg:mt-48`} ref={featuredClients}>
          <div className="flex justify-between flex-col lg:flex-row items-center items-center">
            <img src={`${assetPrefix}/images/productPage/feature-4.png`} className={`${styles.feature4} mb-20 lg:mb-0`} alt="Early Stage Growth" />
            <div className="flex flex-col mr-8">
              <span className={styles.featureTitle}>
                Health Barometer in a
                <br />
                Single Score
              </span>
              <span className={`${styles.featureDesc} hidden lg:block`}>
                Oddup tracks and exhaustively analyses the profiles of thousands
                <br />
                of startups to provide the potential investors with a curated view of
                <br />
                their health and potential success in a single quantifiable metric.
              </span>
              <span className={`${styles.featureDesc} block lg:hidden`}>
                Oddup tracks and exhaustively analyses the profiles of thousands of startups to provide the potential investors with a curated view of their health and potential success in a single quantifiable metric.
              </span>
              <br />
              <div
                style={{
                  background: '#4AA8FF',
                }}
                className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
                {...buttonize(typeformOpen)}
              >
                <div className="text-white">
                  Embrace the power of Oddup Score
                  <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={`${styles.generalContainer} mt-28 lg:mt-56`}>
          <div className="flex justify-between flex-col lg:flex-row items-center items-center">
            <img src={`${assetPrefix}/images/productPage/success-potential.svg`} className={`${styles.feature5} block lg:hidden`} alt="Early Stage Growth" />
            <div className="flex flex-col mr-20">
              <span className={styles.featureTitle}>
                Success Potential
                <br />
                at a Glance
              </span>
              <span className={`${styles.featureDesc} hidden lg:block`}>
                Oddup’s most prominent and effective offering is the Benchmark
                <br />
                Valuation. It makes investment planning easier by giving investors
                <br />
                an insight into the valuation of the startup as well as its valuation
                <br />
                trend, enabling realistic expectations and planning.
              </span>
              <span className={`${styles.featureDesc} block lg:hidden`}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Oddup’s most prominent and effective offering is the Benchmark Valuation. It makes investment planning easier by giving investors an insight into the valuation of the startup as well as its valuation trend, enabling realistic expectations and planning.
              </span>
              <br />
              <div
                style={{
                  background: '#4AA8FF',
                }}
                className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
                {...buttonize(typeformOpen)}
              >
                <div className="text-white">
                  Explore Benchmark Valuation for Startups
                  <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
                </div>
              </div>
            </div>
            <img src={`${assetPrefix}/images/productPage/success-potential.svg`} className={`${styles.feature5} hidden lg:block`} alt="Early Stage Growth" />
          </div>
        </section>

        <section className={`${styles.generalContainer} mt-28 lg:mt-56`} ref={toCheckScroll}>
          <div className="flex justify-between flex-col lg:flex-row items-center items-center">
            <img src={`${assetPrefix}/images/productPage/feature-5.png`} className={`${styles.feature5} block lg:hidden`} alt="Early Stage Growth" />
            <img src={`${assetPrefix}/images/productPage/feature-5.png`} className={`${styles.feature5} hidden lg:block`} alt="Early Stage Growth" />
            <div className="flex flex-col mr-20">
              <span className={styles.featureTitle}>
                Keep Tabs on the
                <br />
                Market in Real Time
              </span>
              <span className={`${styles.featureDesc} hidden lg:block`}>
                Oddup Indexes provides a competitive view of the market by
                <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                monitoring the  real-time trends across the startup landscape.
              </span>
              <span className={`${styles.featureDesc} block lg:hidden`}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Oddup Indexes provides a competitive view of the market by  monitoring the  real-time trends across the startup landscape.
              </span>
              <br />
              <div
                style={{
                  background: '#4AA8FF',
                }}
                className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
                {...buttonize(typeformOpen)}
              >
                <div className="text-white">
                  Check out Oddup Indexes
                  <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className={`${styles.generalContainer} w-10/12 mx-auto mt-20 lg:mt-48`} ref={cryptoDiv}>
        <div className="flex justify-between flex-col lg:flex-row items-center">
          <div className="flex flex-col">
            <img src={`${assetPrefix}/images/landingPage/feature-2.png`} className={`${styles.feature2} block lg:hidden mx-auto mb-10`} alt="Early Stage Growth" />
            <span className={styles.featureTitle}>
              Nothing Cryptic
              <br />
              About Crypto
            </span>
            <span className={`${styles.featureDesc} hidden lg:block`}>
              Oddup provides curated in-depth analysis of active
              <br />
              cryptocurrencies by tracking and exploring hundreds of digital
              <br />
              assets to give investors a list that simplifies the complex market.
              <br />
            </span>
            <span className={`${styles.featureDesc} block lg:hidden`}>
              Oddup provides curated in-depth analysis of active cryptocurrencies by tracking and exploring hundreds of digital assets to give investors a list that simplifies the complex market.
              <br />
            </span>
            <br />
            <div
              style={{
                background: '#4AA8FF',
              }}
              className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
              {...buttonize(typeformOpen)}
            >
              <div className="text-white">
                Explore the world of Oddup Crypto
                <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
              </div>
            </div>
          </div>
          <img src={`${assetPrefix}/images/landingPage/feature-2.png`} className={`${styles.feature2} hidden lg:block`} alt="Early Stage Growth" />
        </div>
      </section>

      <section className={`${styles.generalContainer} w-10/12 mx-auto  mt-28 lg:mt-56 mb-28 lg:mb-56`}>
        <div className="flex justify-between flex-col lg:flex-row items-center items-center">
          <img src={`${assetPrefix}/images/landingPage/feature-6.png`} className={styles.feature6} alt="Early Stage Growth" />
          <div className="flex flex-col mr-12">
            <span className={styles.featureTitle}>
              Fort Knox of
              <br />
              Digital Assets
            </span>
            <span className={`${styles.featureDesc} hidden lg:block`}>
              The Oddup Crypto platform offers institutional-grade custody and
              <br />
              trading services for investors to further simplify the crypto space
            </span>
            <span className={`${styles.featureDesc} block lg:hidden`}>
              The Oddup Crypto platform offers institutional-grade custody and trading services for investors to further simplify the crypto space
            </span>
            <br />
            <div
              style={{
                background: '#4AA8FF',
              }}
              className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
              {...buttonize(typeformOpen)}
            >
              <div className="text-white">
                Secure your Crypto now
                <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`flex justify-center text-center ${styles.sectionContainer}`}>
        <div className={styles.topContent}>
          <div className={styles.gradientText}>
            100+ Digital Assets Supported and Counting
          </div>
          <div className={styles.subtitle}>
            Store your assets with Oddup Crypto, and when you are ready to trade, transfer them to any of
            <br />
            the leading crypto exchanges, with native support to over 100 different digital currencies
          </div>
          <CryptoIconsContainer />
          <div className="flex flex-col items-center lg:justify-start mt-24">
            <div className={styles.exploreBtn} {...buttonize(typeformOpen)}>
              Start trading on Oddup Crypto
              <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
            </div>
          </div>
        </div>
      </section>

      <div className={`${styles.insurance} mt-24 lg:mt-40`}>
        <section
          className={`${styles.generalContainer}`}
        >
          <div className="flex flex-col items-center">
            <span className={`${styles.featureTitle} mb-12`}>
              Up to $10 Million in Claimable Insurance
            </span>
            <span className={`${styles.featureDesc} text-center`}>
              Oddup Crypto is designed as a walled garden where new infrastructure is used to mitigate risk from malicious
              <br />
              actors. In addition, Oddup crypto investors are insured under a Full Crypto Crime Insurance Policy.
            </span>
            <div className={`text-center item-center font-medium text-xl justify-center flex flex-col mx-auto ${styles.carousel}`}>
              <Carousel navButtonsAlwaysInvisible indicators={false} animation="slide">
                {carouselItems.map((ci) => (
                  <div className="font-medium text-sm lg:text-xl  items-center">
                    {ci}
                  </div>
                ))}
              </Carousel>
            </div>
            <div
              style={{
                background: '#FFA82D',
              }}
              className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
              {...buttonize(typeformOpen)}
            >
              <div className="text-white">
                Request demo and join Oddup Crypto
                <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={`w-full ${styles.bubblesImageContainerBg} relative`}>
        <section className={`${styles.generalContainer} mr-0 w-10/12`}>
          <div className={`flex justify-between items-center ${styles.bubblesImageContainer}`}>
            <div className="flex flex-col z-10">
              <span
                className={styles.featureTitle}
                style={{
                  color: '#FFFFFF',
                }}
              >
                Interested In Oddup?
              </span>
              <span
                className={`${styles.featureDesc} hidden lg:block`}
                style={{
                  color: '#FFFFFF',
                }}
              >
                Sign up with Oddup to get a comprehensive pulse of the startup and the cryptocurrency
                <br />
                ecosystems by leveraging Oddup’s proprietary monitoring platform and actionable insights
                <br />
                <br />
                You can contact us directly at hello@oddup.com, or request a demo below to discover unique
                <br />
                insights from the startup and cryptocurrency ecosystems. If you are a startup that wants to gain
                <br />
                wide exposure to a large cohort of startup investors and corporates looking for their next
                <br />
                acquisition, you can get featured on Oddup by registering your startup. Join Oddup Now!
              </span>
              <span
                className={`${styles.featureDesc} block lg:hidden`}
                style={{
                  color: '#FFFFFF',
                }}
              >
                Oddup Crypto is designed as a walled garden where new infrastructure is used to mitigate risk from malicious actors. In addition, Oddup crypto investors are insured under a Full Crypto Crime Insurance Policy.
              </span>
              <br />
              <div
                style={{
                  background: '#F9A61A',
                }}
                className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button} ${styles.reqDemoBtn}`}
                {...buttonize(typeformOpen)}
              >
                <div className="text-white">
                  REQUEST DEMO
                </div>
              </div>
            </div>
            {/* <img
              src={`${assetPrefix}/images/productPage/upwardArrow.png`}
              className="right-10 bottom-5 absolute hidden lg:block"
              alt="Upward Arrow"
            /> */}
            <img src={`${assetPrefix}/images/bubbles.svg`} className={`${styles.bubblesImage} hidden lg:block`} alt="Early Stage Growth" />
          </div>
        </section>
      </div>

      {/* <LowerComponent /> */}
      <div className={styles.footerParent}>
        <section className={styles.generalContainer3}>
          <div className="md:flex items-center w-11/12 border-b-2 py-16">
            <div className="md:text-left text-center mb-4 md:mb-0 md:w-3/6 w-full">
              <img className={`md:mr-auto ${styles.imageStyle}`} src={`${assetPrefix}/logo/oddupWhiteHeading.svg`} alt="bubbles" />
            </div>
            <div className="md:w-3/6 w-full flex items-center md:justify-end justify-center">
              <div style={{ color: '#FFFFFF' }} className="mr-3 md:inline-flex hidden">FOLLOW US</div>
              <div className="flex items-center">
                <a
                  className={`flex items-center justify-center mr-3 ${styles.iconStyle}`}
                  href="https://www.facebook.com/oddupratings/"
                  target="_blank"
                >
                  <img src={`${assetPrefix}/images/facebook.svg`} className={styles.fbLogo} alt="Facebook" />
                </a>
                <a
                  className={`flex items-center justify-center mr-3 ${styles.iconStyle}`}
                  href="https://twitter.com/oddupratings"
                  target="_blank"
                >
                  <img src={`${assetPrefix}/images/twitter.svg`} alt="twitter" className={styles.twLogo} />
                </a>
                <a
                  className={`flex items-center justify-center ${styles.iconStyle}`}
                  href="https://www.linkedin.com/company/oddup"
                  target="_blank"
                >
                  <img src={`${assetPrefix}/images/linkedin.svg`} alt="linkedin" className={styles.twLogo} />
                </a>
              </div>
            </div>
          </div>
          <div className={`font-normal text-center py-10 ${styles.copyright}`}>
            © 2021 Oddup.com. All rights reserved.
            {' '}
            <a
              href="https://www.notion.so/oddup/Copyright-Policy-fc1e1aa5db60497e886ddb247d3948c2"
              target="_blank"
            >
              Copyright
            </a>
            {' '}
            |
            {' '}
            <a
              href="https://www.notion.so/oddup/Oddup-Terms-Conditions-39b0a029ba05440e85f8e4bee1e7350c"
              target="_blank"
            >
              Terms
            </a>
            {' '}
            |
            {' '}
            <a
              href="https://www.notion.so/oddup/Oddup-Privacy-Policy-5b46ce2440934925b43005b5ebce0c7d"
              target="_blank"
            >
              Privacy
            </a>
            {' '}
            |
            {' '}
            <a
              href="mailto:hello@oddup.com"
            >
              Help
            </a>
          </div>
        </section>
      </div>
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        url="https://form.typeform.com/to/MXo4VteX"
        hideHeaders
        hideFooter
        buttonText="Go!"
        style={{ height: 0 }}
        ref={typeform}
      />
    </div>
  );
};

export default ProductPage;
