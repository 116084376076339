export const devices = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};

export const getDevice = () => {
  if (window && window.innerWidth) {
    if (window.innerWidth <= 767) {
      return devices.MOBILE;
    }
    if (window.innerWidth <= 1024) {
      return devices.TABLET;
    }
    return devices.DESKTOP;
  }
  return devices.DESKTOP;
};

const stylesAnimationInitial = (direction) => {
  if (getDevice() !== devices.DESKTOP) {
    return ({});
  }
  if (direction === 'left-right') {
    return ({
      opacity: 0,
      transform: 'translateX(-20vw)',
    });
  }
  if (direction === 'right-left') {
    return ({
      opacity: 0,
      transform: 'translateX(20vw)',
    });
  }
  return ({});
};

export default stylesAnimationInitial;
