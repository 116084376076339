import axios from 'axios';
import constants from '../constants';
import { headersProvider } from './apiHelpers';

export const signup = ({
  email, password, name,
  // TODO: change host
}) => axios.post(`${constants.apiAuthHost}/user/signup`, {
  email, password, name,
}, {
  noTrailingSlash: true,
  headers: headersProvider(),
});

export const login = ({
  email, password,
}) => axios.post(`${constants.apiAuthHost}/user/login`, {
  email, password,
}, {
  noTrailingSlash: true,
  headers: headersProvider(),
});

export const resend = ({
  email,
  // TODO: change host
}) => axios.get(`${constants.apiAuthHost}/user/resend`, {
  params: {
    email,
  },
  noTrailingSlash: true,
  headers: headersProvider(),
});

export const changePassword = ({
  password, token,
}) => axios.post(`${constants.apiAuthHost}/user/change-password?token=${token}`, {
  password,
}, {
  noTrailingSlash: true,
});

export const forgotPassword = ({
  email,
}) => axios.post(`${constants.apiAuthHost}/user/forgot-password`, {
  email,
}, {
  noTrailingSlash: true,
});
