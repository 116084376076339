import React, { useEffect } from 'react';
import anime from 'animejs';
import styles from './CommonStyle.module.css';
import { assetPrefix } from '../../utilities/general';
import { devices, getDevice } from '../../utilities/animations';

const DigitalAssets = () => {
  useEffect(() => {
    const animate = () => {
      anime({
        keyframes: [
          // {
          //   translateY: -50,
          // },
          {
            // delay: 100,
            opacity: 1,
            translateY: 0,
          }],
        targets: `.${styles.digitalAssetsIcon}`,
        duration: 300,
        easing: 'easeInQuad',
        delay: anime.stagger(200, { from: 'last' }),
      });
    };

    // Add an event listener
    document.addEventListener('entry-animation-complete', (e) => {
      if (e.detail.from === 'section-7') {
        animate();
      }
    });
  }, []);

  const animatedStyles = (preTransform) => {
    if (getDevice() !== devices.DESKTOP) {
      return ({});
    }
    return ({
      opacity: 0,
      transform: `translateY(-50px)${preTransform || ''}`,
    });
  };
  return (
    <div
      className={`relative ml-5 ${styles.DigitalAssetsFrame}`}
    >
      <div className="flex">
        <div className={`mt-7 md:mt-14 lg:mt-20 ${styles.circleshadowStyle3} absolute md:-left-10 -left-6`}>
          <img
            className={`pt-3 ${styles.digitalAssetsIcon}`}
            style={{
              ...animatedStyles(),
            }}
            src={`${assetPrefix}/images/productPage/alv.png`}
            alt="icon"
          />
        </div>
        <div className={`mt-0 md:mt-3 ml-4 ${styles.circleshadowStyle3} absolute md:left-14 lg:left-20 left-9`}>
          <img
            className={`pt-3 ${styles.digitalAssetsIcon}`}
            style={{
              ...animatedStyles(),
            }}
            src={`${assetPrefix}/images/productPage/ada.png`}
            alt="icon"
          />
        </div>
        <div
          className={`mt-0 md:mt-10 mr-7 ${styles.circleshadowStyle3} absolute md:-top-14 md:right-20 lg:-top-10 right-10 -top-6`}
        >
          <img
            className={`pt-3 ${styles.digitalAssetsIcon}`}
            style={{
              ...animatedStyles(),
            }}
            src={`${assetPrefix}/images/productPage/btc.png`}
            alt="icon"
          />
        </div>
        <div
          className={`mt-0 md:mt-10 ml-0 md:ml-7 ${styles.circleshadowStyle3} absolute md:-right-10 -right-2`}
        >
          <img
            className={`pt-3 w-1/2 ${styles.digitalAssetsIcon}`}
            style={{
              ...animatedStyles(),
            }}
            src={`${assetPrefix}/images/productPage/crypto2.png`}
            alt="icon"
          />
        </div>
      </div>
      <div className="flex items-center">
        <div
          className={`mt-0 md:mt-2 lg:mt-4 ml-auto ${styles.circleshadowStyle3} absolute xl:left-14 lg:left-8 lg:top-40 md:left-10 md:top-32 left-2 top-15`}
        >
          <img
            className={`pt-3 ${styles.digitalAssetsIcon}`}
            src={`${assetPrefix}/images/productPage/crypto1.png`}
            style={{
              ...animatedStyles(),
            }}
            alt="icon"
          />
        </div>
        <div className={`ml-auto relative z-index-10 ${styles.lockDivStyle}`}>
          <img
            className={`w-4/5 ml-6 md:ml-0 ${styles.lockIconStyle}`}
            // style={{
            //   ...animatedStyles(),
            // }}
            src={`${assetPrefix}/images/landingPage/lock.svg`}
            alt="icon"
          />
        </div>
        <div
          className={`-mt-8 md:mt-4 ml-auto ${styles.circleshadowStyle3} absolute lg:right-14 md:right-8 right-4`}
        >
          <img
            className={`pt-3 ${styles.digitalAssetsIcon}`}
            style={{
              ...animatedStyles(),
            }}
            src={`${assetPrefix}/images/productPage/eth.png`}
            alt="icon"
          />
        </div>
      </div>
      <div className="flex">
        <div className={`-mt-20 -ml-6 md:ml-8 lg:ml-0 md:-mt-14 ${styles.circleshadowStyle3} absolute`}>
          <img
            className={`pt-3 -mt-2 md:mt-0 ${styles.digitalAssetsIcon}`}
            style={{
              ...animatedStyles(),
            }}
            src={`${assetPrefix}/images/productPage/buxx.png`}
            alt="icon"
          />
        </div>
        <div
          className={`-ml-10 -mt-20 lg:-mt-20 md:-mt-12 md:-ml-16 lg:-ml-5 ${styles.circleshadowStyle3} absolute left-1/3 md:left-1/2 lg:left-1/3`}
        >
          <img
            className={`pt-3 mt-2 md:mt-0 ${styles.digitalAssetsIcon}`}
            src={`${assetPrefix}/images/productPage/xrp.png`}
            style={{
              ...animatedStyles(),
            }}
            alt="icon"
          />
        </div>
        <div
          className={`mt-0 ml-auto ${styles.circleshadowStyle3} absolute lg:right-40 lg:-bottom-2 md:bottom-2 right-20 bottom-8`}
        >
          <img
            className={`pt-3 w-10/12 md:w-2/3 ${styles.digitalAssetsIcon}`}
            src={`${assetPrefix}/images/productPage/neo.png`}
            style={{
              ...animatedStyles(),
            }}
            alt="icon"
          />
        </div>
        <div className={`mt-0 ${styles.circleshadowStyle3} absolute right-0 md:-bottom-5 bottom-8`}>
          <img
            className={`pt-3 ${styles.digitalAssetsIcon}`}
            style={{
              ...animatedStyles(),
            }}
            src={`${assetPrefix}/images/productPage/kmd.png`}
            alt="icon"
          />
        </div>
      </div>
    </div>
  );
};

export default DigitalAssets;
