/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  NavigateNext, KeyboardArrowDown,
} from '@mui/icons-material';
import styles from './CommonStyle.module.css';

const FAQs = () => {
  const [selectedQues, setSelectedQuestion] = useState([]);

  const handleQuestions = (e, questionNo) => {
    e.preventDefault();
    const openQuestions = selectedQues;
    if (openQuestions.includes(questionNo)) {
      const index = openQuestions.indexOf(questionNo);
      openQuestions.splice(index, 1);
    } else {
      openQuestions.push(questionNo);
    }
    setSelectedQuestion([...openQuestions]);
  };

  return (
    <section className="mt-8 w-5/12 mx-auto py-16">
      <div className="flex flex-col items-center justify-center">
        <div className={`2xl:pt-12 xl:mx-auto font-bold 2xl:text-3xl text-center w-11/12 mr-auto ${styles.heading}`}>
          Pricing FAQs
        </div>
        <div className="flex flex-col justify-start w-full mt-16">
          <div className="flex text-xl font-bold" onClick={(e) => handleQuestions(e, '1')}>
            {selectedQues.includes('1') ? <KeyboardArrowDown /> : <NavigateNext />}
            <div className="pl-8">Can I use Oddup for free?</div>
          </div>
          {selectedQues.includes('1')
            && (
            <div className="pt-9 ml-10 pl-4">
              Absolutely! Oddup is completely free to use with all the features you would need in a startup due-diligence tool with a cap on the number of research queries that can be raised in a day.
              <br />
              If you are an individual or a small team, trying to get off the ground, that’s another monthly bill you don’t have to worry about.
            </div>
            )}
        </div>
        <div className="w-full my-9" style={{ backgroundColor: 'black', height: '2px' }} />
        <div className="flex flex-col justify-start w-full">
          <div className="flex text-xl font-bold" onClick={(e) => handleQuestions(e, '2')}>
            {selectedQues.includes('2') ? <KeyboardArrowDown /> : <NavigateNext />}
            <div className="pl-8">How many different Oddup plans are available?</div>
          </div>
          {selectedQues.includes('2')
            && (
            <div className="pt-9 ml-10 pl-4">
              Currently, we are offering two paid plans – a tailored access for the Sales Enablement teams, at $29 per month, when billed monthly, or $299, when billed annually.
              <br />
              Additionally, an unlimited access plan, specifically for large volume users is available, at $49 per month, when billed monthly, or $499, when billed annually.
              <br />
              We are also working on offering premium tiers for these plans with a lot of advanced features as well as introducing a team plan for slam and large teams to collaborate efficiently.
            </div>
            )}
        </div>
        <div className="w-full my-9" style={{ backgroundColor: 'black', height: '2px' }} />
        <div className="flex flex-col justify-start w-full">
          <div className="flex text-xl font-bold" onClick={(e) => handleQuestions(e, '3')}>
            {selectedQues.includes('3') ? <KeyboardArrowDown /> : <NavigateNext />}
            <div className="pl-8">Are there long-term contracts?</div>
          </div>
          {selectedQues.includes('3')
            && (
            <div className="pt-9 ml-10 pl-4">
              No, we don&apos;t force you into any long-term contracts. Our best price is available as an annual subscription, but we offer monthly subscriptions as well.
            </div>
            )}
        </div>
        <div className="w-full my-9" style={{ backgroundColor: 'black', height: '2px' }} />
        <div className="flex flex-col justify-start w-full">
          <div className="flex text-xl font-bold" onClick={(e) => handleQuestions(e, '4')}>
            {selectedQues.includes('4') ? <KeyboardArrowDown /> : <NavigateNext />}
            <div className="pl-8">Do you offer discounts on Oddup? Can I get special non-profit or trial pricing?</div>
          </div>
          {selectedQues.includes('4')
            && (
            <div className="pt-9 ml-10 pl-4">
              Unfortunately, no. We have already priced Oddup as competitively as possible.
            </div>
            )}
        </div>
        <div className="w-full my-9" style={{ backgroundColor: 'black', height: '2px' }} />
        <div className="flex flex-col justify-start w-full">
          <div className="flex text-xl font-bold" onClick={(e) => handleQuestions(e, '5')}>
            {selectedQues.includes('5') ? <KeyboardArrowDown /> : <NavigateNext />}
            <div className="pl-8">How can I cancel my subscription?</div>
          </div>
          {selectedQues.includes('5')
            && (
            <div className="pt-9 ml-10 pl-4">
              If you decide you want to stop your paid subscription, you can so from your account, and your account will be reverted to our FREE plan once the existing subscription period expires. We do not have a functionality to cancel an ongoing subscription.
            </div>
            )}
        </div>
        <div className="w-full my-9" style={{ backgroundColor: 'black', height: '2px' }} />
        <div className="flex flex-col justify-start w-full">
          <div className="flex text-xl font-bold" onClick={(e) => handleQuestions(e, '6')}>
            {selectedQues.includes('6') ? <KeyboardArrowDown /> : <NavigateNext />}
            <div className="pl-8">What payment methods do you accept?</div>
          </div>
          {selectedQues.includes('6')
            && (
            <div className="pt-9 ml-10 pl-4">
              We process our payments through Stripe, and allow most major credit and debit cards. We will soon be adding cryptocurrencies to our payments options.
            </div>
            )}
        </div>
        <div className="w-full my-9" style={{ backgroundColor: 'black', height: '2px' }} />
        <div className="flex flex-col justify-start w-full">
          <div className="flex text-xl font-bold" onClick={(e) => handleQuestions(e, '7')}>
            {selectedQues.includes('7') ? <KeyboardArrowDown /> : <NavigateNext />}
            <div className="pl-8">What is your refund policy like?</div>
          </div>
          {selectedQues.includes('7')
            && (
            <div className="pt-9 ml-10 pl-4">
              Due to technical limitations, we are currently not able to offer refunds, and cancelations within an active subscription.
            </div>
            )}
        </div>
        <div className="w-full my-9" style={{ backgroundColor: 'black', height: '2px' }} />
        <div className="flex flex-col justify-start w-full">
          <div className="flex text-xl font-bold" onClick={(e) => handleQuestions(e, '8')}>
            {selectedQues.includes('8') ? <KeyboardArrowDown /> : <NavigateNext />}
            <div className="pl-8">If my team/company is multinational can I pay in one currency for employees in one country and in a different currency for employees in another country?</div>
          </div>
          {selectedQues.includes('8')
            && (
            <div className="pt-9 ml-10 pl-4">
              Unfortunately, no, this isn&apos;t possible. All our transactions are in United States dollar.
            </div>
            )}
        </div>
        <div className="w-full my-9" style={{ backgroundColor: 'black', height: '2px' }} />
        <div className="flex flex-col justify-start w-full">
          <div className="flex text-xl font-bold" onClick={(e) => handleQuestions(e, '9')}>
            {selectedQues.includes('9') ? <KeyboardArrowDown /> : <NavigateNext />}
            <div className="pl-8">I don&apos;t have the permission to accept any agreement. It has to go through our legal department. How do I go about this?</div>
          </div>
          {selectedQues.includes('9')
            && (
            <div className="pt-9 ml-10 pl-4">
              Let us know, and we will get in touch with you to for the required formalities. Once done, we&apos;ll enable access to Oddup so you can proceed with the next step.
            </div>
            )}
        </div>
        <div className="w-full my-9" style={{ backgroundColor: 'black', height: '2px' }} />
        <div className="flex flex-col justify-start w-full">
          <div className="flex text-xl font-bold" onClick={(e) => handleQuestions(e, '10')}>
            {selectedQues.includes('10') ? <KeyboardArrowDown /> : <NavigateNext />}
            <div className="pl-8">I&apos;m really sold on Oddup, but I need to convince the rest of my team. Can you help me?</div>
          </div>
          {selectedQues.includes('10')
            && (
            <div className="pt-9 ml-10 pl-4">
              Definitely! Please drop us a line about the kind of help you need, and we&apos;ll get you the right information.
            </div>
            )}
        </div>
        <div className="w-full my-9" style={{ backgroundColor: 'black', height: '2px' }} />
      </div>
    </section>
  );
};

export default FAQs;
