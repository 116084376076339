import React from 'react';
import { ClipLoader } from 'react-spinners';
import {
  bool, elementType, func, object, string,
} from 'prop-types';
import styles from './Button.module.css';
import buttonize, { assetPrefix } from '../../utilities/general';

const Button = (props) => {
  const {
    text, success, loading, successColor,
    successImage, loadingColor, loadingComponent,
    extraStyles, onClick,
  } = props;
  let classToApply = `flex justify-center ${styles.container} ${styles.buttonBg}`;
  if (success) {
    classToApply += ` ${styles.successBg}`;
  }
  let content = <span className={styles.text}>{text}</span>;
  if (success) content = <img src={successImage} className={styles.successImg} alt="Success" />;
  if (loading) content = loadingComponent;
  return (
    <div
      className={classToApply}
      style={{
        ...(!!successColor && !!success ? {
          backgroundColor: successColor,
        } : {}),
        ...(!!loadingColor && !!loading ? {
          backgroundColor: successColor,
        } : {}),
        ...(extraStyles ? { ...extraStyles } : {}),
      }}
      {...buttonize(onClick)}
    >
      {content}
    </div>
  );
};

export default Button;

Button.defaultProps = {
  text: '',
  success: false,
  loading: false,
  successColor: '',
  loadingColor: '',
  successImage: `${assetPrefix}/images/success-tick.svg`,
  loadingComponent: <ClipLoader size={25} color="#FFFFFF" />,
  extraStyles: {},
};

Button.propTypes = {
  text: string,
  success: bool,
  loading: bool,
  successColor: string,
  successImage: string,
  loadingColor: string,
  loadingComponent: elementType,
  // eslint-disable-next-line react/forbid-prop-types
  extraStyles: object,
  onClick: func.isRequired,
};
