import { combineReducers } from 'redux';
import initialValues from './initialValues';
import userProfile from './userProfile';
import userSettings from './userSettings';
import loggedInStatus from './loggedInStatus';
import warningRibbon from './warningRibbon';
import limitExceedError from './limitExceedError';
import notification from './notification';
import allNotification from './allNotification';

const rootReducer = combineReducers({
  initialValues,
  userProfile,
  userSettings,
  loggedInStatus,
  warningRibbon,
  limitExceedError,
  notification,
  allNotification,
});

export default rootReducer;
