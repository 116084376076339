/* eslint-disable max-len */
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import '../tailwind.output.css';
import './RouteHandler.css';
import ReactTooltip from 'react-tooltip';
// import CompanyListing from './Listings/companies/CompanyListing';
// import LocationListing from './Listings/location/LocationListing';
// import SectorsListing from './Listings/sectors/SectorsListing';
// import InvestorsListing from './Listings/investors/InvestorsListing';
import MainWrapper from '../components/MainWrapper/MainWrapper';
import LazyLoading from '../components/LazyLoading/LazyLoading';
import ChangePasswordPage from './ChangePasswordPage/ChangePasswordPage';
import LandingPage from './LandingPage/LandingPage';
import ProductPage from './ProductPage/ProductPage';
import Protected from '../components/Protected/Protected';
import AboutPage from './AboutPage/AboutPage';
import PricingPage from './PricingPage/PricingPage';
import PaymentFlow from './PaymentFlow/PaymentFlow';
// import SafetiLandingPageContent from './SafetiPage/SafetiLandingPageContent/SafetiLandingPage';
// import SafetiLandingPageParent from './SafetiLandingPage/SafetiLandingPage';

const SectorsListing = lazy(() => import('./Listings/sectors/SectorsListing'));
const CompanyListing = lazy(() => import('./Listings/companies/CompanyListing'));
const InvestorsListing = lazy(() => import('./Listings/investors/InvestorsListing'));
const LocationListing = lazy(() => import('./Listings/location/LocationListing'));
const LocationMap = lazy(() => import('./Listings/locationmap/LocationMap'));
const CryptoCurrencyListing = lazy(() => import('./Listings/cryptocurrencies/CryptocurrencyListing'));
const FollowingListing = lazy(() => import('./Listings/following/FollowingListing'));
const OddupReportsListing = lazy(() => import('./Listings/reports/OddupReportsListing'));

const LoginPage = lazy(() => import('./LoginPage/LoginPage'));
const SignupPage = lazy(() => import('./SignupPage/SignupPage'));
const ForgotPasswordPage = lazy(() => import('./ForgotPasswordPage/ForgotPasswordPage'));
const EditProfile = lazy(() => import('./Settings/EditProfile/EditProfile'));
const ChangePassword = lazy(() => import('./Settings/ChangePassword/ChangePassword'));
const AccountSettings = lazy(() => import('./Settings/AccountSettings/AccountSettings'));
const BillingDetails = lazy(() => import('./Settings/BillingAndSubscription/BillingAndSubscription'));
const ProductUpgrade = lazy(() => import('./ProductUpgrade/ProductUpgrade'));
// const Dashboard = lazy(() => import('./Dashboard/Dashboard'));

const RouteHandler = () => {
  const isProductioLandingDomain = (((window.location.hostname.toLocaleLowerCase()) === 'www.oddup.com')
   || ((window.location.hostname.toLocaleLowerCase()) === 'oddup.com'));
  // console.log({ onlyLandingPage });
  return (
    <MainWrapper>
      <ReactTooltip />
      <Suspense fallback={<LazyLoading />}>
        {!isProductioLandingDomain && (
        <Switch>
          <Protected path="/locationsmap" exact component={<LocationMap />} />
          <Protected path="/locationsmap/:searchedText" exact component={<LocationMap />} />
          <Protected path="/locationsmap/details/:locationSlug" exact component={<LocationMap />} />
          {/* <Protected path="/dashboard" exact component={<Dashboard />} /> */}
          <Protected path="/locations" exact component={<LocationListing />} />
          <Protected path="/locations/:searchedText" exact component={<LocationListing />} />
          {/* <Protected path="/locations?searchedText" exact component={<LocationListing />} /> */}
          <Protected path="/location/details/:locationSlug" exact component={<LocationListing />} />
          <Protected path="/sectors" exact component={<SectorsListing />} />
          <Protected path="/sectors/:searchedText" exact component={<SectorsListing />} />
          {/* <Protected path="/sectors?searchedText" exact component={<SectorsListing />} /> */}
          <Protected exact path="/sectors/details/:sectorSlug" component={<SectorsListing />} />
          <Protected path="/companies" exact component={<CompanyListing />} />
          <Protected path="/companies/:searchedText" exact component={<CompanyListing />} />
          <Protected path="/companies/details/:companySlug" exact component={<CompanyListing />} />
          <Protected path="/investors" exact component={<InvestorsListing />} />
          <Protected path="/investors/:searchedText" exact component={<InvestorsListing />} />
          {/* <Protected path="/investors?searchedText" exact component={<InvestorsListing />} /> */}
          <Protected exact path="/investors/details/:investorSlug" component={<InvestorsListing />} />
          <Protected path="/cryptocurrencies" exact component={<CryptoCurrencyListing />} />
          <Protected path="/cryptocurrencies/:searchedText" exact component={<CryptoCurrencyListing />} />
          <Protected exact path="/cryptocurrencies/details/:cryptocurrencySlug" component={<CryptoCurrencyListing />} />
          <Protected path="/following/:followingType?" exact component={<FollowingListing />} />
          <Protected path="/reports/:reportType?" exact component={<OddupReportsListing />} />

          <Route path="/login" exact render={() => <LoginPage />} />
          <Route path="/signup" exact render={() => <SignupPage />} />
          <Route path="/forgotpw" exact render={() => <ForgotPasswordPage />} />
          <Route path="/change-password" exact render={() => <ChangePasswordPage />} />
          <Protected path="/settings/editprofile" exact component={<EditProfile />} />
          <Protected path="/settings/changepw" exact component={<ChangePassword />} />
          <Protected path="/settings/accountsettings" exact component={<AccountSettings />} />
          <Protected path="/settings/billing-details" exact component={<BillingDetails />} />
          <Protected path="/settings" exact component={<EditProfile />} />
          <Route path="/product-page" render={() => <ProductPage />} />
          <Route path="/about-us" render={() => <AboutPage />} />
          <Route path="/pricing-details" render={() => <PricingPage />} />
          <Route path="/payment-flow" render={() => <PaymentFlow />} />
          {/* <Route exact path="/safeti" render={() => <SafetiLandingPageParent />} /> */}
          {/* <Route path="/safeti-content" render={() => <SafetiLandingPageContent />} /> */}
          <Protected path="/upgrade" exact component={<ProductUpgrade />} />
          <Route
            exact
            path="/"
            render={() => {
              const isProductionAppDomain = (((window.location.hostname.toLocaleLowerCase()) === 'www.app.oddup.com')
               || ((window.location.hostname.toLocaleLowerCase()) === 'app.oddup.com'));
              if (isProductionAppDomain) {
                window.location.href = 'https://oddup.com';
                return null;
              }
              return <LandingPage />;
            }}
          />
        </Switch>
        )}
        {isProductioLandingDomain && (
        <Switch>
          <Route exact path="/" render={() => <LandingPage />} />
          <Route
            path="/login"
            exact
            render={() => {
              window.location.href = 'https://app.oddup.com/login';
              return null;
            }}
          />
          <Route
            path="/signup"
            exact
            render={() => {
              window.location.href = 'https://app.oddup.com/signup';
              return null;
            }}
          />
          <Route path="/pricing-details" exact render={() => <PricingPage />} />
        </Switch>
        )}
      </Suspense>
    </MainWrapper>
  );
};
export default RouteHandler;
