import axios from 'axios';
import { headersProviderWithAuthorization } from './apiHelpers';
import constants from '../constants';

export const getProfile = () => axios.get(`${constants.apiAuthHost}/user/editdetails`, {
  noTrailingSlash: true,
  headers: headersProviderWithAuthorization(),
});

export const updateProfile = ({
  location, name, company, role,
}) => axios.put(`${constants.apiAuthHost}/user/editdetails`, {
  location, name, company, role,
}, {
  noTrailingSlash: true,
  headers: headersProviderWithAuthorization(),
});

export const changePassword = ({
  oldPassword, newPassword,
}) => axios.put(`${constants.apiAuthHost}/user/password`, {
  old_password: oldPassword, new_password: newPassword,
}, {
  noTrailingSlash: true,
  headers: headersProviderWithAuthorization(),
});

export const getAccountDetails = () => axios.get(`${constants.apiAuthHost}/user/accountsettings`, {
  noTrailingSlash: true,
  headers: headersProviderWithAuthorization(),
});

export const updateAccountDetails = ({
  email, username,
}) => axios.put(`${constants.apiAuthHost}/user/accountsettings`, {
  email, username,
}, {
  noTrailingSlash: true,
  headers: headersProviderWithAuthorization(),
});

export const getUserFavourites = ({
  pageType, sortBy, sortDirection,
}) => axios.get(
  `${constants.apiHost}/user/get-favourites?page_type=${pageType}&sort_by=${sortBy}&sort_order=${sortDirection}`,
  {
    noTrailingSlash: true,
    headers: headersProviderWithAuthorization(),
  },
);
