/**
 * React
 * Author: Patanjali kumar (patanjali@oddup.com)
 * Copyright © 2020-present Oddup. All rights reserved.
 *
 This component will rendered all the time, all root / app booting time logic handling will
 be done through here
 */
import { useEffect } from 'react';
// import '../../utilities/chartjs-financial-plugin';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOf, node, oneOfType } from 'prop-types';
import { useLocation } from 'react-router';
import {
  getDatabase, ref, onValue, onChildAdded,
} from 'firebase/database';
import { apiWrap, apiWrapWithoutData } from '../../utilities/apis/apiHelpers';
import { getAllTags, getInitialValues } from '../../utilities/apis/filters';
import actions from '../../redux/actions';
import { loadingStates } from '../../utilities/general';
import { getAccountDetails, getProfile } from '../../utilities/apis/settings';
import { selectLoggedInStatus, selectUserProfile, selectUserSettings } from '../../redux/selectors';
import { identifyUserEvent, pageViewEvent, productSources } from '../../utilities/event';
import constants from '../../utilities/constants';

const MainWrapper = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);
  const userSettings = useSelector(selectUserSettings);
  const loggedInStatus = useSelector(selectLoggedInStatus);

  useEffect(() => {
    (async () => {
      dispatch({
        type: actions.SET_USER_SETTINGS,
        payload: {
          ...userSettings,
          loading: loadingStates.LOADING,
          data: null,
        },
      });
      const response = await apiWrapWithoutData(getAccountDetails());
      if (response && response.data && response.data.success) {
        dispatch({
          type: actions.SET_USER_SETTINGS,
          payload: {
            ...userProfile,
            loading: loadingStates.FINISHED,
            data: response.data.accountDetails,
          },
        });
      } else {
        dispatch({
          type: actions.SET_USER_SETTINGS,
          payload: {
            ...userProfile,
            loading: loadingStates.FAILED,
            data: null,
          },
        });
      }
    })();
  }, [loggedInStatus.loggedIn]);

  useEffect(() => {
    (async () => {
      dispatch({
        type: actions.SET_USER_PROFILE,
        payload: {
          ...userProfile,
          loading: loadingStates.LOADING,
          data: null,
        },
      });
      const response = await apiWrapWithoutData(getProfile());
      if (response && response.data && response.data.success) {
        dispatch({
          type: actions.SET_USER_PROFILE,
          payload: {
            ...userProfile,
            loading: loadingStates.FINISHED,
            data: response.data.profile,
          },
        });
        dispatch({
          type: actions.SET_LOGGED_IN_STATUS,
          payload: {
            loggedIn: true,
          },
        });
      } else {
        dispatch({
          type: actions.SET_USER_PROFILE,
          payload: {
            ...userProfile,
            loading: loadingStates.FAILED,
            data: null,
          },
        });
        dispatch({
          type: actions.SET_LOGGED_IN_STATUS,
          payload: {
            loggedIn: false,
          },
        });
      }
    })();
  }, [loggedInStatus.loggedIn]);

  useEffect(() => {
    (async () => {
      if (!loggedInStatus.loggedIn) return;
      const values = await apiWrap(getInitialValues());
      if (values && values.success) {
        dispatch({
          type: actions.SET_INITIAL_VALUES,
          payload: values.data,
        });
      }
    })();
  }, [loggedInStatus.loggedIn]);
  useEffect(() => {
    (async () => {
      if (!loggedInStatus.loggedIn) return;
      const values = await apiWrap(getAllTags());
      if (values && values.success) {
        dispatch({
          type: actions.SET_INITIAL_VALUES,
          payload: values.data,
        });
      }
    })();
  }, [loggedInStatus.loggedIn]);
  const location = useLocation();
  useEffect(() => {
    pageViewEvent({
      pathname: location.pathname,
      search: location.search,
      // pageName:
    });
    // if (window && window.ga) {
    //   window.ga('set', 'page', location.pathname + location.search);
    //   window.ga('send', 'pageview');
    // }
  }, [location]);

  const userSettingsWithLoader = useSelector(selectUserSettings);
  const userProfileWithLoader = useSelector(selectUserProfile);

  const convertFirebaseData = (data) => {
    const firebaseKeys = Object.keys(data);
    const newData = {};
    firebaseKeys.forEach((value) => {
      newData[value] = { ...data[value], key: value };
    });
    return newData;
  };

  useEffect(() => {
    if (userSettingsWithLoader.loading === loadingStates.FINISHED
        && userProfileWithLoader.loading === loadingStates.FINISHED
    ) {
      // firebase listener
      if (loggedInStatus.loggedIn) {
        const db = getDatabase();
        const nodeValue = `${constants.env}/notifications/${userSettingsWithLoader.data.userId}`;
        if (window.firebaseValueListener !== nodeValue) {
          const nodeRef = ref(db, nodeValue);
          onValue(nodeRef, (snapshot) => {
            const data = snapshot.val();
            dispatch({
              type: actions.SET_ALL_NOTIFICATION,
              payload: convertFirebaseData(data || {}),
            });
          });
          window.firebaseValueListener = nodeValue;
        }
      } else {
        console.log('not loggged in');
      }

      if (loggedInStatus.loggedIn) {
        const db = getDatabase();
        const nodeAdded = `${constants.env}/notifications/${userSettingsWithLoader.data.userId}`;
        if (window.firebaseAddedListener !== nodeAdded) {
          const nodeRef = ref(db, nodeAdded);
          onChildAdded(nodeRef, (snapshot) => {
            const data = { ...snapshot.val(), key: snapshot.key };
            if (!data.seen || data.seen === 'false') {
              dispatch({
                type: actions.SET_NOTIFICATION,
                payload: { ...data, seen: false },
              });
            }
          });
          window.firebaseAddedListener = nodeAdded;
        }
      } else {
        console.log('not loggged in');
      }
      const userSettingsData = userSettingsWithLoader.data || {};
      const userProfileData = userProfileWithLoader.data || {};
      identifyUserEvent({
        email: userSettingsData.email,
        properties: {
          FIRSTNAME: userProfileData.name.split(' ')[0],
          LASTNAME: (userProfileData.name.split(' ')[1] || ''),
          plan: (userSettingsData.plan_type || ''),
          PRODUCT_SOURCE: productSources.oddup_app,
          ODDUP_MEMBER: true,
        },
      });
    }
  }, [userSettingsWithLoader, userProfileWithLoader]);
  return (children);
};

MainWrapper.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

export default MainWrapper;
