/* eslint-disable max-len */
/**
 * React
 * Author: Ritesh Choudhary (ritesh@oddup.com)
 * Copyright © 2020-present Oddup. All rights reserved.
 *
 *
 */
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useLocation } from 'react-router';
import { passwordStrength } from 'check-password-strength';
import { useHistory } from 'react-router-dom';
import styles from './ChangePasswordPage.module.css';
import TextInput from '../../components/Inputs/TextInput';
import Button from '../../components/Inputs/Button';
import { assetPrefix, loadingStates } from '../../utilities/general';
import PercentageIndicator from '../DetailPages/companies/PercentageIndicator';
import { apiWrapWithErrorWithData } from '../../utilities/apis/apiHelpers';
import { changePassword } from '../../utilities/apis/loginSingup';

const ChangePasswordPage = () => {
  const history = useHistory();
  const queryString = (useLocation()).search;
  const params = new URLSearchParams(queryString);
  let initialMessage = {
    type: 'info',
    text: '',
  };
  if (params.get('emailConfirmed') === 'true' && (params.get('email')).length > 0) {
    initialMessage = {
      type: 'info',
      text: 'Thank you for confirming your email address. You now have access to the Oddup platform.',
    };
  }
  let preFilledEmail = '';
  if (params.get('email') && (params.get('email')).length > 0) {
    preFilledEmail = params.get('email');
  }
  const token = params.get('token');
  const [changePasswordConfig, setChangePasswordConfig] = useState({
    inputs: {
      email: preFilledEmail,
      password: '',
      rePassword: '',
    },
    errors: {
      email: '',
      password: '',
      rePassword: '',
    },
    totalErrors: 0,
    message: initialMessage,
    submitting: loadingStates.NO_ACTIVE_REQUEST,
    passwordStrength: 25,
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [changePasswordConfig.errors]);

  const handleSubmit = async () => {
    let totalErrors = 0;
    const errors = {};
    const calculatedPasswordStrength = passwordStrength(changePasswordConfig.inputs.password);
    if (calculatedPasswordStrength.contains.length < 4) {
      errors.password = 'Password should contain a digit, a lowercase, an uppercase and a special character.';
      totalErrors += 1;
    }
    if (calculatedPasswordStrength.length < 8) {
      errors.password = 'Password should be at least 8 characters.';
      totalErrors += 1;
    }
    if (changePasswordConfig.inputs.password !== changePasswordConfig.inputs.rePassword) {
      errors.rePassword = 'Password did not match';
      totalErrors += 1;
    }
    if (totalErrors !== 0) {
      setChangePasswordConfig({
        ...changePasswordConfig,
        errors,
        totalErrors,
      });
      return;
    }
    setChangePasswordConfig({
      ...changePasswordConfig,
      submitting: loadingStates.LOADING,
    });
    const response = await apiWrapWithErrorWithData(changePassword({
      password: changePasswordConfig.inputs.password,
      token,
    }));
    if (response && response.success) {
      history.push('/login');
    } else {
      setChangePasswordConfig({
        ...changePasswordConfig,
        submitting: loadingStates.NO_ACTIVE_REQUEST,
        message: {
          type: 'error',
          text: (response && response.message) ? response.message : 'Something went wrong.',
        },
      });
    }
  };

  const inputHandler = (inputName) => (e) => {
    const merge = {};
    if (inputName === 'password') {
      merge.passwordStrength = (parseInt(passwordStrength(e.target.value).id, 10) + 1) * 25;
    }
    setChangePasswordConfig({
      ...changePasswordConfig,
      inputs: {
        ...changePasswordConfig.inputs,
        [inputName]: e.target.value,
      },
      ...merge,
    });
  };
  let textClassToApply = styles.messageSuccess;
  if (changePasswordConfig.message) {
    if (changePasswordConfig.message.type === 'error') textClassToApply = styles.messageError;
    if (changePasswordConfig.message.type === 'info') textClassToApply = styles.messageInfo;
  }
  return (
    <>
      {(!!changePasswordConfig.message && !!changePasswordConfig.message.text.length) && (
        <div
          className={`w-full flex justify-center ${textClassToApply}`}
        >
          {changePasswordConfig.message.text}
        </div>
      )}
      <div className="flex items-center justify-center h-full h-screen">
        <div className="items-center relative">
          <div className="items-center">
            <img className={styles.logoHeader} src="../../oddup-app/assets/logo/oddup.svg" alt="Oddup" />
          </div>
          <div className={`font-semibold mb-5 mt-3 ${styles.headingStyle}`}>Change Password</div>
          <p
            style={{
              maxWidth: '33rem',
            }}
            className="text-base font-normal mb-5"
          >
            Enter and confirm new password.
          </p>
          <TextInput
            customStyles={{
              maxWidth: '33rem',
            }}
            onChange={inputHandler('password')}
            value={changePasswordConfig.inputs.password}
            title="Password"
            placeholder="Minimum 8 Characters"
            errored={changePasswordConfig.errors.password}
            errorIcon={`${assetPrefix}/images/form-icon-error-password.svg`}
            normalIcon={`${assetPrefix}/images/form-icon-normal-password.svg`}
            type="password"
            errorToolTip={changePasswordConfig.errors.password}
          />
          <div
            className="flex justify-between mt-2 mb-4"
            style={{
              maxWidth: '33rem',
            }}
          >
            <PercentageIndicator
              percentStyles={{
                minWidth: '1.3rem',
                width: '1.3rem',
                height: '0.312rem',
                minHeight: '0.312rem',
              }}
              percent={changePasswordConfig.passwordStrength}
            />
          </div>
          <TextInput
            customStyles={{
              maxWidth: '33rem',
            }}
            onChange={inputHandler('rePassword')}
            value={changePasswordConfig.inputs.rePassword}
            title="Re-Password"
            placeholder="Minimum 8 Characters"
            errored={changePasswordConfig.errors.rePassword}
            errorIcon={`${assetPrefix}/images/form-icon-error-password.svg`}
            normalIcon={`${assetPrefix}/images/form-icon-normal-password.svg`}
            type="password"
            errorToolTip={changePasswordConfig.errors.rePassword}
          />
          <br />
          <Button
            extraStyles={{
              maxWidth: '33rem',
            }}
            onClick={handleSubmit}
            text="Change Password"
            loading={changePasswordConfig.submitting === loadingStates.LOADING}
          />
        </div>
      </div>
    </>
  );
};

export default ChangePasswordPage;
