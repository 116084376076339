import React, { useEffect } from 'react';
import anime from 'animejs';
import styles from './CommonStyle.module.css';
import { assetPrefix } from '../../utilities/general';
import { devices, getDevice } from '../../utilities/animations';

const ComprehensiveInsights = () => {
  useEffect(() => {
    const animate = () => {
      anime({
        keyframes: [
          // {
          //   translateY: -50,
          // },
          {
            // delay: 100,
            opacity: 1,
            translateY: 0,
          }],
        targets: '.cryptoAnime',
        duration: 300,
        easing: 'easeInQuad',
        delay: anime.stagger(200, { from: 'last' }),
      });
    };

    // Add an event listener
    document.addEventListener('entry-animation-complete', (e) => {
      if (e.detail.from === 'section-3') {
        animate();
      }
    });
  }, []);
  const animatedStyles = (preTransform) => {
    if (getDevice() !== devices.DESKTOP) {
      return ({});
    }
    return ({
      opacity: 0,
      transform: `translateY(-50px)${preTransform || ''}`,
    });
  };
  return (
    <div
      className={`relative mt-1 lg:-mt-28 ml-8 ${styles.comprehensiveFrameWidth}`}
    >
      <div
        className={`mt-4 ml-0 ${styles.comprehensiveInsightscircle} absolute left-4 top-full cryptoAnime`}
        style={{
          ...animatedStyles(),
        }}
      >
        <img
          className={`pt-0 lg:pt-3 ${styles.imageStyle}`}
          src={`${assetPrefix}/images/productPage/xrp.png`}
          alt="icon"
        />
      </div>
      <div
        style={{
          ...animatedStyles(),
        }}
        className={`mt-8 md:mt-10 md:ml-20 lg:ml-10 lg:mt-16 ml-10 ${styles.comprehensiveInsightscircle} absolute left-1/4 top-full cryptoAnime`}
      >
        <img
          className={`pt-0 lg:pt-3 ${styles.imageStyle}`}
          src={`${assetPrefix}/images/productPage/ada.png`}
          alt="icon"
        />
      </div>
      <div
        style={{
          ...animatedStyles(),
        }}
        className={`mt-0 ml-0 ${styles.comprehensiveInsightscircle} absolute -left-4 lg:top-0 top-2 cryptoAnime`}
      >
        <img
          className={`pt-0 lg:pt-3 ${styles.imageStyle}`}
          src={`${assetPrefix}/images/landingPage/l.svg`}
          alt="icon"
        />
      </div>
      <div
        style={{
          ...animatedStyles(),
        }}
        className={`mt-0 lg:mt-5 -mr-4 ${styles.comprehensiveInsightscircle} absolute right-12 lg:right-1/4 top-full cryptoAnime`}
      >
        <img
          className={`pt-0 lg:pt-3 align-center ${styles.imageStyle}`}
          src={`${assetPrefix}/images/landingPage/crypto.svg`}
          alt="icon"
        />
      </div>
      <div
        className="flex items-center pl-0 md:pl-10 ml-7 md:ml-0"
      >
        <div
          style={{
            ...animatedStyles(),
          }}
          className={`mt-4 ml-auto ${styles.comprehensiveInsightscircle} absolute lg:right-20 2xl:right-16 lg:top-20 right-0 top-10 cryptoAnime`}
        >
          <img
            className={`pt-0 lg:pt-3 ${styles.imageStyle}`}
            src={`${assetPrefix}/images/landingPage/crypto2.svg`}
            alt="icon"
          />
        </div>
        <div
          style={{
            ...animatedStyles(),
          }}
          className={`${styles.cardMargin} flex items-start -mt-4 mb-3 md:mb-5 ml-2 md:ml-5 md:ml-10 lg:ml-32 xl:ml-20 cryptoAnime ${styles.comprehensiveInsightsShadowStyle}`}
        >
          <img
            className={`${styles.iconStyle2}`}
            src={`${assetPrefix}/images/productPage/btc.png`}
            alt="icon"
          />
          <div className="font-semibold text-sm md:text-base lg:text-xl ml-2 mb-2 md:ml-4 lg:mb-4">
            <div className="flex">
              <div>
                Bitcoin
                <span className="ml-3" style={{ color: '#C0C0C0' }}>BTC</span>
                <div className="mr-1">$40,600.00</div>
              </div>
              <div className="ml-3 mt-3" style={{ color: '#4ECA2C' }}>+3.42%</div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          ...animatedStyles(),
        }}
        className={`flex items-start mt-4 xl:ml-7 md:ml-7 lg:ml-20 cryptoAnime ${styles.comprehensiveInsightsShadowStyle}`}
      >
        <img
          className={`${styles.iconStyle2}`}
          src={`${assetPrefix}/images/landingPage/eth.svg`}
          alt="icon"
        />
        <div className="font-semibold text-sm md:text-base lg:text-xl ml-2 mb-2 md:ml-4 lg:mb-4">
          <div className="flex">
            <div>
              Ethereum
              <span className="ml-3" style={{ color: '#C0C0C0' }}>ETH</span>
              <div className="mr-1">$2,505.00</div>
            </div>
            <div className="ml-3 mt-3" style={{ color: '#4ECA2C' }}>+1.42%</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComprehensiveInsights;
