import React, { useState } from 'react';
import { Check, ArrowBack } from '@mui/icons-material';
import Switch from 'react-switch';
import styles from './commonStyle.module.css';
import PageHead from '../../components/PageHead/PageHead';
import { assetPrefix } from '../../utilities/general';
import RightPlan from '../../components/ComparePlans/ComparePlans';
import constants from '../../utilities/constants';

const PaymentFlow = () => {
  const [checked, setChecked] = useState(false);
  const [searchText, setSearchText] = useState('');
  const planType = checked ? 'yearly' : 'monthly';
  const selectedPeriod = planType === 'yearly' ? 'annually' : 'monthly';

  const handleChange = (event) => {
    setChecked(event);
  };

  const handleOuterClick = () => {
    setSearchText('');
  };

  return (
    <div onClick={handleOuterClick}>
      <PageHead
        pageTitle="Sectors"
        showBorderColor={false}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <div className={`${styles.backBtn} flex justify-center pt-36`}>
        <ArrowBack className="mr-1" />
        Go back to Oddup App
      </div>
      <div className={`2xl:pt-12 pt-8 xl:mx-auto font-bold 2xl:text-3xl text-center w-11/12 mr-auto ${styles.heading}`}>
        Upgrade to the plan that’s right for you
      </div>
      <div className={`${styles.subHeading} xl:mx-auto 2xl:text-lg text-center 2xl:py-7 w-11/12 mr-auto ${styles.textColor}`}>
        Pay by month or by year, and cancel at any time.
      </div>
      <div className="2xl:pt-9 pt-5 flex items-center justify-center toggle_btn">
        <div className={`2xl:text-2xl text-lg font-bold ${checked ? styles.switchColor : ''}`}>Monthly</div>
        <Switch
          onChange={handleChange}
          checked={checked}
          className="mx-3"
          offColor="#DFF0FF"
          onColor="#DFF0FF"
          offHandleColor="#4AA8FF"
          onHandleColor="#4AA8FF"
          uncheckedIcon={false}
          checkedIcon={false}
          width={window.screen.width <= 1366 ? 29.4 : 42}
          height={window.screen.width <= 1366 ? 15.4 : 22}
        />
        <div className={`2xl:text-2xl text-lg font-bold ${!checked ? styles.switchColor : ''}`}>Annually</div>
      </div>
      <section className="flex justify-center pt-20 pb-12 px-5">
        <div className={`${styles.upgradeBorder} w-3/12 mr-12`}>
          <div className="text-center pt-14">
            <div className="flex justify-center">
              <img src={`${assetPrefix}/logo/oddup.png`} alt="Oddup" />
            </div>
            <div className="px-9 pt-4 2xl:h-48 h-36">
              <div className={`${styles.upgradeHeading} font-bold`}>
                <span>Oddup for Sales</span>
              </div>
              <div className={`${styles.textColor} ${styles.subHeading} 2xl:text-lg 2xl:pt-8 pt-5`}>
                Get a unlimited access to Oddup to
                plus all the benifts from the free plan
              </div>
            </div>
            <div className={`font-bold mb-2 ${styles.valueSize}`}>
              $
              {constants.pricingPlans.forSales[planType].priceInUSD}
            </div>
            <div className={`${styles.subHeading} 2xl:text-lg ${styles.textColor}`}>
              billed
              {' '}
              {selectedPeriod}
            </div>
            <div className={`${styles.switchBtn} ${styles.subHeading} 2xl:text-lg mt-1`}>Switch to annual billing</div>
          </div>
          <div className={`h-48 2xl:text-lg pl-9 py-8 ${styles.subHeading}`}>
            <div className="flex">
              <Check className={styles.switchBtn} />
              <div className={`pl-3 ${styles.textColor}`}>Get Unlimited</div>
            </div>
            <div className="flex pt-3.5">
              <Check className={styles.switchBtn} />
              <div className={`pl-3 ${styles.textColor}`}>Get Unlimited</div>
            </div>
            <div className="flex pt-3.5">
              <Check className={styles.switchBtn} />
              <div className={`pl-3 ${styles.textColor}`}>Get Unlimited</div>
            </div>
            <div className="flex pt-3.5">
              <Check className={styles.switchBtn} />
              <div className={`pl-3 ${styles.textColor}`}>Get Unlimited</div>
            </div>
          </div>
          <div className="w-10/12 mx-auto text-center py-11 h-44">
            <div className={styles.modalBtn}>
              Get Started
            </div>
          </div>
        </div>
        <div className={`w-3/12 ${styles.upgradeBorder}`}>
          <div className="text-center pt-14">
            <div className="flex justify-center">
              <img src={`${assetPrefix}/logo/oddup.png`} alt="Oddup" />
            </div>
            <div className="px-9 pt-4 2xl:h-48 h-36">
              <div className={`${styles.upgradeHeading} font-bold`}>
                <span>Oddup for Sales</span>
              </div>
              <div className={`${styles.textColor} ${styles.subHeading} 2xl:text-lg 2xl:pt-8 pt-5 font-normal`}>
                Get a unlimited access to Oddup to
                plus all the benifts from the free plan
              </div>
            </div>
            <div className={`font-bold ${styles.valueSize} mb-2`}>
              $
              {constants.pricingPlans.forInvestors[planType].priceInUSD}
            </div>
            <div className={`${styles.subHeading} 2xl:text-lg ${styles.textColor}`}>
              billed
              {' '}
              {selectedPeriod}
            </div>
            <div className={`2xl:text-lg mt-1 ${styles.subHeading} ${styles.switchBtn}`}>Switch to annual billing</div>
          </div>
          <div className={`h-48 2xl:text-lg pl-9 py-8 ${styles.subHeading}`}>
            <div className="flex">
              <Check className={styles.switchBtn} />
              <div className={`pl-3 ${styles.textColor}`}>Get Unlimited</div>
            </div>
            <div className="flex pt-3.5">
              <Check className={styles.switchBtn} />
              <div className={`pl-3 ${styles.textColor}`}>Get Unlimited</div>
            </div>
            <div className="flex pt-3.5">
              <Check className={styles.switchBtn} />
              <div className={`pl-3 ${styles.textColor}`}>Get Unlimited</div>
            </div>
            <div className="flex pt-3.5">
              <Check className={styles.switchBtn} />
              <div className={`pl-3 ${styles.textColor}`}>Get Unlimited</div>
            </div>
          </div>
          <div className="w-10/12 mx-auto text-center py-11 h-44">
            <div className={styles.modalBtn}>
              Get Started
            </div>
          </div>
        </div>
      </section>
      <div className={`pb-40 ${styles.upgradeBg}`}>
        <div className={`font-bold text-center mt-32 pt-16 ${styles.heading}`}>The right plan for you</div>
        <RightPlan
          width="4/6"
          handleChange={handleChange}
          checked={checked}
          selectedPeriod={selectedPeriod}
          constant1={constants.pricingPlans.forSales[planType].priceInUSD}
          constant2={constants.pricingPlans.forInvestors[planType].priceInUSD}
        />
      </div>
    </div>
  );
};

export default PaymentFlow;
