import React, { useEffect } from 'react';
import anime from 'animejs';
import styles from './CommonStyle.module.css';
import { assetPrefix } from '../../utilities/general';
import { devices, getDevice } from '../../utilities/animations';

const HealthBarometer = () => {
  useEffect(() => {
    const animate = () => {
      anime({
        keyframes: [
          // {
          //   translateY: -50,
          // },
          {
            // delay: 100,
            opacity: 1,
            translateY: 0,
          }],
        targets: '.animeCryptoCircle',
        duration: 300,
        easing: 'easeInQuad',
        delay: anime.stagger(200, { from: 'last' }),
      });
    };

    // Add an event listener
    document.addEventListener('entry-animation-complete', (e) => {
      if (e.detail.from === 'section-5') {
        animate();
      }
    });
  }, []);
  const animatedStyles = (preTransform) => {
    if (getDevice() !== devices.DESKTOP) {
      return ({});
    }
    return ({
      opacity: 0,
      transform: `translateY(-50px)${preTransform || ''}`,
    });
  };
  return (
    <div
      className={styles.healthBarometerFrame}
    >
      <div className="flex items-center">
        <div
          className={`ml-auto mt-10 ${styles.fifthImageShadowStyle} animeCryptoCircle`}
          style={{
            ...animatedStyles(),
          }}
        >
          <div className="flex items-start">
            <div className="relative">
              <img
                className={`mx-auto ${styles.iconStyle}`}
                src={`${assetPrefix}/images/productPage/ada.png`}
                alt="icon"
              />
              <span className={styles.badgeStyle1}>89</span>
            </div>
            <div className="font-semibold text-lg md:text-xl lg:text-3xl ml-3 lg:ml-4">
              Cardano
              <div
                className={`flex items-center ${styles.subHeading} md:text-base font-medium leading-3`}
                style={{ color: '#A9A9A9' }}
              >
                <div className="mr-1">Zug, Switzerland</div>
                <div className={styles.dotStyle} />
                <div className="ml-1">Financial Technology</div>
              </div>
            </div>
          </div>
          <div className={styles.divStyle}>
            <img
              src={`${assetPrefix}/images/landingPage/cardanoGraph.svg`}
              className={`mt-0 md:mt-1 lg:mt-4 mx-auto ${styles.barometerGraph}`}
              alt="graph"
            />
          </div>
        </div>
        <div
          className={`mt-10 ml-7 ${styles.circleshadowStyle1} animeCryptoCircle`}
          style={{
            ...animatedStyles(),
          }}
        >
          <img
            className={`pt-0 md:pt-3 ${styles.barometerIconStyle}`}
            src={`${assetPrefix}/images/productPage/buxx.png`}
            alt="icon"
          />
          <div className="relative">
            <span className={styles.badgeStyle1}>82</span>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div
          className={`mt-4 ml-auto ${styles.circleshadowStyle1} ${styles.circleshadowStyle2} animeCryptoCircle`}
          style={{
            ...animatedStyles(),
          }}
        >
          <img
            className={`pt-0 md:pt-3 ${styles.barometerIconStyle}`}
            src={`${assetPrefix}/images/landingPage/search.svg`}
            alt="icon"
          />
          <div className="relative">
            <span className={`${styles.badgeStyle1} ${styles.badgeStyle2}`}>55</span>
          </div>
        </div>
        <div
          className={`mt-4 ml-7 ${styles.fifthImageShadowStyle} animeCryptoCircle`}
          style={{
            ...animatedStyles(),
          }}
        >
          <div className="flex items-start">
            <div className="relative">
              <img
                className={`mx-auto ${styles.iconStyle}`}
                src={`${assetPrefix}/images/landingPage/Zalora.svg`}
                alt="icon"
              />
              <span className={`${styles.badgeStyle1} ${styles.badgeStyle2}`}>57</span>
            </div>
            <div
              className="font-semibold text-lg md:text-xl lg:text-3xl ml-3 lg:ml-4 animeCryptoCircle"
              style={{
                ...animatedStyles(),
              }}
            >
              Zalora
              <div
                className={`flex items-center ${styles.subHeading} md:text-base font-medium leading-3`}
                style={{ color: '#A9A9A9' }}
              >
                <div className="mr-1">Singapore</div>
                <div className={styles.dotStyle} />
                <div className="ml-1">Fashion & Beauty</div>
              </div>
            </div>
          </div>
          <div>
            <img
              src={`${assetPrefix}/images/landingPage/zaloraGraph.svg`}
              className={`mt-4 md:mt-5 lg:mt-10 mx-auto ${styles.barometerGraph}`}
              alt="graph"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthBarometer;
