import Cookies from 'js-cookie';

export const setCookiesSession = (cookieName, authToken, expiryTime) => {
  // const secondsInMinute = expiryTime / 60;
  // const in120Minutes = secondsInMinute / 1440;
  Cookies.set(cookieName, authToken, {
    expires: expiryTime,
  });
};

export const deleteCookie = (cookieName) => {
  Cookies.remove(cookieName);
};
export const getCookiesSession = (cookieName) => Cookies.get(cookieName);
