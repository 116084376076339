/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { useHistory } from 'react-router-dom';
import {
  MenuItem, MenuList, Button, ClickAwayListener, Grow, Paper, Popper,
} from '@material-ui/core';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import './VerticalTimeline.min.css';
// import Carousel from 'react-material-ui-carousel';
import styles from './AboutPage.module.css';
import buttonize, { assetPrefix } from '../../utilities/general';

const AboutPage = () => {
  const typeform = useRef(null);
  const cryptoDiv = useRef(null);
  const toCheckScroll = useRef(null);
  const featuredClients = useRef(null);
  const anchorRef = useRef(null);
  const history = useHistory();
  const [activeNav, setActiveNav] = useState(null);
  const [open, setOpen] = useState(false);
  const [showHeaderBg, setShowHeaderBg] = useState(false);
  const [screen, setScreen] = useState(window.screen.availWidth);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const scrollListener = () => {
    if (window.scrollY > 150 && !showHeaderBg) {
      setShowHeaderBg(true);
    }
    if (window.scrollY < 150 && showHeaderBg) {
      setShowHeaderBg(false);
    }
    const cryptoTarget = toCheckScroll.current;
    const researchTarget = featuredClients.current;
    const passedResearchTarget = researchTarget !== null && window.scrollY > (
      researchTarget.offsetTop + researchTarget.offsetHeight
    );
    const passedCryptoTarget = cryptoTarget !== null && window.scrollY > (
      cryptoTarget.offsetTop + cryptoTarget.offsetHeight
    );
    if (passedCryptoTarget && passedResearchTarget) {
      setActiveNav('crypto');
      return;
    }
    if (passedResearchTarget) {
      setActiveNav('research');
    }

    if (!passedResearchTarget && !passedCryptoTarget) {
      setActiveNav(null);
    }
    // if (scrolled && !passed) {
    //   setScrolled(false);
    // }
    // if (!scrolled && passed) {
    //   setScrolled(true);
    // }
  };

  useEffect(() => {
    if (toCheckScroll && toCheckScroll.current && featuredClients && featuredClients.current) {
      window.removeEventListener('scroll', scrollListener);
      window.addEventListener('scroll', scrollListener);
    }
  }, [activeNav]);

  const typeformOpen = () => {
    if (typeform) {
      typeform.current.typeform.open();
    }
  };

  useEffect(() => {
    setScreen(window.screen.availWidth);
  }, [window.screen]);
  return (
    <div className={open ? `${styles.overlay}` : ''}>
      <img src={`${assetPrefix}/images/aboutusPage/topRightEllipse.png`} alt="Oddup" className={`hidden lg:block ${styles.topLeftImage}`} />
      { /* Header -- start */ }
      <div className={`${styles.headerParentContainer} ${styles.headerbgImage} ${showHeaderBg ? styles.headerBg : ''}`}>
        <div className="lg:pt-2 lg:pb-2 flex items-center justify-between w-full xl:w-10/12 mx-auto px-10 xl:px-5">
          <div className="md:text-left text-center">
            <img
              className={`md:mr-auto ${styles.imageStyle}`}
              src={`${assetPrefix}/logo/oddup.svg`}
              alt="bubbles"
              {...buttonize(() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              })}
            />
          </div>
          {screen > 1025
            ? (
              <>
                <div className="flex">
                  <div className="font-semibold text-base pr-9">DISCOVER ODDUP</div>
                  <div className="font-semibold text-base pr-9">PRODUCTS</div>
                  <div className="font-semibold text-base pr-9">SERVICES</div>
                  <div className="font-semibold text-base">INSIGHTS</div>
                </div>
                <div className="flex">
                  <span className={`${styles.loginBtn} mr-2.5`} onClick={() => history.push('/login')}>
                    LOGIN
                  </span>
                  <span className={styles.signUpBtn} onClick={() => history.push('/signup')}>
                    SIGNUP
                  </span>
                </div>
              </>
            )
            : (
              <div>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  {!open
                    ? <img src={`${assetPrefix}/images/aboutusPage/menu.png`} alt="Oddup" className={styles.menu} />
                    : <img src={`${assetPrefix}/images/aboutusPage/close.png`} alt="Oddup" className={styles.menu} />}
                </Button>
                <Popper className={styles.dropdownStyle} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper className={styles.paperStyle}>
                        <ClickAwayListener>
                          <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <MenuItem>Discover Oddup</MenuItem>
                            <MenuItem>Products</MenuItem>
                            <MenuItem>Services</MenuItem>
                            <MenuItem>Insights</MenuItem>
                            <MenuItem className="flex flex-col">
                              <div className={`${styles.loginBtn} w-full mb-7`} {...buttonize(typeformOpen)}>
                                LOGIN
                              </div>
                              <div className={styles.signUpBtn} {...buttonize(typeformOpen)}>
                                SIGNUP
                              </div>
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )}
        </div>
      </div>
      { /* Header -- end */ }
      <section className={`flex justify-between items-center xl:w-10/12 px-5 ${styles.sectionContainer}`}>
        <div className={styles.topContent}>
          <div className={styles.gradientText}>
            Behind a great product,
            there’s a great team.
          </div>
          <div className={styles.subtitle}>
            Quisque fringilla lacus lectus, vitae viverra mi suscipit commodo. Sed vel velit felis.
            Nunc aliquam nulla felis, ac consequat urna placerat sed.
          </div>
          <div className="flex lg:justify-start">
            <div className={styles.exploreBtn} {...buttonize(typeformOpen)}>
              Meet Us
            </div>
          </div>
        </div>
        <div
          className={styles.topImageStyle}
          style={{
            zIndex: 101,
          }}
        >
          <img src={`${assetPrefix}/images/aboutusPage/personsGroup.png`} className={`${styles.rightImage} ml-auto`} alt="Persons Group" />
        </div>
      </section>

      <img src={`${assetPrefix}/images/aboutusPage/rectangle1.png`} alt="Persons Group" className={`${styles.hideOnMobile} mt-40 w-full`} />

      <div className={`mr-auto sm:w-3/4 xl:mx-auto font-semibold text-5xl text-center py-16 lg:py-48 md:py-20 w-11/12 mr-auto sm:w-full ${styles.centerHeading}`}>
        Developing a game-changing platform &nbsp;
        <br />
        requires expertise from a wide &nbsp;
        <br />
        spectrum of fields.
      </div>

      <section className={`${styles.generalContainer} xl:w-10/12 pb-12 lg:pb-12 px-5 relative`} ref={featuredClients}>
        <div className="flex justify-between flex-col md:flex-row items-center">
          <div className={`${styles.centerImage} lg:w-1/2 md:w-2/5 w-full`}>
            <img src={`${assetPrefix}/images/aboutusPage/rectangle2.png`} alt="Persons Group" />
          </div>
          <div className="mt-20 px-10 md:pl-20 lg:mt-0 lg:w-1/2 md:w-3/5 w-full">
            <div className="font-semibold text-sm lg:text-lg mb-9" style={{ color: '#4499E8' }}>
              OUR VISION
            </div>
            <span className={styles.featureTitle}>
              Quisque faucibus nunc sit
              <br />
              amet metus luctus.
            </span>
            <span className={`${styles.featureDesc} mt-10 hidden lg:block`}>
              Quisque fringilla lacus lectus, vitae viverra mi suscipit commodo. Sed vel velit felis.
              Nunc aliquam nulla felis, ac consequat urna placerat sed. Etiam pulvinar neque
              massa, sed sagittis enim lobortis eu. Quisque faucibus nunc sit amet metus luctus
              dictum. Nunc laoreet mi et vehicula dignissim.
            </span>
            <span className={`${styles.featureDesc} mt-6 block lg:hidden`}>
              Quisque fringilla lacus lectus, vitae viverra mi suscipit commodo. Sed vel velit felis. Nunc aliquam nulla felis, ac consequat urna placerat sed. Etiam pulvinar neque massa, sed sagittis enim lobortis eu. Quisque faucibus nunc sit amet metus luctus dictum. Nunc laoreet mi et vehicula dignissim.
            </span>
            <br />
          </div>
        </div>
      </section>

      <section className={`${styles.generalContainer} xl:w-10/12 md:pt-28 lg:pb-20 px-5 relative`} ref={featuredClients}>
        <div className="flex justify-between flex-col md:flex-row items-center">
          <div className={`${styles.centerImage} ${styles.orderStyle} mt-20 px-10 md:pr-20 lg:mt-0 lg:w-1/2 md:w-3/5 w-full`}>
            <div className="font-semibold text-sm lg:text-lg mb-9" style={{ color: '#4499E8' }}>
              OUR VISION
            </div>
            <span className={styles.featureTitle}>
              Quisque faucibus nunc sit
              <br />
              amet metus luctus.
            </span>
            <span className={`${styles.featureDesc} mt-10 hidden lg:block`}>
              Quisque fringilla lacus lectus, vitae viverra mi suscipit commodo. Sed vel velit felis.
              Nunc aliquam nulla felis, ac consequat urna placerat sed. Etiam pulvinar neque
              massa, sed sagittis enim lobortis eu. Quisque faucibus nunc sit amet metus luctus
              dictum. Nunc laoreet mi et vehicula dignissim.
            </span>
            <span className={`${styles.featureDesc} mt-6 block lg:hidden`}>
              Quisque fringilla lacus lectus, vitae viverra mi suscipit commodo. Sed vel velit felis. Nunc aliquam nulla felis, ac consequat urna placerat sed. Etiam pulvinar neque massa, sed sagittis enim lobortis eu. Quisque faucibus nunc sit amet metus luctus dictum. Nunc laoreet mi et vehicula dignissim.
            </span>
            <br />
          </div>
          <div className="lg:w-1/2 md:w-2/5 w-full">
            <img src={`${assetPrefix}/images/aboutusPage/rectangle2.png`} alt="Persons Group" className="w-full" />
          </div>
        </div>
      </section>

      <div className={`w-full ${styles.feature3Container} relative`} ref={toCheckScroll}>
        <section className="mt-20 lg:mt-56 px-5">
          <div className={`justify-between items-center py-20 ${styles.mapCitiesContainer}`}>
            <div className={`xl:w-10/12 px-6 mx-auto ${styles.featureTitle}`}>
              Press Mentions
            </div>
            <div className="grid grid-flow-row xl:grid-cols-3  lg:grid-cols-2 gap-8 px-6">
              <div className={`items-start pt-10 flex sm:pt-24 sm:items-center ${styles.mentionsImageStyle}`}>
                <img src={`${assetPrefix}/images/aboutusPage/PM1.png`} className="block" alt="PM1" />
                <div className="font-semibold text-sm lg:text-xl pl-9">
                  Oddup Adds ICO and Cryptocurrency
                  Analysis to Its Startup Insights and
                  Rating Platform
                  <div className="text-xs lg:text-lg pt-2.5 flex" style={{ color: '#4499E8' }}>
                    Markets Insider
                    <div className="pl-4" style={{ color: '#BFBFBF' }}>April 8, 2018</div>
                  </div>
                </div>
              </div>
              <div className={`items-start pt-10 flex sm:pt-24 sm:items-center ${styles.mentionsImageStyle}`}>
                <img src={`${assetPrefix}/images/aboutusPage/PM2.png`} className="block" alt="PM2" />
                <div className="font-semibold text-sm lg:text-xl pl-9">
                  Series A rounds are about funding
                  growth. Oddup has got just the right
                  partners to do that
                  <div className="text-xs lg:text-lg pt-2.5 flex" style={{ color: '#4499E8' }}>
                    HuffingtonPost
                    <div className="pl-4" style={{ color: '#BFBFBF' }}>April 8, 2018</div>
                  </div>
                </div>
              </div>
              <div className={`items-start pt-10 flex sm:pt-24 sm:items-center ${styles.mentionsImageStyle}`}>
                <img src={`${assetPrefix}/images/aboutusPage/PM3.png`} className="block" alt="PM3" />
                <div className="font-semibold text-sm lg:text-xl pl-9">
                  Times Group, others put Rs 40 crore in
                  startup-­focused research platform
                  Oddup
                  <div className="text-xs lg:text-lg pt-2.5 flex" style={{ color: '#4499E8' }}>
                    Markets Insider
                    <div className="pl-4" style={{ color: '#BFBFBF' }}>April 8, 2018</div>
                  </div>
                </div>
              </div>
              <div className={`items-start pt-10 flex sm:pt-24 sm:items-center ${styles.mentionsImageStyle}`}>
                <img src={`${assetPrefix}/images/aboutusPage/PM4.png`} className="block" alt="PM4" />
                <div className="font-semibold text-sm lg:text-xl pl-9">
                  Oddup Adds ICO and Cryptocurrency
                  Analysis to Its Startup Insights and
                  Rating Platform
                  <div className="text-xs lg:text-lg pt-2.5 flex" style={{ color: '#4499E8' }}>
                    Markets Insider
                    <div className="pl-4" style={{ color: '#BFBFBF' }}>April 8, 2018</div>
                  </div>
                </div>
              </div>
              <div className={`items-start pt-10 flex sm:pt-24 sm:items-center ${styles.mentionsImageStyle}`}>
                <img src={`${assetPrefix}/images/aboutusPage/PM5.png`} className="block" alt="PM5" />
                <div className="font-semibold text-sm lg:text-xl pl-9">
                  Series A rounds are about funding
                  growth. Oddup has got just the right
                  partners to do that
                  <div className="text-xs lg:text-lg pt-2.5 flex" style={{ color: '#4499E8' }}>
                    HuffingtonPost
                    <div className="pl-4" style={{ color: '#BFBFBF' }}>April 8, 2018</div>
                  </div>
                </div>
              </div>
              <div className={`items-start pt-10 flex sm:pt-24 sm:items-center ${styles.mentionsImageStyle}`}>
                <img src={`${assetPrefix}/images/aboutusPage/PM6.png`} className="block" alt="PM6" />
                <div className="font-semibold text-sm lg:text-xl pl-9">
                  Times Group, others put Rs 40 crore in
                  startup-­focused research platform
                  Oddup
                  <div className="text-xs lg:text-lg pt-2.5 flex" style={{ color: '#4499E8' }}>
                    Markets Insider
                    <div className="pl-4" style={{ color: '#BFBFBF' }}>April 8, 2018</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className={`${styles.generalContainer} mt-14 lg:mt-56 mb-28`} ref={cryptoDiv}>
        <div className="flex flex-col">
          <span className={`text-left sm:px-14 md:text-center text-sm lg:text-lg font-semibold pb-6 ${styles.verticalTimelineHeadind}`} style={{ color: '#4499E8' }}>
            OUR JOURNEY
          </span>
          <span className={`${styles.featureTitle} ${styles.verticalTimelineHeadind} md:text-center text-left sm:px-14`}>
            Our Company Highlights
          </span>
          <VerticalTimeline animate={false} className={styles.verticalTimeline}>
            <VerticalTimelineElement
              className={styles.verticalTimelineYear}
              contentStyle={{ background: '#fff', color: '#1D1D1D' }}
              iconStyle={{
                background: '#4499E8', color: '#fff', borderRadius: '20px', height: '40px', width: '93px', marginLeft: '-40px', boxShadow: 'none',
              }}
              icon={<div className="font-semibold text-xl text-center" style={{ lineHeight: '40px' }}>2021</div>}
            />
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#fff', color: '#1D1D1D', boxShadow: 'none' }}
              iconStyle={{
                background: '#4499E8', color: '#fff', width: '14px', height: '14px', boxShadow: 'none',
              }}
              position="right"
            >
              <div className="text-sm lg:text-lg font-semibold" style={{ color: '#4499E8' }}>FEBRUARY</div>
              <div className={`text-lg font-semibold mt-4 lg:mt-3.5 ${styles.verticalFontSize}`}>Oddup becomes a unicorn</div>
              <p className="text-lg font-normal">
                Oddup raises $100M at a $1B valuation to launch its crypto
                data and custody platform
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#fff', color: '#1D1D1D', boxShadow: 'none' }}
              iconStyle={{
                background: '#4499E8', color: '#fff', width: '14px', height: '14px', boxShadow: 'none',
              }}
              position="left"
            >
              <div className="text-sm lg:text-lg font-semibold" style={{ color: '#4499E8' }}>FEBRUARY</div>
              <div className={`text-lg font-semibold mt-4 lg:mt-3.5 ${styles.verticalFontSize}`}>Launches Oddup Crypto</div>
              <p className="text-lg font-normal">
                Oddup launches Oddup crypto to allow investors to buy, sell
                and safely store cryptocurrencies
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#fff', color: '#1D1D1D', boxShadow: 'none' }}
              iconStyle={{
                background: '#4499E8', color: '#fff', width: '14px', height: '14px', boxShadow: 'none',
              }}
              position="right"
            >
              <div className="text-sm lg:text-lg font-semibold" style={{ color: '#4499E8' }}>FEBRUARY</div>
              <div className={`text-lg font-semibold mt-4 lg:mt-3.5 ${styles.verticalFontSize}`}>Launches COVID-19 scores</div>
              <p className="text-lg font-normal">
                Oddup launches Covid-19 ratings, which track the best
                performing countries managing the pandemic
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#fff', color: '#1D1D1D', boxShadow: 'none' }}
              iconStyle={{
                background: '#4499E8', color: '#fff', width: '14px', height: '14px', boxShadow: 'none',
              }}
              position="left"
            >
              <div className="text-sm lg:text-lg font-semibold" style={{ color: '#4499E8' }}>FEBRUARY</div>
              <div className={`text-lg font-semibold mt-4 lg:mt-3.5 ${styles.verticalFontSize}`}>Partners with Dow Jones Factiva</div>
              <p className="text-lg font-normal">
                Oddup becomes a trusted source of startup data and ratings
                on Dow Jones Factiva platform
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#fff', color: '#1D1D1D', boxShadow: 'none' }}
              iconStyle={{
                background: '#4499E8', color: '#fff', width: '14px', height: '14px', boxShadow: 'none',
              }}
              position="right"
            >
              <div className="text-sm lg:text-lg font-semibold" style={{ color: '#4499E8' }}>FEBRUARY</div>
              <div className={`text-lg font-semibold mt-4 lg:mt-3.5 ${styles.verticalFontSize}`}>Oddup raises $10M Series B</div>
              <p className="text-lg font-normal">
                Oddup raises $10M at a $100M valuation, to launch its
                business further around the globe
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#fff', color: '#1D1D1D', boxShadow: 'none' }}
              iconStyle={{
                background: '#4499E8', color: '#fff', width: '14px', height: '14px', boxShadow: 'none',
              }}
              position="left"
            >
              <div className="text-sm lg:text-lg font-semibold" style={{ color: '#4499E8' }}>FEBRUARY</div>
              <div className={`text-lg font-semibold mt-4 lg:mt-3.5 ${styles.verticalFontSize}`}>Partners with Bloomberg</div>
              <p className="text-lg font-normal">
                Oddup becomes a trusted source of startup data and ratings
                on Bloomberg Terminal
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </section>

      <div className={`w-full ${styles.bubblesImageContainerBg} relative`}>
        <section className={`${styles.generalContainer} mr-0 xl:w-10/12 lg:w-full`}>
          <div className={`flex justify-between items-center px-6 ${styles.bubblesImageContainer}`}>
            <div className="flex flex-col z-10">
              <span
                className="text-xl lg:text-lg font-semibold mb-9"
                style={{
                  color: '#FFFFFF',
                }}
              >
                INTERESTED WITH ODDUP?
              </span>
              <span
                className={`${styles.featureTitle} hidden lg:block`}
                style={{
                  color: '#FFFFFF',
                }}
              >
                Sed vel velit felis nunc
                <br />
                aliquam nulla felis.
              </span>
              <span
                className={`${styles.featureDesc} hidden lg:block`}
                style={{
                  color: '#FFFFFF',
                }}
              >
                Quisque fringilla lacus lectus, vitae viverra mi suscipit commodo. Sed vel velit felis. Nunc aliquam
                <br />
                nulla felis, ac consequat urna placerat sed. Etiam pulvinar neque massa, sed sagittis enim lobortis
                <br />
                eu. Quisque faucibus nunc sit amet metus luctus dictum. Nunc laoreet mi et vehicula dignissim.
              </span>
              <span
                className={`${styles.featureDesc} block lg:hidden`}
                style={{
                  color: '#FFFFFF',
                }}
              >
                Oddup Crypto is designed as a walled garden where new infrastructure is used to mitigate risk from malicious actors. In addition, Oddup crypto investors are insured under a Full Crypto Crime Insurance Policy.
              </span>
              <br />
              <div className="flex">
                <input type="text" placeholder="Email address" className="hidden lg:block mr-2.5 pl-4 py-2.5 rounded pr-64" />
                <div
                  style={{
                    background: '#F9A61A',
                  }}
                  className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button} ${styles.reqDemoBtn}`}
                  {...buttonize(typeformOpen)}
                >
                  <div className="text-white">
                    REQUEST DEMO
                  </div>
                </div>
              </div>
            </div>
            <img src={`${assetPrefix}/images/bubbles.svg`} className={`${styles.bubblesImage} hidden lg:block`} alt="Early Stage Growth" />
          </div>
        </section>
      </div>

      {/* <LowerComponent /> */}
      <div className={styles.footerParent}>
        <section className={styles.generalContainer1}>
          <div className="md:flex items-center w-11/12 border-b-2 py-16">
            <div className="md:text-left text-center mb-4 md:mb-0 md:w-3/6 w-full">
              <img className={`md:mr-auto ${styles.imageStyle}`} src={`${assetPrefix}/logo/oddupWhiteHeading.svg`} alt="bubbles" />
            </div>
            <div className="md:w-3/6 w-full flex items-center md:justify-end justify-center">
              <div style={{ color: '#FFFFFF' }} className="mr-3 md:inline-flex hidden">FOLLOW US</div>
              <div className="flex items-center">
                <a
                  className={`flex items-center justify-center mr-3 ${styles.iconStyle}`}
                  href="https://www.facebook.com/oddupratings/"
                  target="_blank"
                >
                  <img src={`${assetPrefix}/images/facebook.svg`} className={styles.fbLogo} alt="Facebook" />
                </a>
                <a
                  className={`flex items-center justify-center mr-3 ${styles.iconStyle}`}
                  href="https://twitter.com/oddupratings"
                  target="_blank"
                >
                  <img src={`${assetPrefix}/images/twitter.svg`} alt="twitter" className={styles.twLogo} />
                </a>
                <a
                  className={`flex items-center justify-center ${styles.iconStyle}`}
                  href="https://www.linkedin.com/company/oddup"
                  target="_blank"
                >
                  <img src={`${assetPrefix}/images/linkedin.svg`} alt="linkedin" className={styles.twLogo} />
                </a>
              </div>
            </div>
          </div>
          <div className={`font-normal text-center py-10 ${styles.copyright}`}>
            © 2021 Oddup.com. All rights reserved.
            {' '}
            <a
              href="https://www.notion.so/oddup/Copyright-Policy-fc1e1aa5db60497e886ddb247d3948c2"
              target="_blank"
            >
              Copyright
            </a>
            {' '}
            |
            {' '}
            <a
              href="https://www.notion.so/oddup/Oddup-Terms-Conditions-39b0a029ba05440e85f8e4bee1e7350c"
              target="_blank"
            >
              Terms
            </a>
            {' '}
            |
            {' '}
            <a
              href="https://www.notion.so/oddup/Oddup-Privacy-Policy-5b46ce2440934925b43005b5ebce0c7d"
              target="_blank"
            >
              Privacy
            </a>
            {' '}
            |
            {' '}
            <a
              href="mailto:hello@oddup.com"
            >
              Help
            </a>
          </div>
        </section>
      </div>
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        url="https://form.typeform.com/to/MXo4VteX"
        hideHeaders
        hideFooter
        buttonText="Go!"
        style={{ height: 0 }}
        ref={typeform}
      />
    </div>
  );
};

export default AboutPage;
