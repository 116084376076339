/* eslint-disable react/jsx-no-target-blank,no-unreachable,max-len */
/**
 * React
 * Author: Patanjali kumar (patanjali@oddup.com)
 * Copyright © 2020-present Oddup. All rights reserved.
 *
 * LANDING PAGE VERSION 1
 */

import React, {
  lazy, useEffect, useRef, useState, Suspense,
} from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import Carousel from '../../components/CarouselOwn';
// import Carousel from 'react-material-ui-carousel';
import styles from './LandingPage.module.css';
import buttonize, { assetPrefix } from '../../utilities/general';
import { selectLoggedInStatus } from '../../redux/selectors';
import FirstImage from '../../components/AnimationSections/FirstImage';
import WorldGrowth from '../../components/AnimationSections/WorldGrowth';
import HealthBarometer from '../../components/AnimationSections/HealthBarometer';
import MarketStartups from '../../components/AnimationSections/MarketStartups';
import ComprehensiveInsights from '../../components/AnimationSections/ComprehensiveInsights';
// import GlobalCoverage from '../../components/AnimationSections/GlobalCoverage';
import DigitalAssets from '../../components/AnimationSections/DigitalAssets';
import AnimationLeftRightFloater from '../../components/AnimationLeftRightFloater/AnimationLeftRightFloater';
import stylesAnimationInitial, { devices, getDevice } from '../../utilities/animations';
import LazyLoading from '../../components/LazyLoading/LazyLoading';

const GlobalCoverage = lazy(() => import('../../components/AnimationSections/GlobalCoverage'));
// import { animate, init } from '../../components/AnimatedGlobe/AnimatedGlobe';

const LandingPage = () => {
  const typeform = useRef(null);
  const cryptoDiv = useRef(null);
  const researchDiv = useRef(null);
  const toCheckScroll = useRef(null);
  const featuredClients = useRef(null);
  const [activeNav, setActiveNav] = useState(null);
  const history = useHistory();
  const [showHeaderBg, setShowHeaderBg] = useState(false);
  // useEffect(() => {
  //   init();
  //   animate();
  // }, []);
  const scrollListener = () => {
    if (window.scrollY > 60 && !showHeaderBg) {
      setShowHeaderBg(true);
    }
    if (window.scrollY < 60 && showHeaderBg) {
      setShowHeaderBg(false);
    }
    // const cryptoTarget = toCheckScroll.current;
    // const researchTarget = featuredClients.current;
    // const passedResearchTarget = researchTarget !== null && window.scrollY > (
    //   researchTarget.offsetTop + researchTarget.offsetHeight
    // );
    // const passedCryptoTarget = cryptoTarget !== null && window.scrollY > (
    //   cryptoTarget.offsetTop + cryptoTarget.offsetHeight
    // );
    // if (passedCryptoTarget && passedResearchTarget) {
    //   setActiveNav('crypto');
    //   return;
    // }
    // if (passedResearchTarget) {
    //   setActiveNav('research');
    // }
    //
    // if (!passedResearchTarget && !passedCryptoTarget) {
    //   setActiveNav(null);
    // }
    // if (scrolled && !passed) {
    //   setScrolled(false);
    // }
    // if (!scrolled && passed) {
    //   setScrolled(true);
    // }
  };
  const carouselItems = [
    'Shielding against Theft',
    'Secure exchange address',
    'Immunity from cyber crimes',
    'Protection against transfer fraud',
    'Direct access of funds to exchanges',
    'Address whitelisting required for withdrawals',
    'Transaction level verification and Offline Signing',
    'Comprehensive reporting on Oddup Crypto platform',
  ];

  useEffect(() => {
    if (toCheckScroll && toCheckScroll.current && featuredClients && featuredClients.current) {
      window.removeEventListener('scroll', scrollListener);
      window.addEventListener('scroll', scrollListener);
    }
  }, [activeNav]);
  const goToResearchDiv = () => {
    if (researchDiv && researchDiv.current) {
      const ele = researchDiv.current;
      const y = ele.getBoundingClientRect().top + 20 + window.scrollY;
      window.scroll({
        top: y,
        behavior: 'smooth',
      });
    }
  };
  const goToCryptoDiv = () => {
    if (cryptoDiv && cryptoDiv.current) {
      const ele = cryptoDiv.current;
      const y = ele.getBoundingClientRect().top - 220 + window.scrollY;
      window.scroll({
        top: y,
        behavior: 'smooth',
      });
    }
  };
  const typeformOpen = () => {
    if (window.gtag) {
      // window.dataLayer.push({ event: 'LandingPageFirstSectionCTA' });
      // window.ga('send', 'event', 'Click', 'CTAClick', 'LandingPageFirstSectionCTA', 'Clicked', {});
      window.gtag('event', 'Click', {
        // 'event_category': <category>,
        event_label: 'LandingPageFirstSectionCTA',
      });
    }
    if (typeform) {
      typeform.current.typeform.open();
    }
  };
  const loggedInStatus = useSelector(selectLoggedInStatus);
  if (loggedInStatus.loggedInStatus) {
    return <Redirect to="/companies" />;
  }
  // return <Redirect to="/login" />;
  return (
    <div className="overflow-x-hidden">
      <img
        src={`${assetPrefix}/images/landingPage/landing-page-TL.png`}
        alt="Oddup"
        className={`hidden lg:block ${styles.topLeftImage}`}
      />
      <img
        src={`${assetPrefix}/images/landingPage/mobile-top-right.png`}
        alt="Oddup"
        className={`block lg:hidden ${styles.topLeftImage}`}
      />
      <img
        src={`${assetPrefix}/images/landingPage/rectangle-left.png`}
        className={styles.middleLeftImage}
        alt="Landing Page"
      />
      { /* Header -- start */}
      <div className={`${styles.headerParentContainer} ${showHeaderBg ? styles.headerBg : ''}`}>
        <div className={`${styles.generalContainer} ${styles.headerContainer}`}>
          <div className="flex justify-between items-center">
            <div className="flex">
              <img
                src={`${assetPrefix}/logo/oddup.svg`}
                className={styles.logo}
                alt="Oddup"
                {...buttonize(() => {
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                })}
              />
              <span
                className={`${styles.headerItem} ${styles.hideOnMobile} ${activeNav === 'research' ? styles.headerItemActive : ''} mr-10`}
                {...buttonize(goToResearchDiv)}
              >
                ODDUP RESEARCH
              </span>
              <span
                className={`${styles.headerItem} ${styles.hideOnMobile} ${activeNav === 'crypto' ? styles.headerItemActive : ''} mr-10`}
                {...buttonize(goToCryptoDiv)}
              >
                ODDUP CRYPTO
              </span>
              <a href="https://blog.oddup.com">
                <span className={`${styles.headerItem} ${styles.hideOnMobile}`}>BLOG</span>
              </a>
              <a href="/pricing-details" className="ml-10 2xl:ml-12">
                <span className={`${styles.headerItem} ${styles.hideOnMobile}`}>PRICING</span>
              </a>
            </div>
            <div
              className={`${styles.signUpLoginContainer} flex`}
            >
              <span className={styles.loginBtn} onClick={() => history.push('/login')}>
                LOGIN
              </span>
              <span className={styles.signUpBtn} onClick={() => history.push('/signup')}>
                SIGNUP
              </span>
            </div>
          </div>
        </div>
      </div>
      { /* Header -- end */}
      {/* <AnimationLeftRightFloater ltrNodeId="firstSectionLeft" rtlNodeId="firstSectionRight"> */}
      <section
        className={`flex justify-between items-center ${styles.sectionContainer}`}
        style={{ marginTop: '8rem' }}
      >
        <div
          className={styles.topContent}
          id="firstSectionLeft"
          style={{
            // ...stylesAnimationInitial('left-right'),
          }}
        >
          <div className={styles.gradientText}>
            Intelligent insights, optimised
          </div>
          <div className={styles.subtitle}>
            Data driven insights for helping you understand the
            <br />
            new world economy.
          </div>
          <div className="flex justify-center lg:justify-start">
            <div className={styles.exploreBtn}>
              <a href="https://app.oddup.com/signup">Explore Oddup</a>
            </div>
          </div>
        </div>
        <div
          style={{
            zIndex: 101,
            // ...stylesAnimationInitial('right-left'),
          }}
          id="firstSectionRight"
          className={styles.firstImageStyle}
        >
          {getDevice() === devices.DESKTOP
            ? <FirstImage />
            : (
              <img
                src={`${assetPrefix}/images/landingPage/top-section-right.png`}
                className={styles.rightImage}
                alt="Oddup Companies"
              />
            )}
        </div>
      </section>
      {/* </AnimationLeftRightFloater> */}

      <section className={`${styles.generalContainer}  mt-24`} ref={featuredClients}>
        <div className={`${styles.featuredClients} hidden lg:flex justify-between items-center flex-wrap`}>
          <img src={`${assetPrefix}/images/landingPage/client-icons/companies1.png`} alt="Client" />
          <img src={`${assetPrefix}/images/landingPage/client-icons/companies2.png`} alt="Client" />
          <img src={`${assetPrefix}/images/landingPage/client-icons/companies3.png`} alt="Client" />
          <img src={`${assetPrefix}/images/landingPage/client-icons/companies4.png`} alt="Client" />
          <img src={`${assetPrefix}/images/landingPage/client-icons/companies5.png`} alt="Client" />
          <img src={`${assetPrefix}/images/landingPage/client-icons/companies6.png`} alt="Client" />
          <img src={`${assetPrefix}/images/landingPage/client-icons/companies7.png`} alt="Client" />
        </div>
        <div className={`${styles.featuredClients} flex lg:hidden flex-col flex-wrap`}>
          <div className="flex justify-evenly w-full items-center">
            <img src={`${assetPrefix}/images/landingPage/client-icons/companies1.png`} alt="Client" />
            <img
              src={`${assetPrefix}/images/landingPage/client-icons/companies2.png`}
              className={styles.bloombergLogo}
              alt="Client"
            />
            <img src={`${assetPrefix}/images/landingPage/client-icons/companies3.png`} alt="Client" />
          </div>
          <div className="flex justify-evenly w-full items-center">
            <img src={`${assetPrefix}/images/landingPage/client-icons/companies4.png`} alt="Client" />
            <img src={`${assetPrefix}/images/landingPage/client-icons/companies5.png`} alt="Client" />
            <img src={`${assetPrefix}/images/landingPage/client-icons/companies6.png`} alt="Client" />
            <img src={`${assetPrefix}/images/landingPage/client-icons/companies7.png`} alt="Client" />
          </div>
        </div>
      </section>

      <section
        className={`${styles.generalContainer} pt-28 pb-28 lg:pt-56 lg:pb-56 px-auto relative`}
        ref={researchDiv}
      >
        <AnimationLeftRightFloater
          ltrNodeId="secondSectionLeft"
          rtlNodeId="secondSectionRight"
          animationAfterEvent="section-2"
          inViewListener={() => {
            setActiveNav('research');
          }}
        >
          <div className="flex justify-between flex-col lg:flex-row items-center">
            {/* <img src={`${assetPrefix}/images/landingPage/feature-1.png`} className={`${styles.feature1} mx-auto`} alt="Early Stage Growth" /> */}
            <div
              id="secondSectionLeft"
              style={{
                ...stylesAnimationInitial('left-right'),

              }}
            >
              <WorldGrowth />
            </div>
            <div
              className="flex flex-col mt-20 lg:mt-0"
              id="secondSectionRight"
              style={{
                ...stylesAnimationInitial('right-left'),

              }}
            >
              <span className={`${styles.featureTitle} lg:block hidden`}>
                Understanding the World of
                <br />
                Early Stage Growth
              </span>
              <span className={`${styles.featureTitle} lg:hidden block`}>
                Understanding the World of Early Stage Growth
              </span>
              <span className={`${styles.featureDesc} hidden lg:block`}>
                Oddup monitors real-time entrepreneurial activity across prominent startup hubs,
                <br />
                regions, and industries and empowers investors to stay updated with all the
                <br />
                critical information to capitalize on new technologies and industry disruptions.
              </span>
              <span className={`${styles.featureDesc} block lg:hidden`}>
                Oddup monitors real-time entrepreneurial activity across prominent startup hubs, regions, and industries and empowers investors to stay updated with all the critical information to capitalize on new technologies and industry disruptions.
              </span>
              <br />
              <div
                style={{
                  background: '#4AA8FF',
                }}
                className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
              >
                <a href="https://app.oddup.com/signup">
                  <div className="text-white">
                    Explore Early Stage Startups
                    <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </AnimationLeftRightFloater>
      </section>

      <section className={`${styles.generalContainer}`}>
        <AnimationLeftRightFloater
          ltrNodeId="thirdSectionLeft"
          rtlNodeId="thirdSectionRight"
          animationAfterEvent="section-3"
        >
          <div className="flex justify-between lg:flex-row flex-col-reverse lg:flex-row items-center">
            <div
              className="flex flex-col mt-40 lg:mt-0 md:mt-52"
              id="thirdSectionLeft"
              style={{
                ...stylesAnimationInitial('left-right'),
              }}
            >
              {/* <div className="block lg:hidden">
              <ComprehensiveInsights />
            </div> */}
              {/* <img src={`${assetPrefix}/images/landingPage/feature-2.png`} className={`${styles.feature2} block lg:hidden mx-auto mb-10`} alt="Early Stage Growth" /> */}
              <span className={`${styles.featureTitle} lg:block hidden`}>
                Comprehensive Insights for
                <br />
                Every Investor
              </span>
              <span className={`${styles.featureTitle} lg:hidden block`}>
                Comprehensive Insights for Every Investor
              </span>
              <span className={`${styles.featureDesc} hidden lg:block`}>
                From identifying the next corporate innovation to fund, accelerate, or acquire, to
                <br />
                complementing due diligence, and from screening inbound startup leads to
                <br />
                identifying potential startup partners.
                <br />
                <br />
                Get cryptocurrency price targets for six and twelve month periods, and insights on
                <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                whether to Buy, Hold, or Sell leading cryptocurrencies with Oddup's proprietary
                <br />
                Cryptocurrency Ratings. Oddup has solutions for the needs of every investor.
              </span>
              <span className={`${styles.featureDesc} block lg:hidden`}>
                From identifying the next corporate innovation to fund, accelerate, or acquire, to complementing due diligence, and from screening inbound startup leads to identifying potential startup partners.
                <br />
                <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Get cryptocurrency price targets for six and twelve month periods, and insights on whether to Buy, Hold, or Sell leading cryptocurrencies with Oddup's proprietary Cryptocurrency Ratings. Oddup has solutions for the needs of every investor.
              </span>
              <br />
              <div
                style={{
                  background: '#4AA8FF',
                }}
                className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
              >
                <a href="https://app.oddup.com/signup">
                  <div className="text-white">
                    Signup Now!
                    <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
                  </div>
                </a>
              </div>
            </div>
            {/* <img src={`${assetPrefix}/images/landingPage/feature-2.png`} className={`${styles.feature2} hidden lg:block`} alt="Early Stage Growth" /> */}
            <div
              id="thirdSectionRight"
              style={{
                ...stylesAnimationInitial('left-right'),
              }}
            >
              <ComprehensiveInsights />
            </div>
          </div>
        </AnimationLeftRightFloater>
      </section>

      <div className={`w-full ${styles.feature3Container} relative`}>
        <img
          src={`${assetPrefix}/images/landingPage/rectangle-right.png`}
          className={styles.middleRightImage}
          alt="Oddup Page"
        />
        <section
          className={`${styles.generalContainer} ${styles.globalCoverage} mt-20 lg:mt-56 mr-0`}
          id="globalCoverageSection"
        >
          <AnimationLeftRightFloater ltrNodeId="fourthSectionLeft" rtlNodeId="fourthSectionRight">
            <div className={`flex justify-between items-center ${styles.mapCitiesContainer}`}>
              <div
                className="flex flex-col z-10"
                id="fourthSectionLeft"
                style={{
                  ...stylesAnimationInitial('left-right'),
                }}
              >
                <img
                  src={`${assetPrefix}/images/landingPage/map-cities-mobile.png`}
                  className={`${styles.mapCities} block lg:hidden`}
                  alt="Early Stage Growth"
                />
                <span className={`${styles.featureTitle} lg:block hidden`}>
                  Comprehensive Global
                  <br />
                  Coverage
                </span>
                <span className={`${styles.featureTitle} lg:hidden block`}>
                  Comprehensive Global Coverage
                </span>
                <span className={`${styles.featureDesc} hidden lg:block`}>
                  Keep efficient tabs on the real-time startups, city, region, sector and
                  <br />
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  cryptocurrency movements and Investment activity with Oddup's un-paralleled
                  <br />
                  global coverage, and expertise across locations, markets, and industries. Oddup
                  <br />
                  provides entrepreneurial, investor, industry, and ecosystem data, insights, and
                  <br />
                  analyses across all the major startup locations across the globe.
                </span>
                <span className={`${styles.featureDesc} block lg:hidden`}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Keep efficient tabs on the real-time startups, city, region, sector and cryptocurrency movements and Investment activity with Oddup's un-paralleled global coverage, and expertise across locations, markets, and industries. Oddup provides entrepreneurial, investor, industry, and ecosystem data, insights, and analyses across all the major startup locations across the globe.
                </span>
                <br />
                <div
                  style={{
                    background: '#4AA8FF',
                  }}
                  className={`mb-24 rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
                >
                  <a href="https://app.oddup.com/signup">
                    <div className="text-white">
                      Signup for Oddup
                      <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
                    </div>
                  </a>
                </div>
              </div>
              {/* <div>
                <img src={`${assetPrefix}/images/landingPage/map1024.svg`} className={`${styles.mapCities1024} hidden lg:block xl:hidden`} alt="Global Coverage" />
              </div> */}
              <div
                id="fourthSectionRight"
                style={{
                  ...stylesAnimationInitial('right-left'),
                }}
              >
                {getDevice() === devices.DESKTOP ? (
                  <Suspense fallback={<LazyLoading />}>
                    <GlobalCoverage />
                  </Suspense>
                ) : (
                  <img
                    src={`${assetPrefix}/images/landingPage/map1024.svg`}
                    className={`${styles.mapCities1024} hidden lg:block xl:hidden`}
                    alt="Global Coverage"
                  />
                )}
              </div>
            </div>
          </AnimationLeftRightFloater>
        </section>
      </div>

      <section className={`${styles.generalContainer} mt-14 lg:mt-56`}>
        <AnimationLeftRightFloater
          ltrNodeId="fifthSectionleft"
          rtlNodeId="fifthSectionRight"
          animationAfterEvent="section-5"
        >
          <div className="flex justify-between flex-col lg:flex-row items-center items-center">
            {/* <img src={`${assetPrefix}/images/landingPage/feature-4.png`} className={`${styles.feature4} mb-20 lg:mb-0`} alt="Early Stage Growth" /> */}
            <div
              id="fifthSectionleft"
              style={{
                ...stylesAnimationInitial('left-right'),
              }}
            >
              <HealthBarometer />
            </div>
            <div
              className="flex flex-col mt-20 lg:mt-0"
              id="fifthSectionRight"
              style={{
                ...stylesAnimationInitial('right-left'),
              }}
            >
              <span className={`${styles.featureTitle} lg:block hidden`}>
                Health Barometer in
                <br />
                Simple Scores
              </span>
              <span className={`${styles.featureTitle} lg:hidden block`}>
                Health Barometer in Simple Scores
              </span>
              <span className={`${styles.featureDesc} hidden lg:block`}>
                Oddup tracks and exhaustively analyses the profiles of thousands of startups to
                <br />
                provide the potential investors with a curated view of their health and potential
                <br />
                success in a single quantifiable metric. Oddup’s most prominent and effective
                <br />
                offering is the Benchmark Valuation. It makes investment planning easier by
                <br />
                giving investors an insight into the valuation of the startup as well as its valuation
                <br />
                trend, enabling realistic expectations and planning.
              </span>
              <span className={`${styles.featureDesc} block lg:hidden`}>
                Oddup tracks and exhaustively analyses the profiles of thousands of startups to provide the potential investors with a curated view of their health and potential success in a single quantifiable metric. Oddup’s most prominent and effective offering is the Benchmark Valuation. It makes investment planning easier by giving investors an insight into the valuation of the startup as well as its valuation trend, enabling realistic expectations and planning.
              </span>
              <br />
              <div
                style={{
                  background: '#4AA8FF',
                }}
                className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
              >
                <a href="https://app.oddup.com/signup">
                  <div className="text-white">
                    Signup for Oddup
                    <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </AnimationLeftRightFloater>
      </section>

      <section className={`${styles.generalContainer} mt-12 lg:mt-56`} ref={toCheckScroll}>
        <AnimationLeftRightFloater
          ltrNodeId="sixthSectionLeft"
          rtlNodeId="sixthSectionRight"
          animationAfterEvent="section-6"
        >
          <div
            className="flex justify-between lg:flex-row flex-col-reverse lg:flex-row items-center items-center"
          >
            {/* <img src={`${assetPrefix}/images/landingPage/feature-5.png`} className={`${styles.feature5} block lg:hidden`} alt="Early Stage Growth" /> */}
            <div
              className="flex flex-col mt-12 md:mt-20 lg:mt-0"
              id="sixthSectionLeft"
              style={{
                ...stylesAnimationInitial('left-right'),
              }}
            >
              <span className={`${styles.featureTitle} lg:block hidden`}>
                Keep Tabs on the Market,
                <br />
                in Real Time
              </span>
              <span className={`${styles.featureTitle} lg:hidden block`}>
                Keep Tabs on the Market, in Real Time
              </span>
              <span className={`${styles.featureDesc} hidden lg:block`}>
                Keep efficient tabs on the real-time startups, city, region, sector and
                <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                cryptocurrency movements and Investment activity with Oddup's un-paralleled
                <br />
                global coverage, and expertise across locations, markets, and industries. Oddup
                <br />
                provides entrepreneurial, investor, industry, and ecosystem data, insights, and
                <br />
                {/* eslint-disable-next-line max-len */}
                analyses across all the major startup locations across the globe.
              </span>
              <span className={`${styles.featureDesc} block lg:hidden`}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Keep efficient tabs on the real-time startups, city, region, sector and cryptocurrency movements and Investment activity with Oddup's un-paralleled global coverage, and expertise across locations, markets, and industries. Oddup provides entrepreneurial, investor, industry, and ecosystem data, insights, and analyses across all the major startup locations across the globe.
              </span>
              <br />
              <div
                style={{
                  background: '#4AA8FF',
                }}
                className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
              >
                <a href="https://app.oddup.com/signup">
                  <div className="text-white">
                    Check out Oddup Indexes
                    <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
                  </div>
                </a>
              </div>
            </div>
            {/* <img src={`${assetPrefix}/images/landingPage/feature-5.png`} className={`${styles.feature5} hidden lg:block`} alt="Early Stage Growth" /> */}
            <div
              id="sixthSectionRight"
              style={{
                ...stylesAnimationInitial('left-right'),
              }}
            >
              <MarketStartups />
            </div>
          </div>
        </AnimationLeftRightFloater>
      </section>

      <section className={`${styles.generalContainer} mt-28 lg:mt-56 mb-28 lg:mb-56`} ref={cryptoDiv}>
        <AnimationLeftRightFloater
          inViewListener={() => {
            setActiveNav('crypto');
          }}
          ltrNodeId="seventhSectionLeft"
          rtlNodeId="seventhSectionRight"
          animationAfterEvent="section-7"
        >
          <div className="flex justify-between flex-col lg:flex-row items-center items-center">
            {/* <img src={`${assetPrefix}/images/landingPage/feature-6.png`} className={styles.feature6} alt="Early Stage Growth" /> */}
            <div
              id="seventhSectionLeft"
              style={{
                ...stylesAnimationInitial('left-right'),
              }}
            >
              <DigitalAssets />
            </div>
            <div
              className="flex flex-col mr-20 mt-28 lg:mt-0"
              id="seventhSectionRight"
              style={{
                ...stylesAnimationInitial('right-left'),
              }}
            >
              <span className={styles.featureTitle}>
                Fort Knox of Digital Assets
              </span>
              <span className={`${styles.featureDesc} hidden lg:block`}>
                The Oddup Crypto platform offers institutional-grade custody and trading
                <br />
                services for investors to further simplify the crypto space
              </span>
              <span className={`${styles.featureDesc} block lg:hidden`}>
                The Oddup Crypto platform offers institutional-grade custody and trading services for investors to further simplify the crypto space
              </span>
              <br />
              <div
                style={{
                  background: '#4AA8FF',
                }}
                className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
                {...buttonize(typeformOpen)}
              >
                <div className="text-white">
                  Secure Your Assets Now!
                  <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
                </div>
              </div>
            </div>
          </div>
        </AnimationLeftRightFloater>
      </section>

      <div className={`${styles.insurance} mt-28 lg:mt-56`}>
        <section
          className={`${styles.generalContainer}`}
        >
          <div className="flex flex-col items-center">
            <span className={`${styles.featureTitle} mb-12`}>
              Up to $10 Million in Claimable Insurance
            </span>
            <span className={`${styles.featureDesc} ${styles.fontStyle}`}>
              Oddup Crypto is designed as a walled garden where new infrastructure is used to mitigate risk from malicious
              <br />
              actors. In addition, Oddup crypto investors are insured under a Full Crypto Crime Insurance Policy.
            </span>
            <div
              className={`text-center item-center font-medium text-xl justify-center flex flex-col mx-auto ${styles.carousel}`}
            >
              <Carousel navButtonsAlwaysInvisible indicators={false} animation="slide">
                {carouselItems.map((ci) => (
                  <div className="font-medium text-sm lg:text-xl  items-center">
                    {ci}
                  </div>
                ))}
              </Carousel>
            </div>
            <div
              style={{
                background: '#FFA82D',
              }}
              className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button}`}
              {...buttonize(typeformOpen)}
            >
              <div className="text-white">
                Request demo and join Oddup Crypto
                <ArrowForward className="ml-2" style={{ color: '#FFFFF' }} />
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={`w-full ${styles.bubblesImageContainerBg} relative`}>
        <section className={`${styles.generalContainer} mr-0`}>
          <div className={`flex justify-between items-center ${styles.bubblesImageContainer}`}>
            <div className="flex flex-col z-10">
              <span
                className={styles.featureTitle}
                style={{
                  color: '#FFFFFF',
                }}
              >
                Interested In Oddup?
              </span>
              <span
                className={`${styles.featureDesc} hidden lg:block`}
                style={{
                  color: '#FFFFFF',
                }}
              >
                Sign up with Oddup to get a comprehensive pulse of the startup and the cryptocurrency
                <br />
                ecosystems by leveraging Oddup’s proprietary monitoring platform and actionable insights
                <br />
                <br />
                You can contact us directly at hello@oddup.com, or request a demo below to discover unique
                <br />
                insights from the startup and cryptocurrency ecosystems. If you are a startup that wants to gain
                <br />
                wide exposure to a large cohort of startup investors and corporates looking for their next
                <br />
                acquisition, you can get featured on Oddup by registering your startup. Join Oddup Now!
              </span>
              <span
                className={`${styles.featureDesc} block lg:hidden`}
                style={{
                  color: '#FFFFFF',
                }}
              >
                Oddup Crypto is designed as a walled garden where new infrastructure is used to mitigate risk from malicious actors. In addition, Oddup crypto investors are insured under a Full Crypto Crime Insurance Policy.
              </span>
              <br />
              <div
                style={{
                  background: '#F9A61A',
                }}
                className={`rounded-sm flex font-semibold text-base justify-center flex flex-col ${styles.button} ${styles.reqDemoBtn}`}
                {...buttonize(typeformOpen)}
              >
                <div className="text-white">
                  REQUEST DEMO
                </div>
              </div>
            </div>
            <img
              src={`${assetPrefix}/images/bubbles.svg`}
              className={`${styles.bubblesImage} hidden lg:block`}
              alt="Early Stage Growth"
            />
          </div>
        </section>
      </div>

      {/* <LowerComponent /> */}
      <div className={styles.footerParent}>
        <section className={styles.generalContainer}>
          <div className="md:flex items-center w-11/12 border-b-2 py-16">
            <div className="md:text-left text-center mb-4 md:mb-0 md:w-3/6 w-full">
              <img
                className={`md:mr-auto ${styles.imageStyle}`}
                src={`${assetPrefix}/logo/oddupWhiteHeading.svg`}
                alt="bubbles"
              />
            </div>
            <div className="md:w-3/6 w-full flex items-center md:justify-end justify-center">
              <div style={{ color: '#FFFFFF' }} className="mr-3 md:inline-flex hidden">FOLLOW US</div>
              <div className="flex items-center">
                <a
                  className={`flex items-center justify-center mr-3 ${styles.iconStyle}`}
                  href="https://www.facebook.com/oddupratings/"
                  target="_blank"
                >
                  <img
                    src={`${assetPrefix}/images/facebook.svg`}
                    className={styles.fbLogo}
                    alt="Facebook"
                  />
                </a>
                <a
                  className={`flex items-center justify-center mr-3 ${styles.iconStyle}`}
                  href="https://twitter.com/oddupratings"
                  target="_blank"
                >
                  <img
                    src={`${assetPrefix}/images/twitter.svg`}
                    alt="twitter"
                    className={styles.twLogo}
                  />
                </a>
                <a
                  className={`flex items-center justify-center ${styles.iconStyle}`}
                  href="https://www.linkedin.com/company/oddup"
                  target="_blank"
                >
                  <img
                    src={`${assetPrefix}/images/linkedin.svg`}
                    alt="linkedin"
                    className={styles.twLogo}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className={`font-normal text-center py-10 ${styles.copyright}`}>
            © 2021 Oddup.com. All rights reserved.
            {' '}
            <a
              href="https://www.notion.so/oddup/Copyright-Policy-fc1e1aa5db60497e886ddb247d3948c2"
              target="_blank"
            >
              Copyright
            </a>
            {' '}
            |
            {' '}
            <a
              href="https://www.notion.so/oddup/Oddup-Terms-Conditions-39b0a029ba05440e85f8e4bee1e7350c"
              target="_blank"
            >
              Terms
            </a>
            {' '}
            |
            {' '}
            <a
              href="https://www.notion.so/oddup/Oddup-Privacy-Policy-5b46ce2440934925b43005b5ebce0c7d"
              target="_blank"
            >
              Privacy
            </a>
            {' '}
            |
            {' '}
            <a
              href="mailto:hello@oddup.com"
            >
              Help
            </a>
          </div>
        </section>
      </div>
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        url="https://form.typeform.com/to/MXo4VteX"
        hideHeaders
        hideFooter
        buttonText="Go!"
        style={{ height: 0 }}
        ref={typeform}
      />
    </div>
  );
};

export default LandingPage;
