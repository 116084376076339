import React from 'react';
import anime from 'animejs/lib/anime';
import { InView } from 'react-intersection-observer';
import {
  arrayOf, func, node, string,
} from 'prop-types';
import { devices, getDevice } from '../../utilities/animations';

const AnimationLeftRightFloater = (props) => {
  const {
    children, ltrNodeId, rtlNodeId, animationAfterEvent, inViewListener,
  } = props;
  const handleIntersectionChanges = (inView) => {
    if (inView) {
      inViewListener();
      if (window
          && window.doneAnimatedSections
          && window.doneAnimatedSections.includes(rtlNodeId)) {
        return;
      }
      anime({
        targets: `#${ltrNodeId}`,
        translateX: ['-20vw', 0],
        opacity: [0.3, 1],
        duration: 300,
        easing: 'linear',
        delay: 200,
      });
      anime({
        targets: `#${rtlNodeId}`,
        translateX: ['20vw', 0],
        opacity: [0.3, 1],
        duration: 300,
        easing: 'linear',
        delay: 200,
        complete: () => {
          try {
            if (window) {
              window.doneAnimatedSections = [...(window.doneAnimatedSections || []), rtlNodeId];
            }
            if (!animationAfterEvent || !animationAfterEvent.length) {
              return;
            }
            // Create the event
            const event = new CustomEvent('entry-animation-complete', { detail: { from: animationAfterEvent } });
            // const rtLNode = document.querySelector(rtlNodeId);

            // Dispatch/Trigger/Fire the event
            document.dispatchEvent(event);
          } catch (e) {
            console.log(e);
          }
        },
      });
    } else {
      return;
      // eslint-disable-next-line
      anime({
        targets: `#${rtlNodeId}`,
        translateX: [0, '20vw'],
        opacity: [1, 0.3],
        duration: 300,
        easing: 'linear',
        delay: 200,
      });
      anime({
        targets: `#${ltrNodeId}`,
        translateX: [0, '-20vw'],
        opacity: [1, 0.3],
        duration: 300,
        easing: 'linear',
        delay: 200,
      });
    }
  };
  if (getDevice() !== devices.DESKTOP) {
    return children;
  }
  return (
    <InView as="div" onChange={handleIntersectionChanges} threshold={0.7} initialInView={false}>
      {children}
    </InView>
  );
};

AnimationLeftRightFloater.defaultProps = {
  animationAfterEvent: '',
  inViewListener: () => {},
};

AnimationLeftRightFloater.propTypes = {
  children: arrayOf(node).isRequired,
  ltrNodeId: string.isRequired,
  rtlNodeId: string.isRequired,
  animationAfterEvent: string,
  inViewListener: func,
};

export default AnimationLeftRightFloater;
