import React from 'react';
import Switch from 'react-switch';
import {
  string, func, bool,
} from 'prop-types';
import styles from './CommonStyle.module.css';
import buttonize from '../../utilities/general';

const RightPlan = (props) => {
  const {
    width,
    checked,
    handleChange,
    selectedPeriod,
    setPlan,
    setProceededToPayment,
    constant1,
    constant2,
  } = props;

  return (
    <>
      <div className={`flex mt-16 text-center w-${width} items-center mx-auto`}>
        <div className="pt-9 flex items-center justify-start w-1/3">
          <div className={`2xl:text-xl text-base font-bold ${checked ? styles.switchColor : ''}`}>Monthly</div>
          <Switch
            onChange={handleChange}
            checked={checked}
            className="mx-5"
            offColor="#DFF0FF"
            onColor="#DFF0FF"
            offHandleColor="#4AA8FF"
            onHandleColor="#4AA8FF"
            uncheckedIcon={false}
            checkedIcon={false}
            width={window.screen.width <= 1366 ? 29.4 : 42}
            height={window.screen.width <= 1366 ? 15.4 : 22}
          />
          <div className={`2xl:text-xl text-base font-bold ${!checked ? styles.switchColor : ''}`}>Annually</div>
        </div>
        <div className="w-1/3">
          <div className={`font-bold ${styles.upgradeHeading}`}>Oddup for Sales</div>
          <div className={`${styles.valueSize} font-bold mt-5 mb-2`}>
            $
            {constant1 !== '' ? constant1 : 29}
          </div>
          <div className={`2xl:text-lg ${styles.subHeading} ${styles.textColor}`}>
            billed
            {' '}
            {selectedPeriod !== '' ? selectedPeriod : 'monthly'}
          </div>
          <div
            className={`${styles.modalBtn} ${styles.upgradePlan2}`}
            {...buttonize(() => {
              setPlan('forSales');
              setProceededToPayment(true);
            })}
          >
            Upgrade to Oddup for Sales
          </div>
        </div>
        <div className="w-1/3">
          <div className={`font-bold ${styles.upgradeHeading}`}>Oddup for Investors</div>
          <div className={`${styles.valueSize} font-bold mt-5 mb-2`}>
            $
            {constant2 !== '' ? constant2 : 49}
          </div>
          <div className={`2xl:text-lg ${styles.subHeading} ${styles.textColor}`}>
            billed
            {' '}
            {selectedPeriod !== '' ? selectedPeriod : 'monthly'}
          </div>
          <div
            className={`${styles.modalBtn} ${styles.upgradePlan2}`}
            {...buttonize(() => {
              setPlan('forInvestors');
              setProceededToPayment(true);
            })}
          >
            Upgrade to Oddup for Investors
          </div>
        </div>
      </div>
      <div className={`w-${width} mx-auto`}>
        <div className="font-bold 2xl:text-xl text-base 2xl:mt-16 mt-8">Allowance</div>
        <table className={`my-9 w-full ${styles.tableBorder}`}>
          <tbody className={`2xl:text-lg ${styles.subHeading} ${styles.textColor}`}>
            <tr className="border border-l-0 border-r-0 border-t-0">
              <td className="text-left py-3.5 w-1/3">
                Search Allowance
              </td>
              <td className="text-center py-3.5">
                Unlimited
              </td>
              <td className="text-center py-3.5">
                Unlimited
              </td>
            </tr>
            <tr className="border border-l-0 border-r-0">
              <td className="text-left py-3.5">
                View Allowance
              </td>
              <td className="text-center py-3.5">
                Unlimited
              </td>
              <td className="text-center py-3.5">
                Unlimited
              </td>
            </tr>
            <tr className="border border-l-0 border-r-0">
              <td className="text-left py-3.5">
                Daily Allowance Warning
              </td>
              <td className="text-center py-3.5">
                None
              </td>
              <td className="text-center py-3.5">
                None
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={`w-${width} mx-auto`}>
        <div className="font-bold 2xl:text-xl text-base 2xl:mt-20 mt-10">Data Access</div>
        <table className={`mt-9 w-full ${styles.tableBorder}`}>
          <tbody className={`2xl:text-lg ${styles.subHeading} ${styles.textColor}`}>
            <tr className="border border-l-0 border-r-0 border-t-0">
              <td className="text-left py-3.5 w-1/3">
                Company Data
              </td>
              <td className="text-center py-3.5">
                Unlimited
              </td>
              <td className="text-center py-3.5">
                Unlimited
              </td>
            </tr>
            <tr className="border border-l-0 border-r-0">
              <td className="text-left py-3.5">
                Sector Data
              </td>
              <td className="text-center py-3.5">
                Locked
              </td>
              <td className="text-center py-3.5">
                Unlimited
              </td>
            </tr>
            <tr className="border border-l-0 border-r-0">
              <td className="text-left py-3.5">
                Location Data
              </td>
              <td className="text-center py-3.5">
                Locked
              </td>
              <td className="text-center py-3.5">
                Unlimited
              </td>
            </tr>
            <tr className="border border-l-0 border-r-0">
              <td className="text-left py-3.5">
                Investor Data
              </td>
              <td className="text-center py-3.5">
                Locked
              </td>
              <td className="text-center py-3.5">
                Unlimited
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

RightPlan.propTypes = {
  width: string.isRequired,
  checked: bool,
  selectedPeriod: string,
  handleChange: func,
  setPlan: func,
  setProceededToPayment: func,
  constant1: string,
  constant2: string,
};

RightPlan.defaultProps = {
  selectedPeriod: '',
  checked: false,
  constant1: '',
  constant2: '',
  setProceededToPayment: () => {},
  setPlan: () => {},
  handleChange: () => {},
};

export default RightPlan;
