/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
import React from 'react';
import styles from './style.module.css';

const OddupUsers = () => (
  <section className="mt-8 w-3/5 mx-auto pb-16 pt-10">
    <div className="flex flex-col items-center justify-center">
      <div className={`2xl:pt-12 xl:mx-auto font-bold 2xl:text-3xl text-center w-11/12 mr-auto ${styles.heading}`}>
        Oddup in Numbers
      </div>
    </div>
    <div className={`flex justify-between font-semibold mt-11 ${styles.heading}`}>
      <div className="flex flex-col">
        300,000+
        <div className="text-lg font-medium mt-2.5">STARTUPS</div>
      </div>
      <div className="flex flex-col">
        63,000+
        <div className="text-lg font-medium mt-2.5">INVESTORS</div>
      </div>
      <div className="flex flex-col">
        30+
        <div className="text-lg font-medium mt-2.5">SECTORS</div>
      </div>
      <div className="flex flex-col">
        15+
        <div className="text-lg font-medium mt-2.5">LOCATIONS</div>
      </div>
    </div>
  </section>
);

export default OddupUsers;
