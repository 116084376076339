import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './MobileViewWarning.module.css';
import { assetPrefix } from '../../utilities/general';

// useEffect(() => {
//   bodyScrollLock();
//   return bodyScrollUnLock;
// }, []);
const MobileViewWarning = () => {
  const history = useHistory();
  return (
    <div className={`${styles.containerParent} flex flex-col w-full lg:hidden`}>
      <div className={`${styles.header} flex items-center justify-center w-full`}>
        <img alt="Oddup" src={`${assetPrefix}/logo/oddup.svg`} className={styles.logo} />
      </div>
      <div className="w-full h-screen relative">
        <div className={`relative ${styles.overlayBg}`} />
        <div className={`${styles.mainContainer} absolute top-0 left-0`}>
          <div className="flex flex-col mt-12 mx-8 items-center justify-center">
            <img
              src={`${assetPrefix}/images/warning-outline.svg`}
              className={styles.warningLogo}
              alt="Only Desktop View"
            />
            <span className={`${styles.warningText} mt-12`}>
              {/* eslint-disable-next-line max-len */}
              We’re still working on making the app fully optimised for mobile, but for now, please view the app on desktop for a better experience.
            </span>
            <button
              type="button"
              className="text-sm mt-10 text-white px-8 py-2.5 rounded-md border-none"
              style={{ backgroundColor: '#47CC79' }}
              onClick={() => history.push('/')}
            >
              Go back to Oddup.com
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileViewWarning;
