/* eslint-disable no-console */
export const pageViewEvent = ({
  pathname,
  search,
  pageName,
}) => {
  try {
    // Google Analytics
    window.gtag('set', 'page', pathname + search);
    window.gtag('event', 'pageview');

    // SendInBlue
    const properties = {
      ma_title: pageName,
      ma_url: window.location.href,
      ma_path: pathname,
    };
    window.sendinblue.page(pageName, properties);
  } catch (e) {
    console.log('Error in event pageView', e);
  }
};

export const identifyUserEvent = ({
  email,
  properties,
}) => {
  try {
    const details = { ...properties };
    delete details.card_details;
    delete details.payment_method_id;
    // GA
    window.gtag('event', 'userIdenitfy', {
      email,
      ...details,
    });

    // SendInBlue
    window.sendinblue.identify(email, details);
  } catch (e) {
    console.log('Error in event identifyUser', e);
  }
};

export const loginUserEvent = ({
  userDetails,
}) => {
  try {
    const eventName = 'userLogin';
    // GA
    window.gtag('event', eventName, {
      ...userDetails,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      { email: userDetails.email },
      { data: userDetails },
    );
  } catch (e) {
    console.log('Error in event loginUserEvent', e);
  }
};

export const purchaseCompleteEvent = ({
  email,
  purchaseDetails,
}) => {
  try {
    const eventName = 'purchaseComplete';
    // GA
    window.gtag('event', eventName, {
      ...purchaseDetails,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      { email },
      { data: purchaseDetails },
    );
  } catch (e) {
    console.log('Error in event purchaseCompleteEvent', e);
  }
};

export const paymentFailedEvent = ({
  email,
  purchaseDetails,
}) => {
  try {
    const eventName = 'paymentFailed';
    // GA
    window.gtag('event', eventName, {
      ...purchaseDetails,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      { email },
      { data: purchaseDetails },
    );
  } catch (e) {
    console.log('Error in event paymentFailedEvent', e);
  }
};

export const cancelPlanEvent = ({
  email,
  planDetailsIncoming,
}) => {
  try {
    const planDetails = { ...planDetailsIncoming };
    delete planDetails.card_details;
    delete planDetails.payment_method_id;
    const eventName = 'cancelPlan';
    // GA
    window.gtag('event', eventName, {
      ...planDetails,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      { email },
      { data: planDetails },
    );
  } catch (e) {
    console.log('Error in event cancelPlanEvent', e);
  }
};

export const globalSearchCTAEvent = ({
  details,
}) => {
  try {
    const eventName = 'globalSearchCTAEvent';
    // GA
    window.gtag('event', eventName, {
      ...details,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
      { data: details },
    );
  } catch (e) {
    console.log('Error in event globalSearchCTAEvent', e);
  }
};

export const signUpSuccessEvent = ({
  details,
}) => {
  try {
    const eventName = 'signUpSuccessEvent';
    // GA
    window.gtag('event', eventName, {
      ...details,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {
        email: details.email,
        FIRSTNAME: details.name.split(' ')[0],
        LASTNAME: details.name.split(' ')[1] || '',
      },
      { data: details },
    );
  } catch (e) {
    console.log('Error in event signUpSuccessEvent', e);
  }
};

export const initiatePurchaseEvent = ({
  details,
}) => {
  try {
    const eventName = 'initiatePurchaseEvent';
    // GA
    window.gtag('event', eventName, {
      ...details,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
      { data: details },
    );
  } catch (e) {
    console.log('Error in event initiatePurchaseEvent', e);
  }
};

export const planSelectedEvent = ({
  details,
}) => {
  try {
    const eventName = 'planSelectedEvent';
    // GA
    window.gtag('event', eventName, {
      ...details,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
      { data: details },
    );
  } catch (e) {
    console.log('Error in event planSelectedEvent', e);
  }
};

export const checkoutInitiatedEvent = ({
  details,
}) => {
  try {
    const eventName = 'checkoutInitiatedEvent';
    // GA
    window.gtag('event', eventName, {
      ...details,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
      { data: details },
    );
  } catch (e) {
    console.log('Error in event checkoutInitiatedEvent', e);
  }
};

export const applyCompaniesFilters = ({
  details,
}) => {
  try {
    const eventName = 'applyCompaniesFilters';
    // GA
    window.gtag('event', eventName, {
      ...details,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
      { data: details },
    );
  } catch (e) {
    console.log('Error in event applyCompaniesFilters', e);
  }
};

export const exportWarningConfirmation = () => {
  try {
    const eventName = 'exportWarningConfirmation';
    // GA
    window.gtag('event', eventName);
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
    );
  } catch (e) {
    console.log('Error in event exportWarningConfirmation', e);
  }
};

export const dataExportClick = () => {
  try {
    const eventName = 'dataExportClick';
    // GA
    window.gtag('event', eventName);
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
    );
  } catch (e) {
    console.log('Error in event dataExportClick', e);
  }
};

export const sampleReportDownloadEvent = ({ details }) => {
  try {
    const eventName = 'sampleReportDownload';
    // GA
    window.gtag('event', eventName, { ...details });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
      { data: details },
    );
  } catch (e) {
    console.log('Error in event applyCompaniesFilters', e);
  }
};

export const chooseReportTypeEvent = ({ details }) => {
  try {
    const eventName = 'chooseReportType';
    // GA
    window.gtag('event', eventName, {
      ...details,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
      { data: details },
    );
  } catch (e) {
    console.log('Error in event chooseReportTypeEvent', e);
  }
};

export const finalExportReportClickEvent = () => {
  try {
    const eventName = 'finalExportReportClick';
    // GA
    window.gtag('event', eventName);
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
    );
  } catch (e) {
    console.log('Error in event finalExportReportClick', e);
  }
};

export const monthlyReportDownloadEvent = ({ details }) => {
  try {
    const eventName = 'monthlyReportDownload';
    // GA
    window.gtag('event', eventName, {
      ...details,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
      { data: details },
    );
  } catch (e) {
    console.log('Error in event monthlyReportDownload', e);
  }
};

export const userGeneratedReportDownloadEvent = ({ details }) => {
  try {
    const eventName = 'userGeneratedReportDownload';
    // GA
    window.gtag('event', eventName, {
      ...details,
    });
    // SendInBlue
    window.sendinblue.track(
      eventName,
      {},
      { data: details },
    );
  } catch (e) {
    console.log('Error in event userGeneratedReportDownload', e);
  }
};

export const productSources = {
  safeti_landing_page: 'safeti_landing_page',
  safeti_app: 'safeti_app',
  oddup_landing_page: 'oddup_landing_page',
  oddup_app: 'oddup_app',
};
