/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
const buttonize = (handlerFn) => ({
  role: 'button',
  onClick: handlerFn,
  tabIndex: 0,
  onKeyDown: (event) => {
    // insert your preferred method for detecting the keypress
    if (event.keycode === 13) handlerFn(event);
  },
});

export const assetPrefix = '/oddup-app/assets';

export const loadingStates = {
  NO_ACTIVE_REQUEST: 'NO_ACTIVE_REQUEST',
  LOADING: 'LOADING',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  SUCCEEDED: 'SUCCEEDED',
};

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(pwd) {
  const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
  return pwd.match(regex);
}

export const optionsGenerator = (options, selectedOptions) => {
  const allSelectedValues = selectedOptions.map((option) => option.value);
  return options.filter(
    (option) => allSelectedValues.indexOf(option.value) === -1,
  );
};

export const localDropdownSearch = (search, selectedOptions, allOptions) => {
  if (!allOptions || allOptions[0].label === null) {
    return [];
  }
  return optionsGenerator(
    allOptions.filter(
      (option) => option.label.toLowerCase().indexOf(search.toLowerCase()) === 0,
    ), selectedOptions,
  );
};

export const bodyScrollLock = () => {
  if (document && document.body) document.body.style.overflowY = 'hidden';
  return undefined;
};

export const bodyScrollUnLock = () => {
  if (document && document.body) document.body.style.overflowY = 'scroll';
  return undefined;
};

export const getColorForScore = (score) => {
  if (score >= 70) return '#8CE217';

  if (score > 35) return '#F9A61A';

  return '#ED2D27';
};

export const fixed2Decimals = (val) => (val * 1).toFixed(2).replace(/[.,]00$/, '');

export const displayableValue = (val) => Math.floor(val);

export const sliderValuesFormatter = (maxInMillions, value) => {
  const millions = (maxInMillions / 100) * value;
  const billions = millions / 1000;
  const trillions = billions / 1000;
  const thousand = millions * 1000000;
  return (
    {
      millions,
      billions,
      thousand,
      trillions,
      toShow: millions < 1000 ? `${Math.round(millions)}M` : billions < 1000 ? `${billions}B` : `${trillions}T`,
    }
  );
};

export const getColorViaPercentage = (score) => {
  // 0.25 - 0.375 = #D13333
  // 0.375 - 0.5 = #E6631C
  // 0.5 - 0.625 = #F2AE2A
  // 0.625 - 0.75 = #EEE645
  // 0.75 - 0.875 = #92D348
  // 0.875 - 1 = #2BD069

  if (score >= 87.5) {
    return '#2BD069';
  }
  if (score >= 75) {
    return '#92D348';
  }

  if (score >= 62.5) {
    return '#EEE645';
  }

  if (score >= 50) {
    return '#F2AE2A';
  }

  if (score >= 37.5) {
    return '#E6631C';
  }
  return '#D13333';
  // if (score >= 70) {
  //   return '#4eca2c';
  // } if (score <= 35) {
  //   return '#ed2d27';
  // }
  // return '#f2c62a';
};

export const getStrokeColorViaPercentage = (score) => {
  if (score >= 87.5) {
    return '#26bb5e';
  }
  if (score >= 75) {
    return '#84cd31';
  }

  if (score >= 62.5) {
    return '#ebe228';
  }

  if (score >= 50) {
    return '#f0a30f';
  }

  if (score >= 37.5) {
    return '#d15817';
  }
  return '#be2b2b';
};

export const millifyOptions = ({
  precision: 2,
  units: ['', 'K', 'M', 'B', 'T'],
});

export const millifyOptionsForCrypto = ({
  precision: 6,
  units: ['', 'K', 'M', 'B', 'T'],
});

export const setArrowColor = (score) => {
  if (score.charAt(0) === '-') {
    return {
      value: score.slice(1),
      color: '#D13333',
    };
  }
  return {
    value: score.slice(1),
    color: '#2ED86E',
  };
};

export const multipleTextToOne = (arr) => {
  if (arr.length === 1) return arr[0];
  return `${arr[0]}, +${arr.length - 1} others`;
};

export const getDateFromISODate = (state) => {
  const months = ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  let date = state === null ? '' : state && state.map((date1) => new Date(date1[0]));

  date = date && date.map((Ndate) => (Ndate ? `${months[Ndate.getMonth()]} ${Ndate.getDate()}` : '-'));

  return date;
};

export const numeralFormat = '0,0.[0]00000';

export default buttonize;

const base64ToBlob = (str) => {
  // extract content type and base64 payload from original string
  // var pos = str.indexOf(';base64,');
  // var type = str.substring(5, pos);
  // var b64 = str.substr(pos + 8);

  // decode base64
  const imageContent = atob(str);

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  // fill the view, using the decoded base64
  // eslint-disable-next-line no-plusplus
  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  // convert ArrayBuffer to Blob
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  return blob;
};

export const fileDownloader = ({
  fileContents,
  filename,
}) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const blob = base64ToBlob(fileContents);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const getApplicableSorting = (listingConfigsIncoming) => {
  const applicableSorting = {};
  if ((listingConfigsIncoming.sortOrientation || listingConfigsIncoming.sortDirection) && (listingConfigsIncoming.sortBy || listingConfigsIncoming.selectedValue)) {
    applicableSorting.sort_by = listingConfigsIncoming.sortBy || listingConfigsIncoming.selectedValue;
    applicableSorting.sort_order = listingConfigsIncoming.sortOrientation || listingConfigsIncoming.sortDirection;
  }
  return applicableSorting;
};

export const getApplicableFilters = (listingConfigsIncoming) => {
  const applicableFilters = {};
  const { appliedFilters } = listingConfigsIncoming;
  // appliedFilters and applicableFilters are different
  // appliedFilters is what applied on frontend
  // applicableFilters is what will be sent with api
  if (appliedFilters.funding && appliedFilters.funding.length === 2
    && (+appliedFilters.funding[0] !== 0 || +appliedFilters.funding[1] !== 100)) {
    [applicableFilters.funding_min, applicableFilters.funding_max] = appliedFilters.funding
      .map(
        (val) => sliderValuesFormatter(25000, val).thousand,
      );
  }
  if (appliedFilters.oddupScore && appliedFilters.oddupScore.length === 2
    && (+appliedFilters.oddupScore[0] !== 0 || +appliedFilters.oddupScore[1] !== 100)) {
    // eslint-disable-next-line max-len
    [applicableFilters.oddupscore_min, applicableFilters.oddupscore_max] = appliedFilters.oddupScore.map((val) => val);
  }

  if (appliedFilters.benchmark && appliedFilters.benchmark.length === 2
    && (+appliedFilters.benchmark[0] !== 0 || +appliedFilters.benchmark[1] !== 100)) {
    [applicableFilters.benchmark_min, applicableFilters.benchmark_max] = appliedFilters
      .benchmark.map(
        (val) => sliderValuesFormatter(150000, val).thousand,
      );
  }
  // if (appliedFilters.valuation && appliedFilters.valuation.length === 2) {
  //   [applicableFilters.valuation_min, applicableFilters.valuation_max] = appliedFilters
  //     .valuation.map(
  //       (val) => sliderValuesFormatter(5000, val).thousand,
  //     );
  // }
  if (appliedFilters.featured) { // will not send in api if false
    applicableFilters.featured = appliedFilters.featured;
  }
  if (appliedFilters.saved) { // will not send in api if false
    applicableFilters.saved = appliedFilters.saved;
  }
  if (appliedFilters.locations && appliedFilters.locations.length) {
    applicableFilters.location = appliedFilters.locations.map((v) => v.label).join(',');
  }
  if (appliedFilters.industries && appliedFilters.industries.length) {
    applicableFilters.industry = appliedFilters.industries.map((v) => v.label).join(',');
  }
  if (appliedFilters.keywords && appliedFilters.keywords.length) {
    applicableFilters.keywords = appliedFilters.keywords.map((v) => v.label).join(',');
  }

  if (appliedFilters.includeClosed) {
    applicableFilters.includeClosed = true;
  }

  if (appliedFilters.includeExited) {
    applicableFilters.includeExited = true;
  }

  return applicableFilters;
};

export const transformFirebaseNotifs = (notifs) => {
  const keys = Object.keys(notifs);
  const arr = [];
  keys.forEach((notifKey) => {
    arr.push({ ...notifs[notifKey], key: notifKey });
  });
  return arr;
};

export const changeToReadableStatus = (status) => {
  if (!status) {
    return status;
  }
  if (status.toLowerCase() === 'ipo_exit') {
    return 'EXIT - IPO';
  }

  if (status.toLowerCase() === 'ma_exit') {
    return 'EXIT - M&A';
  }
  return status;
};

export const numFormatter = (num) => {
  if (num >= 10000000 && num < 1000000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}B`;
  }
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}T`;
  }
  if (num >= 1000) {
    return `${((num * 1000) / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  // if (num >= 100) {
  //   return `${((num * 1000) / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  // }
  return num;
};
