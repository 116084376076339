/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';
// import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import {
  Check, Close,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import styles from './PricingPage.module.css';
import buttonize, { assetPrefix } from '../../utilities/general';
import FAQs from '../../components/ComparePlans/FAQs';
import OddupUsers from '../../components/OddupUsers/OddupUsers';
import constants from '../../utilities/constants';

const PricingPage = () => {
  const typeform = useRef(null);
  const toCheckScroll = useRef(null);
  const featuredClients = useRef(null);
  // const history = useHistory();
  const [activeNav, setActiveNav] = useState(null);
  const [showHeaderBg, setShowHeaderBg] = useState(false);
  const [checked, setChecked] = useState(false);
  const planType = checked ? 'yearly' : 'monthly';
  const selectedPeriod = planType === 'yearly' ? 'annually' : 'monthly';

  const history = useHistory();

  const handleChange = (event) => {
    setChecked(event);
  };

  const scrollListener = () => {
    if (window.scrollY > 150 && !showHeaderBg) {
      setShowHeaderBg(true);
    }
    if (window.scrollY < 150 && showHeaderBg) {
      setShowHeaderBg(false);
    }
    const cryptoTarget = toCheckScroll.current;
    const researchTarget = featuredClients.current;
    const passedResearchTarget = researchTarget !== null && window.scrollY > (
      researchTarget.offsetTop + researchTarget.offsetHeight
    );
    const passedCryptoTarget = cryptoTarget !== null && window.scrollY > (
      cryptoTarget.offsetTop + cryptoTarget.offsetHeight
    );
    if (passedCryptoTarget && passedResearchTarget) {
      setActiveNav('crypto');
      return;
    }
    if (passedResearchTarget) {
      setActiveNav('research');
    }

    if (!passedResearchTarget && !passedCryptoTarget) {
      setActiveNav(null);
    }
    // if (scrolled && !passed) {
    //   setScrolled(false);
    // }
    // if (!scrolled && passed) {
    //   setScrolled(true);
    // }
  };

  useEffect(() => {
    if (toCheckScroll && toCheckScroll.current && featuredClients && featuredClients.current) {
      window.removeEventListener('scroll', scrollListener);
      window.addEventListener('scroll', scrollListener);
    }
  }, [activeNav]);

  return (
    <div>
      { /* Header -- start */ }
      <div className={`${styles.headerParentContainer} ${showHeaderBg ? styles.headerBg : ''}`}>
        <div className={`${styles.generalContainer} ${styles.headerContainer}`}>
          <div className="flex">
            <img
              src={`${assetPrefix}/logo/oddup.svg`}
              className={styles.logo}
              alt="Oddup"
              {...buttonize(() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              })}
            />
            <div className="flex">
              {/* <span className={styles.loginBtn} onClick={() => history.push('/login')}>
                LOGIN
              </span>
              <span className={styles.signUpBtn} onClick={() => history.push('/signup')}>
                SIGNUP
              </span> */}
              <a href="/">
                <span className={styles.loginBtn}>
                  HOME
                </span>
              </a>
              <a href="https://blog.oddup.com">
                <span className={styles.loginBtn}>
                  BLOG
                </span>
              </a>

            </div>
          </div>
        </div>
      </div>
      { /* Header -- end */ }

      <div className={`mr-auto sm:w-3/4 xl:mx-auto font-semibold text-5xl text-center pt-32 w-11/12 mr-auto sm:w-full ${styles.centerHeading}`}>
        Choose the plan that’s right for you
      </div>
      <div className="mr-auto sm:w-3/4 xl:mx-auto font-normal text-base text-center py-7 w-11/12 mr-auto sm:w-full" style={{ color: '#666666' }}>
        Pay by month or by year, and cancel at any time.
      </div>

      {/* <div className="font-normal text-base pt-7 w-10/12 mx-auto px-4" style={{ color: '#666666' }}>
        * Team Plan pricing starts at $19/month per member on a team of three (3). Cheaper price offered for larger teams.
      </div> */}

      <section className="mt-20 lg:mt-28 px-5" ref={featuredClients}>
        <div className="flex flex-col items-center justify-center pb-28" ref={toCheckScroll}>
          <table className={`my-9 w-10/12 ${styles.tableBorder} border`}>
            <tbody className={`2xl:text-lg ${styles.subHeading}`}>
              <tr className="border-0">
                <td className="py-3.5 pl-3">
                  <div className="2xl:pt-9 pt-8 flex items-center pl-3 h-36">
                    <div className={`2xl:text-2xl text-base font-bold ${checked ? styles.switchColor : ''}`}>Monthly</div>
                    <Switch
                      onChange={handleChange}
                      checked={checked}
                      className="mx-3"
                      offColor="#DFF0FF"
                      onColor="#DFF0FF"
                      offHandleColor="#4AA8FF"
                      onHandleColor="#4AA8FF"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      width={window.screen.width <= 1366 ? 29.4 : 42}
                      height={window.screen.width <= 1366 ? 15.4 : 22}
                    />
                    <div className={`2xl:text-2xl text-base font-bold ${!checked ? styles.switchColor : ''}`}>Annually</div>
                  </div>
                  <h6 className="font-bold text-xl mb-8 pl-3">Features</h6>
                </td>
                <td className="py-3.5 w-1/4">
                  <div className="text-center">
                    <div className={`font-bold ${styles.heading}`}>Free</div>
                    <div className={`font-bold mt-8 ${styles.valueSize}`}>$0</div>
                    <div className={`2xl:mt-4 2xl:text-lg font-normal ${styles.subHeading}`} style={{ color: '#666666' }}>Free Forever!</div>
                    <div className={`w-full text-center py-4 2xl:py-9 ${styles.divStyle}`} />
                    {/* <div className="w-full text-center py-4 2xl:py-9">
                      <div className={styles.outlinedBtn}>Your Current Plan</div>
                    </div> */}
                  </div>
                </td>
                <td className="py-3.5 w-1/4">
                  <div className="text-center">
                    <div className={`font-bold ${styles.heading}`}>Oddup for Sales</div>
                    <div className={`font-bold mt-8 ${styles.valueSize}`}>
                      $
                      {constants.pricingPlans.forSales[planType].priceInUSD}
                    </div>
                    <div className={`2xl:mt-4 2xl:text-lg font-normal ${styles.subHeading}`} style={{ color: '#666666' }}>
                      billed
                      {' '}
                      {selectedPeriod}
                    </div>
                    <div className="w-full text-center py-4 2xl:py-9" onClick={() => history.push('/login')}>
                      <div className={styles.modalBtn}>Get Oddup for Sales</div>
                    </div>
                  </div>
                </td>
                <td className="py-3.5">
                  <div className="text-center">
                    <div className={`font-bold ${styles.heading}`}>Oddup for Investors</div>
                    <div className={`font-bold mt-8 ${styles.valueSize}`}>
                      $
                      {constants.pricingPlans.forInvestors[planType].priceInUSD}
                    </div>
                    <div className={`2xl:mt-4 2xl:text-lg font-normal ${styles.subHeading}`} style={{ color: '#666666' }}>
                      billed
                      {' '}
                      {selectedPeriod}
                    </div>
                    <div className="w-full text-center py-4 2xl:py-9" onClick={() => history.push('/login')}>
                      <div className={styles.modalBtn}>Get Oddup for Investors</div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className={`border-0 ${styles.textColor}`}>
                <td className="text-left py-3.5 pl-5">
                  Startup Information
                </td>
                <td className="text-center py-3.5">
                  Fundamental
                </td>
                <td className="text-center py-3.5">
                  Fundamental
                </td>
                <td className="text-center py-3.5">
                  Detailed
                </td>
              </tr>
              <tr className={`border-0 ${styles.textColor}`} style={{ backgroundColor: '#F4FAFF' }}>
                <td className="text-left py-3.5 pl-5">
                  Detailed Company Pages
                </td>
                <td className="text-center py-3.5">
                  10 per day
                </td>
                <td className="text-center py-3.5">
                  Unlimited
                </td>
                <td className="text-center py-3.5">
                  Unlimited
                </td>
              </tr>
              <tr className={`border-0 ${styles.textColor}`}>
                <td className="text-left py-3.5 pl-5">
                  Founder Data
                </td>
                <td className="text-center py-3.5">
                  10 per day
                </td>
                <td className="text-center py-3.5">
                  Unlimited
                </td>
                <td className="text-center py-3.5">
                  Unlimited
                </td>
              </tr>
              <tr className={`border-0 ${styles.textColor}`} style={{ backgroundColor: '#F4FAFF' }}>
                <td className="text-left py-3.5 pl-5">
                  Search and Filter Results
                </td>
                <td className="text-center py-3.5">
                  Up to 100 per query
                </td>
                <td className="text-center py-3.5">
                  Unlimited
                </td>
                <td className="text-center py-3.5">
                  Unlimited
                </td>
              </tr>
              <tr className={`border-0 ${styles.textColor}`}>
                <td className="text-left py-3.5 pl-5">
                  Searches
                </td>
                <td className="text-center py-3.5">
                  Unlimited
                </td>
                <td className="text-center py-3.5">
                  Unlimited
                </td>
                <td className="text-center py-3.5">
                  Unlimited
                </td>
              </tr>
              <tr className={`border-0 ${styles.textColor}`} style={{ backgroundColor: '#F4FAFF' }}>
                <td className="text-left py-3.5 pl-5">
                  Sector and Location Deep Dives
                </td>
                <td className="text-center py-3.5">
                  <Close style={{ color: '#E65353' }} />
                </td>
                <td className="text-center py-3.5">
                  <Close style={{ color: '#E65353' }} />
                </td>
                <td className="text-center py-3.5">
                  <Check style={{ color: '#47CC79' }} />
                </td>
              </tr>
              <tr className={`border-0 ${styles.textColor}`}>
                <td className="text-left py-3.5 pl-5">
                  Detailed Investor Profile
                </td>
                <td className="text-center py-3.5">
                  <Close style={{ color: '#E65353' }} />
                </td>
                <td className="text-center py-3.5">
                  <Close style={{ color: '#E65353' }} />
                </td>
                <td className="text-center py-3.5">
                  <Check style={{ color: '#47CC79' }} />
                </td>
              </tr>
              <tr className={`border-0 ${styles.textColor}`} style={{ backgroundColor: '#F4FAFF' }}>
                <td className="text-left py-3.5 pl-5">
                  Investor Portolio Tracking
                </td>
                <td className="text-center py-3.5">
                  <Close style={{ color: '#E65353' }} />
                </td>
                <td className="text-center py-3.5">
                  <Close style={{ color: '#E65353' }} />
                </td>
                <td className="text-center py-3.5">
                  <Check style={{ color: '#47CC79' }} />
                </td>
              </tr>
              <tr className={`border-0 ${styles.textColor}`}>
                <td className="text-left py-3.5 pl-5">
                  Oddup Success Indicators
                </td>
                <td className="text-center py-3.5">
                  <Close style={{ color: '#E65353' }} />
                </td>
                <td className="text-center py-3.5">
                  <Close style={{ color: '#E65353' }} />
                </td>
                <td className="text-center py-3.5">
                  <Check style={{ color: '#47CC79' }} />
                </td>
              </tr>
              <tr className={`border-0 ${styles.textColor}`} style={{ backgroundColor: '#F4FAFF' }}>
                <td className="text-left py-3.5 pl-5">
                  Comprehensive Funding Information
                </td>
                <td className="text-center py-3.5">
                  <Close style={{ color: '#E65353' }} />
                </td>
                <td className="text-center py-3.5">
                  <Close style={{ color: '#E65353' }} />
                </td>
                <td className="text-center py-3.5">
                  <Check style={{ color: '#47CC79' }} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <div className={`lg:mt-20 ${styles.upgradeBg}`}>
        <OddupUsers />
      </div>

      <FAQs />

      {/* <a href="sdc" className="underline pt-7 pb-32 font-normal text-xl flex items-center justify-center" style={{ color: '#4AA8FF' }}>
        See more FAQs
      </a> */}

      {/* <LowerComponent /> */}
      <div className={styles.footerParent}>
        <section className={styles.generalContainer}>
          <div className="md:flex items-center w-11/12 border-b-2 py-16">
            <div className="md:text-left text-center mb-4 md:mb-0 md:w-3/6 w-full">
              <img className={`md:mr-auto ${styles.imageStyle}`} src={`${assetPrefix}/logo/oddupWhiteHeading.svg`} alt="bubbles" />
            </div>
            <div className="md:w-3/6 w-full flex items-center md:justify-end justify-center">
              <div style={{ color: '#FFFFFF' }} className="mr-3 md:inline-flex hidden">FOLLOW US</div>
              <div className="flex items-center">
                <a
                  className={`flex items-center justify-center mr-3 ${styles.iconStyle}`}
                  href="https://www.facebook.com/oddupratings/"
                  target="_blank"
                >
                  <img src={`${assetPrefix}/images/facebook.svg`} className={styles.fbLogo} alt="Facebook" />
                </a>
                <a
                  className={`flex items-center justify-center mr-3 ${styles.iconStyle}`}
                  href="https://twitter.com/oddupratings"
                  target="_blank"
                >
                  <img src={`${assetPrefix}/images/twitter.svg`} alt="twitter" className={styles.twLogo} />
                </a>
                <a
                  className={`flex items-center justify-center ${styles.iconStyle}`}
                  href="https://www.linkedin.com/company/oddup"
                  target="_blank"
                >
                  <img src={`${assetPrefix}/images/linkedin.svg`} alt="linkedin" className={styles.twLogo} />
                </a>
              </div>
            </div>
          </div>
          <div className={`font-normal text-center py-10 ${styles.copyright}`}>
            © 2021 Oddup.com. All rights reserved.
            {' '}
            <a
              href="https://www.notion.so/oddup/Copyright-Policy-fc1e1aa5db60497e886ddb247d3948c2"
              target="_blank"
            >
              Copyright
            </a>
            {' '}
            |
            {' '}
            <a
              href="https://www.notion.so/oddup/Oddup-Terms-Conditions-39b0a029ba05440e85f8e4bee1e7350c"
              target="_blank"
            >
              Terms
            </a>
            {' '}
            |
            {' '}
            <a
              href="https://www.notion.so/oddup/Oddup-Privacy-Policy-5b46ce2440934925b43005b5ebce0c7d"
              target="_blank"
            >
              Privacy
            </a>
            {' '}
            |
            {' '}
            <a
              href="mailto:hello@oddup.com"
            >
              Help
            </a>
          </div>
        </section>
      </div>
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        url="https://form.typeform.com/to/MXo4VteX"
        hideHeaders
        hideFooter
        buttonText="Go!"
        style={{ height: 0 }}
        ref={typeform}
      />
    </div>
  );
};

export default PricingPage;
