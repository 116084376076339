import React from 'react';
import Marquee from 'react-fast-marquee';
import { assetPrefix } from '../../utilities/general';
import style from './ProductPage.module.css';

const CryptoIconsContainer = () => (
  <div
    style={{
      width: '98vw',
      maxWidth: '100%',
    }}
    className="pt-24"
  >
    <div>
      <Marquee speed={100} style={{ padding: '10px 0px', overflowY: 'hidden' }}>
        <div className="flex">
          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/ada.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">ADA</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/ae.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">AE</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/agi.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">AGI</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/alv.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">ALV</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/bch.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">BCH</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/btc.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">BTC</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/btg.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">BTG</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/buxx.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">BUXX</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/eth.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">ETH</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/iost.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">IOST</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>
        </div>
      </Marquee>

      <Marquee direction="right" speed={100} style={{ padding: '10px 0px', overflowY: 'hidden' }}>
        <div className="flex pt-28">
          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/ltc.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">LTC</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/kmd.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">KMD</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/nano.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">NANO</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/neo.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">NEO</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/xrp.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">XRP</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/appc.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">APPC</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/bay.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">BAY</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/bq.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">BQ</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>

          <div className={`border rounded-xl w-40 mr-5 hover:shadow-2xl ${style.shadowStyle}`}>
            <img
              className="pt-6 mx-auto"
              src={`${assetPrefix}/images/productPage/dot.png`}
              alt="icon"
            />
            <div className="text-center py-4">
              <div className="font-semibold text-lg">DOT</div>
              <div className={`text-base font-normal ${style.subtitleStyle}`}>Bitcoin</div>
            </div>
          </div>
        </div>
      </Marquee>
    </div>
  </div>
);

export default CryptoIconsContainer;
